import { toast } from "react-toastify";
import { createSlice } from "@reduxjs/toolkit";
import {
  userList,
  userPasswordUpdate,
  userInfoUpdate,
  getUserPermissions,
} from "../services/user";
import { sectionsArr } from "../components/Roles/UserInfo";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    userData: [],
    loading: false,
    permissions: [],
  },
  reducers: {
    loading: (state, action) => {
      state.loading = action?.payload ?? true;
    },
    getUserList: (state, action) => {
      state.userData = action.payload;
      state.loading = false;
    },
    updateUserList: (state, action) => {
      state.loading = false;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload || [];
    },
  },
});

export const { loading, getUserList, updateUserList, setPermissions } =
  userSlice.actions;
export default userSlice.reducer;

// api call
export const fetchUser = (id) => async (dispatch) => {
  try {
    dispatch(loading());
    const response = await userList(id);
    dispatch(getUserList(response?.data));
  } catch (error) {
    console.error("Error fetchInvoice:", error);
    dispatch(loading(false));
  }
};

export const changeUserPassword = (params) => async (dispatch) => {
  try {
    dispatch(loading());
    const response = await userPasswordUpdate(params);
    toast.success(response?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatch(updateUserList(response?.data));
  } catch (error) {
    console.error("Error fetchInvoice:", error);
    dispatch(loading(false));
  }
};

export const updateUserDetails = (id, params) => async (dispatch) => {
  try {
    dispatch(loading());
    const response = await userInfoUpdate(id, params);
    toast.success(response?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatch(getUserList(response?.data));
  } catch (error) {
    console.error("Error fetchInvoice:", error);
    dispatch(loading(false));
  }
};

const getAllKeys = (sections) => {
  return sections.reduce((acc, section) => {
    acc.push(section.key);
    if (section.subSections) {
      acc.push(...section.subSections.map((sub) => sub.key));
    }
    return acc;
  }, []);
};

export const getLoggedInUserPermissions = () => async (dispatch, getState) => {
  try {
    const storageUser = localStorage.getItem("userinfo");
    const userRole = storageUser ? JSON.parse(storageUser)?.role : "";
    const allKeys = getAllKeys(sectionsArr);

    const response = await getUserPermissions();
    dispatch(setPermissions(!userRole ? allKeys : response?.data));
  } catch (error) {
    console.error("Error fetchInvoice:", error);
  }
};
