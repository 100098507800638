/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { SmallLoader } from "../../Common/Loader";
import pencilIcon from "../../../assets/icons/pencil.png";
import { updataClientApi } from "../../../services/client/addclientApi";
import { useDispatch } from "react-redux";
import { fetchCustomerData } from "../../../Redux/clientsSlice";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import CustomerActivationModale from "../../Common/CustomertActivationModale";
import PhoneInput from "react-phone-input-2";
import {
  mainAndClientRoleClass,
  subscriptionStatusClass,
} from "../../Common/roles";
import { useLoadScript } from "@react-google-maps/api";
import Auxdata from "./Auxdata";
import AddressAutocompleteInput from "../../Common/AddressAutocompleteInput";
import { BsPencil } from "react-icons/bs";
const libraries = ["places"];
const ConnectedCustomers = ({ customerData, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);
  const [openMain, setOpenMain] = useState(0);
  const [openAuxIndex, setOpenAuxIndex] = useState(null);
  const [isLoding, setLoding] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isModelShow, setModelShow] = useState(false);
  const [userId, setUserId] = useState();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCfC8Izlpa7eb4Rae3EtA5-WcmrHr8BHAA",
    libraries,
  });

  const apiData = customerData?.connectedCustomers || [];
  // open main driver and edite
  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? null : index);
    setEdit(true);
  };

  // open driver
  const toggleAccordionMain = (index, values) => {
    setOpenMain(index === openMain ? null : index);
  };

  // open aux driver and edite
  const toggleAuxAccordion = (index) => {
    setOpenAuxIndex(index === openAuxIndex ? null : index);
  };

  const handleNavigate = (e, id) => {
    localStorage.setItem("customer_id", id);
    window.scroll(0, 0);
    navigate(`/driver/profile/${id}`);
  };

  if (loadError) return <></>;
  if (!isLoaded) return <></>;
  return (
    <>
      {apiData?.map((customer, index) => {
        const main = customer?.MAIN || {};
        if (customer?.MAIN === null) {
          return null;
        }
        return (
          <React.Fragment key={index}>
            <div className="card mb-2">
              <div className="card-boday">
                <Formik
                  initialValues={{
                    firstname: main?.firstname || "",
                    lastname: main?.lastname || "",
                    email: main?.email || "",
                    contact: main?.contact || "",
                    // ${main?.houseno ? main?.houseno + ", " : ""}
                    address: `${main?.address || ""}`,
                    buildingNumber: main?.houseno || "",
                    DOB: main?.DOB
                      ? new Date(main?.DOB).toISOString().split("T")[0]
                      : "",
                    language: main?.language,
                    gender: main?.gender,
                    licensedate: main?.licensedate
                      ? new Date(main?.licensedate).toISOString().split("T")[0]
                      : "",
                  }}
                  enableReinitialize={true}
                  onSubmit={async (values) => {
                    setLoding(true);

                    const formData = new FormData();
                    formData.append("firstname", values.firstname);
                    formData.append("lastname", values.lastname);
                    formData.append("contact", values.contact);
                    if (values.buildingNumber)
                      formData.append("houseno", values.buildingNumber);
                    if (values.address)
                      formData.append("address", values.address);
                    formData.append("DOB", values.DOB);
                    formData.append("language", values.language);
                    formData.append("gender", values.gender);
                    formData.append("licensedate", values.licensedate);
                    const data = await updataClientApi(main?._id, formData);

                    if (data?.status) {
                      setLoding(false);
                      setEdit(false);
                      setOpenIndex(null);
                      dispatch(fetchCustomerData(id));
                    } else {
                      toggleAccordion(null);
                      setLoding(false);
                      setEdit(false);
                    }
                  }}
                >
                  {({ values, handleChange, setFieldValue, handleSubmit }) => {
                    return (
                      <Form>
                        <Accordion activeKey={openMain}>
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header
                              onClick={() => toggleAccordionMain(index, values)}
                            >
                              <div className="w-100">
                                <div className="my-auto">
                                  <div className="d-flex gap-2 align-items-center">
                                    {openIndex === index ? (
                                      <div className="d-flex gap-2">
                                        <div>
                                          <button
                                            type="button"
                                            onClick={handleSubmit}
                                            className="btn btn-primary waves-effect waves-light"
                                          >
                                            Save
                                            {isLoding && <SmallLoader />}
                                          </button>
                                        </div>
                                        <Field
                                          name="firstname"
                                          placeholder="-"
                                          className={`dark-body-text dark-input ${
                                            openIndex === index
                                              ? "form-control"
                                              : "hide-input"
                                          }`}
                                          onClick={(e) => e.stopPropagation()}
                                        />
                                        <Field
                                          name="lastname"
                                          placeholder="-"
                                          className={`dark-body-text dark-input ${
                                            openIndex === index
                                              ? "form-control"
                                              : "hide-input"
                                          }`}
                                          onClick={(e) => e.stopPropagation()}
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        <div className="d-flex align-items-center w-100">
                                          <div>
                                            <button
                                              className="btn waves-effect waves-light py-0 ps-0"
                                              onClick={(e) => {
                                                toggleAccordion(index);
                                              }}
                                            >
                                              <BsPencil className="dark-heading" />
                                              {/* <img
                                                src={pencilIcon}
                                                alt="pencil-icon"
                                              /> */}
                                            </button>
                                          </div>
                                          <div className="flex-grow-1 d-flex gap-2">
                                            <h6
                                              className="my-auto text-nowrap dark-heading"
                                              onClick={(e) =>
                                                handleNavigate(e, main?._id)
                                              }
                                            >
                                              {main?.firstname} {main?.lastname}
                                            </h6>
                                            <div className="d-inline-block">
                                              {main?.role.map((role, i) => {
                                                return (
                                                  <React.Fragment key={i}>
                                                    {role !== "MAIN" && (
                                                      <span
                                                        className={`${mainAndClientRoleClass[role]}`}
                                                      >
                                                        {role
                                                          ?.charAt(0)
                                                          .toUpperCase()}
                                                      </span>
                                                    )}
                                                  </React.Fragment>
                                                );
                                              })}
                                            </div>

                                            {main?.sub_id
                                              ?.subscription_name && (
                                              <span
                                                className={
                                                  subscriptionStatusClass[
                                                    main?.sub_id
                                                      ?.subscription_name
                                                  ]
                                                }
                                              >
                                                {
                                                  main?.sub_id
                                                    ?.subscription_name
                                                }
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Accordion.Header>

                            <Accordion.Body>
                              <ul className="list-unstyled mb-0 mt-2">
                                <li className="d-flex align-items-center mb-3">
                                  <span className="fw-medium mx-2 text-heading dark-heading">
                                    Email:
                                  </span>
                                  <Field
                                    readOnly
                                    type="email"
                                    name="email"
                                    className={`dark-body-text dark-input ${
                                      isEdit ? "form-control" : "hide-input"
                                    }`}
                                    placeholder="-"
                                  />
                                  {main?.active === false && (
                                    <btn
                                      className="text-primary text-decoration-underline cursor-pointer ms-2"
                                      onClick={(e) => {
                                        setUserId(main?._id);
                                        setModelShow(true);
                                      }}
                                    >
                                      Activate
                                    </btn>
                                  )}
                                </li>
                                <li className="d-flex align-items-center mb-3 mt-2">
                                  <span className="fw-medium mx-2 text-nowrap dark-heading">
                                    Contact:
                                  </span>
                                  <PhoneInput
                                    disabled={!isEdit}
                                    country={"Luxembourg"}
                                    enableSearch={true}
                                    className={`dark-body-text dark-input ${
                                      isEdit
                                        ? "form-control flex-grow-1"
                                        : "hide-input"
                                    }`}
                                    placeholder="+352 369 852 472"
                                    value={
                                      values?.contact?.toString() || "+352"
                                    }
                                    onChange={(value) =>
                                      setFieldValue("contact", value)
                                    }
                                  />
                                </li>
                                {!isEdit ? (
                                  <li className="d-flex align-items-start mb-3">
                                    <span className="fw-medium mx-2 text-heading text-nowrap dark-heading">
                                      Address:
                                    </span>
                                    <textarea
                                      value={values.address}
                                      name="address"
                                      id="address"
                                      className="dark-body-text dark-input w-100 hide-input resize-none"
                                    />
                                  </li>
                                ) : (
                                  <li className="d-flex align-items-center mb-3">
                                    <span className="fw-medium mx-2 text-heading text-nowrap dark-heading">
                                      Address:
                                    </span>
                                    <AddressAutocompleteInput
                                      name="address"
                                      values={values}
                                      setFieldValue={setFieldValue}
                                    />
                                  </li>
                                )}

                                <li className="d-flex align-items-center mb-3">
                                  <span className="fw-medium mx-2 text-heading text-nowrap dark-heading">
                                    Street/Building No:
                                  </span>

                                  <Field
                                    readOnly={!isEdit}
                                    name="buildingNumber"
                                    placeholder="Street/building number"
                                    className={`dark-body-text dark-input ${
                                      !isEdit ? "hide-input" : "form-control"
                                    }`}
                                  />
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                  <span className="fw-medium mx-2 text-heading dark-heading">
                                    DOB:
                                  </span>
                                  <input
                                    readOnly={!isEdit}
                                    type="Date"
                                    value={values?.DOB}
                                    onChange={handleChange}
                                    name="DOB"
                                    className={`dark-body-text dark-input ${
                                      isEdit ? "form-control" : "hide-input"
                                    }`}
                                    placeholder=""
                                  />
                                </li>
                                <hr />
                                <small className="card-text text-uppercase text-muted dark-heading">
                                  CONTACTS
                                </small>
                                <li className="d-flex align-items-center  mt-2 mb-3 ">
                                  <span className="fw-medium mx-2 text-heading dark-heading">
                                    Languages:
                                  </span>
                                  <Dropdown
                                    disabled={!isEdit}
                                    options={["FR", "EN"]}
                                    placeholder="Language"
                                    className={`dark-body-text dark-input w-full ${
                                      !isEdit ? "p-dropdown-hide" : "p-dropdown"
                                    }`}
                                    name="language"
                                    value={values.language}
                                    onChange={handleChange}
                                  />
                                </li>
                                <li className="d-flex align-items-center  mb-3">
                                  <span className="fw-medium mx-2 text-heading dark-heading">
                                    Gender:
                                  </span>
                                  <Dropdown
                                    disabled={!isEdit}
                                    options={["Male", "Female", "Other"]}
                                    placeholder="Gender"
                                    name="gender"
                                    value={values.gender}
                                    className={`dark-body-text dark-input w-full ${
                                      !isEdit ? "p-dropdown-hide" : "p-dropdown"
                                    }`}
                                    onChange={handleChange}
                                  />
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                  <span className="fw-medium mx-2 text-heading text-nowrap dark-heading">
                                    Permis B :
                                  </span>
                                  <input
                                    readOnly={!isEdit}
                                    type="Date"
                                    value={values?.licensedate}
                                    onChange={handleChange}
                                    name="licensedate"
                                    className={`dark-body-text dark-input ${
                                      isEdit ? "form-control" : "hide-input"
                                    }`}
                                    placeholder="-"
                                  />
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            {customer?.AUX?.map((aux, auxindex) => (
              <>
                <Auxdata
                  id={id}
                  aux={aux}
                  setLoding={setLoding}
                  setEdit={setEdit}
                  openMain={openMain}
                  toggleAccordion={toggleAccordion}
                  toggleAccordionMain={toggleAccordionMain}
                  openAuxIndex={openAuxIndex}
                  setOpenAuxIndex={setOpenAuxIndex}
                  isLoding={isLoding}
                  handleNavigate={handleNavigate}
                  setUserId={setUserId}
                  toggleAuxAccordion={toggleAuxAccordion}
                  setModelShow={setModelShow}
                  main={main}
                  pencilIcon={pencilIcon}
                />
              </>
            ))}
          </React.Fragment>
        );
      })}
      {isModelShow && (
        <CustomerActivationModale
          show={isModelShow}
          onHide={() => setModelShow(false)}
          title={"are you sure you want ot send active link"}
          id={userId}
        />
      )}
    </>
  );
};

export default ConnectedCustomers;
