/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFilterReservationById,
  getCarHistory,
} from "../../../../Redux/clientsSlice";
import { formatDate } from "../../../Common/FormatDate";
import { useParams } from "react-router-dom";

import ConfirmedReservation from "../../Modals/ConfirmedReservation";
import TableFooter from "../../../Common/TableFooter";
import { pagination } from "../../../Common/Pagination";
import { Loader } from "../../../Common/Loader";
import { ReservationStatus } from "../../../Common/InvoiceStatus";

const Future = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [itemsPerPage, setitemsPerPage] = useState("5");
  const [currentPage, setCurrentPage] = useState(0);
  const [confirmModalShow, setconfirmdModalShow] = useState(false);
  const [reservationId, setReservationId] = useState(null);
  const { futureReservationsList, commonLoading } = useSelector(
    (state) => state?.clientsSlice
  );

  const clientListData = pagination(
    futureReservationsList,
    currentPage,
    itemsPerPage
  );

  useEffect(() => {
    dispatch(fetchFilterReservationById(id));
  }, []);

  const aTozSorting = (value) => {
    const data = futureReservationsList
      ?.slice()
      .sort((a, b) =>
        a?.general_id?.[value]?.name?.localeCompare(
          b?.general_id?.[value]?.name
        )
      );
    dispatch(getCarHistory(data));
  };

  const zToaSorting = (value) => {
    const data = futureReservationsList
      ?.slice()
      .sort((a, b) =>
        b?.general_id?.[value]?.name?.localeCompare(
          a?.general_id?.[value]?.name
        )
      );
    dispatch(getCarHistory(data));
  };

  const handelModel = (id) => {
    setReservationId(id);
    setconfirmdModalShow(true);
  };

  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  return (
    <>
      <div className="card-body table-responsive pb-0">
        <section className="new-table">
          <table className="table  table-hover">
            <thead>
              <tr>
                <th>
                  <span className="position-relative top-4">CAR/Date</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp
                        fontSize={12}
                        onClick={() => aTozSorting("manufacturer")}
                      />
                      <BsChevronDown
                        fontSize={12}
                        onClick={() => zToaSorting("manufacturer")}
                      />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">STATUS</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp fontSize={12} />
                      <BsChevronDown fontSize={12} />
                    </span>
                  </div>
                </th>
                <th>
                  <span className="position-relative top-4">ACTIONS</span>
                  <div className="d-inline-block item-center">
                    <span className="d-flex flex-column ps-1">
                      <BsChevronUp fontSize={12} />
                      <BsChevronDown fontSize={12} />
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            {!commonLoading && (
              <tbody>
                {clientListData.length > 0 &&
                  clientListData?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="name dark-body-text">
                          <span>{item?.general_id?.manufacturer?.name}</span>
                          <span className="ps-2">
                            {item?.general_id?.model?.name}
                          </span>
                          <span className="ps-2">{item?.declination}</span>
                        </div>
                        <div className="email">
                          {formatDate(item?.start_date)}-
                          {formatDate(item?.return_date)}
                        </div>
                      </td>
                      <td className="text-nowrap ">
                        <span
                          className={`btn waves-effect py-1 mx-1 my-1 ${
                            item?.status === "Confirmed"
                              ? "btn-label-confirmed"
                              : item?.status === "Wait Confirmed"
                              ? "btn-label-info"
                              : "btn-label-notconfirmed"
                          }`}
                        >
                          {ReservationStatus(item?.status)}
                        </span>
                      </td>

                      <td>
                        <div className="d-flex gap-3 cursor-pointer">
                          <i className="ti ti-circle-x ti-sm lh-1"></i>
                          <i className="ti ti-edit ti-sm lh-1"></i>
                          {item?.status === "Wait Confirmed" && (
                            <i
                              className="ti ti-check ti-sm lh-1"
                              onClick={() => handelModel(item?._id)}
                            ></i>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </section>

        {commonLoading && (
          <div className="card-body">
            <Loader />
          </div>
        )}
        {clientListData === false && !commonLoading && (
          <div className="text-center pt-4 pb-4 mb-2">No Data Found</div>
        )}
        <ConfirmedReservation
          show={confirmModalShow}
          reservationId={reservationId}
          onHide={() => setconfirmdModalShow(false)}
        />
      </div>
      {clientListData.length > 0 && (
        <TableFooter
          itemsPerPage={itemsPerPage}
          handleSelectChange={handleSelectChange}
          listData={clientListData}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Future;
