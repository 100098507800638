import React, { useState, useEffect } from "react";
import { PiCircleThin } from "react-icons/pi";
import "../../css/check-final.css";
import { checkOutAddFinal, checkStatusUpdate } from "../../services/check";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCheckFinalListById,
  fetchCheckSingle,
} from "../../Redux/checkSlice";
// import { float } from 'html2canvas/dist/types/css/property-descriptors/float';
// import { finalCheckList } from './CheckList';

const CheckFinal = () => {
  const { id, generalId, resvStatus } = useParams();

  const conditions = [
    { id: 1, condition: "Bon Etat" },
    { id: 2, condition: "Moyen" },
    { id: 3, condition: "Mauvais" },
  ];

  const initialInputConditions = [
    { id: 1, tire_value: "", AV_D: 1 },
    { id: 2, tire_value: "", AV_G: 1 },
    { id: 3, tire_value: "", AR_G: 1 },
    { id: 4, tire_value: "", AR_D: 1 },
  ];

  const initialChecks = [
    { id: 1, item: "Écrou de sécurité", dotcondition: "neutral" },
    { id: 2, item: "Éléments de sécurités", dotcondition: "neutral" },
    { id: 3, item: "Bouteille d'huile", dotcondition: "neutral" },
    { id: 4, item: "Baie moteur", dotcondition: "neutral" },
    { id: 5, item: "Le niveau d'huile", dotcondition: "neutral" },
    { id: 6, item: "Liquide de refroidissement", dotcondition: "neutral" },
    { id: 7, item: "Liquide Lave glace", dotcondition: "neutral" },
    { id: 8, item: "Liquide PiFilmReelThin", dotcondition: "neutral" },
    { id: 9, item: "Document du véhicule", dotcondition: "neutral" },
    { id: 10, item: "Carte grise", dotcondition: "neutral" },
    { id: 11, item: "Vignette fiscale", dotcondition: "neutral" },
    { id: 12, item: "Assurance", dotcondition: "neutral" },
  ];

  const [checkStates, setCheckStates] = useState([...initialChecks]);
  const [inputFields, setInputFields] = useState([...initialInputConditions]);
  const dispatch = useDispatch();
  const { finalCheckList } = useSelector((state) => state?.checkSlice);
  const navigate = useNavigate();
  // const finalCheckList = null;

  useEffect(() => {
    dispatch(fetchCheckFinalListById(generalId));
  }, [generalId]);

  const handleIconClick = (id) => {
    const index = checkStates.findIndex((item) => item.id === id);
    if (index !== -1) {
      let nextCondition = "";
      switch (checkStates[index].dotcondition) {
        case "neutral":
          nextCondition = "good";
          break;
        case "good":
          nextCondition = "critical";
          break;
        case "critical":
          nextCondition = "neutral";
          break;
        default:
          nextCondition = "neutral";
      }
      const updatedCheckStates = [...checkStates];
      updatedCheckStates[index] = {
        ...updatedCheckStates[index],
        dotcondition: nextCondition,
      };
      setCheckStates(updatedCheckStates);
    }
  };

  const handleConditions = (id) => {
    const inputAV = [...inputFields];
    switch (id) {
      case 0:
        const zeroConditionId = (inputAV[id].AV_D % conditions.length) + 1;
        inputAV[id] = { ...inputAV[id], AV_D: zeroConditionId };
        setInputFields(inputAV);
      case 1:
        const firstConditionId = (inputAV[id].AV_G % conditions.length) + 1;
        inputAV[id] = { ...inputAV[id], AV_G: firstConditionId };
        setInputFields(inputAV);
      case 2:
        const secondConditionId = (inputAV[id].AR_G % conditions.length) + 1;
        inputAV[id] = { ...inputAV[id], AR_G: secondConditionId };
        setInputFields(inputAV);
      case 3:
        const thirdConditionId = (inputAV[id].AR_D % conditions.length) + 1;
        inputAV[id] = { ...inputAV[id], AR_D: thirdConditionId };
        setInputFields(inputAV);
    }
  };

  const handleInput = (e, i) => {
    const value = e.target.value;
    if (Number.isInteger(Number(value)) || Number.isFinite(parseFloat(value))) {
      const inputValues = [...inputFields];
      inputValues[i - 1] = { ...inputValues[i - 1], tire_value: value };
      setInputFields(inputValues);
    }
  };

  function isenable() {
    const enabledCount = checkStates.filter(
      (item) => item.dotcondition !== "neutral"
    ).length;
    return enabledCount === checkStates.length;
  }

  useEffect(() => {
    if (finalCheckList) {
      const mapConditionToId = (condition) => {
        switch (condition) {
          case "Good Condition":
            return 1;
          case "Average":
            return 2;
          case "Critical":
            return 3;
          default:
            return 1;
        }
      };

      setInputFields([
        {
          id: 1,
          tire_value: finalCheckList.tire_AV_D_value,
          AV_D: mapConditionToId(finalCheckList.tire_AV_D),
        },
        {
          id: 2,
          tire_value: finalCheckList.tire_AV_G_value,
          AV_G: mapConditionToId(finalCheckList.tire_AV_G),
        },
        {
          id: 3,
          tire_value: finalCheckList.tire_AR_G_value,
          AR_G: mapConditionToId(finalCheckList.tire_AR_G),
        },
        {
          id: 4,
          tire_value: finalCheckList.tire_AR_D_value,
          AR_D: mapConditionToId(finalCheckList.tire_AR_D),
        },
      ]);

      const updatedCheckStates = [...initialChecks];
      updatedCheckStates[0].dotcondition = finalCheckList.safety_nut;
      updatedCheckStates[1].dotcondition = finalCheckList.safety_elements;
      updatedCheckStates[2].dotcondition = finalCheckList.bottle_of_oil;
      updatedCheckStates[3].dotcondition = finalCheckList.very_motor;
      updatedCheckStates[4].dotcondition = finalCheckList.oil_level;
      updatedCheckStates[5].dotcondition = finalCheckList.cooling_liquid;
      updatedCheckStates[6].dotcondition =
        finalCheckList.windshield_washer_fluid;
      updatedCheckStates[7].dotcondition = finalCheckList.brake_fluid;
      updatedCheckStates[8].dotcondition = finalCheckList.vehicle_document;
      updatedCheckStates[9].dotcondition = finalCheckList.gray_card;
      updatedCheckStates[10].dotcondition = finalCheckList.tax_vignettes;
      updatedCheckStates[11].dotcondition = finalCheckList.assurance;

      setCheckStates(updatedCheckStates);
    }
  }, [finalCheckList]);

  const handlePayload = async () => {
    const tirePayload = {
      general_id: generalId,
      tire_AV_D:
        conditions[inputFields[0].AV_D - 1].condition === "Bon Etat"
          ? "Good Condition"
          : conditions[inputFields[0].AV_D - 1].condition === "Moyen"
          ? "Average"
          : "Critical",
      tire_AV_D_value: inputFields[0].tire_value,
      tire_AV_G:
        conditions[inputFields[1].AV_G - 1].condition === "Bon Etat"
          ? "Good Condition"
          : conditions[inputFields[1].AV_G - 1].condition === "Moyen"
          ? "Average"
          : "Critical",
      tire_AV_G_value: inputFields[1].tire_value,
      tire_AR_G:
        conditions[inputFields[2].AR_G - 1].condition === "Bon Etat"
          ? "Good Condition"
          : conditions[inputFields[2].AR_G - 1].condition === "Moyen"
          ? "Average"
          : "Critical",
      tire_AR_G_value: inputFields[2].tire_value,
      tire_AR_D:
        conditions[inputFields[3].AR_D - 1].condition === "Bon Etat"
          ? "Good Condition"
          : conditions[inputFields[3].AR_D - 1].condition === "Moyen"
          ? "Average"
          : "Critical",
      tire_AR_D_value: inputFields[3].tire_value,
      safety_nut: checkStates[0]?.dotcondition || "unknown",
      safety_elements: checkStates[1]?.dotcondition || "unknown",
      bottle_of_oil: checkStates[2]?.dotcondition || "unknown",
      very_motor: checkStates[3]?.dotcondition || "unknown",
      oil_level: checkStates[4]?.dotcondition || "unknown",
      cooling_liquid: checkStates[5]?.dotcondition || "unknown",
      windshield_washer_fluid: checkStates[6]?.dotcondition || "unknown",
      brake_fluid: checkStates[7]?.dotcondition || "unknown",
      vehicle_document: checkStates[8]?.dotcondition || "unknown",
      gray_card: checkStates[9]?.dotcondition || "unknown",
      tax_vignettes: checkStates[10]?.dotcondition || "unknown",
      assurance: checkStates[11]?.dotcondition || "unknown",
    };
    let res = await checkOutAddFinal(tirePayload);
    if (res) {
      let payload = {
        reservationId: id,
      };
      if (resvStatus && resvStatus === "CHECKOUT") {
        payload["reservation_status"] = "DELIVERYOUT";
      }
      if (resvStatus && resvStatus === "CHECKIN") {
        payload["reservation_status"] = "CHECKINCOMPLETED";
      }
      let checkStatus = await checkStatusUpdate(payload);
      if (checkStatus) {
        navigate("/checks/checkList");
      }
    }
  };

  return (
    <>
      <div className="card check-final">
        <div className="card-header bg-primary text-white text-center">
          Featured
        </div>
        <div className="card-body">
          <h5 className="card-title text-center">Pneu</h5>
          <div className="mb-2">AV.D</div>
          <button
            type="button"
            className={`name-btn ${
              conditions[inputFields[0].AV_D - 1]?.id === 1
                ? "co-grn"
                : conditions[inputFields[0].AV_D - 1]?.id === 2
                ? "co-orng"
                : "co-red"
            }`}
            onClick={() => handleConditions(0)}
          >
            {conditions[inputFields[0].AV_D - 1].condition}
          </button>
          <input
            type="text"
            placeholder="mm"
            className="default mb-1"
            value={inputFields[0].tire_value}
            onChange={(e) => handleInput(e, 1)}
          />
          <div className="mb-2">AV.G</div>
          <button
            type="button"
            className={`name-btn ${
              conditions[inputFields[1].AV_G - 1]?.id === 1
                ? "co-grn"
                : conditions[inputFields[1].AV_G - 1]?.id === 2
                ? "co-orng"
                : "co-red"
            }`}
            onClick={() => handleConditions(1)}
          >
            {conditions[inputFields[1].AV_G - 1]?.condition}
          </button>
          <input
            type="text"
            placeholder="mm"
            className="default mb-1"
            value={inputFields[1].tire_value}
            onChange={(e) => handleInput(e, 2)}
          />
          <div className="mb-2">AR.G</div>
          <button
            type="button"
            className={`name-btn ${
              conditions[inputFields[2].AR_G - 1]?.id === 1
                ? "co-grn"
                : conditions[inputFields[2].AR_G - 1]?.id === 2
                ? "co-orng"
                : "co-red"
            }`}
            onClick={() => handleConditions(2)}
          >
            {conditions[inputFields[2].AR_G - 1]?.condition}
          </button>
          <input
            type="text"
            placeholder="mm"
            className="default mb-1"
            value={inputFields[2].tire_value}
            onChange={(e) => handleInput(e, 3)}
          />
          <div className="mb-2">AR.D</div>
          <button
            type="button"
            className={`name-btn ${
              conditions[inputFields[3].AR_D - 1]?.id === 1
                ? "co-grn"
                : conditions[inputFields[3].AR_D - 1]?.id === 2
                ? "co-orng"
                : "co-red"
            }`}
            onClick={() => handleConditions(3)}
          >
            {conditions[inputFields[3].AR_D - 1]?.condition}
          </button>
          <input
            type="text"
            placeholder="mm"
            className="default mb-1"
            value={inputFields[3].tire_value}
            onChange={(e) => handleInput(e, 4)}
          />
        </div>
        <div className="card-footer text-body-secondary">
          <h5 className="text-center mt-3 chk-final">Check final</h5>
          {checkStates.map((e) => {
            const checkState = checkStates.find((item) => item.id === e.id);
            const partsCondition = checkState
              ? checkState.dotcondition
              : "neutral";
            return (
              <ul className="d-flex px-0" key={e.id}>
                <li>{e.item}</li>
                <div>
                  <PiCircleThin
                    className={`circle-chk`}
                    style={{
                      backgroundColor:
                        partsCondition === "neutral"
                          ? "rgba(128, 128, 128, 0.5)"
                          : partsCondition === "good"
                          ? "rgba(4, 245, 85, 0.3)"
                          : partsCondition === "critical"
                          ? "rgba(255, 0, 0, 0.2)"
                          : "rgba(128, 128, 128, 0.5)",
                    }}
                    fontSize={26}
                    onClick={() => handleIconClick(e.id)}
                  />
                </div>
              </ul>
            );
          })}
        </div>
      </div>
      <button
        type="button"
        className={`btn btn-primary mt-5 w-100`}
        disabled={!isenable()}
        onClick={() => handlePayload()}
      >
        Valider
      </button>
    </>
  );
};

export default CheckFinal;
