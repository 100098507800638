import { Badge } from "react-bootstrap";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";

const roles = [
  {
    label: "WEB DÉVELOPPEUR",
    color: "success",
  },
  {
    label: "COMPTABILITÉ",
    color: "warning",
  },
  {
    label: "LOGISTIQUE",
    color: "danger",
  },
  {
    label: "R&D",
    color: "light",
  },
  {
    label: "SALES",
    color: "primary",
  },
];

const position = [
  {
    label: "SÉNIOR",
    color: "info",
  },
  {
    label: "JUNIOR",
    color: "info",
  },
  {
    label: "GÉRANT",
    color: "info",
  },
  {
    label: "ADMINISTRATEUR",
    color: "dark",
  },
];
const AddUser = ({ data, onClose, addUserd, setAddUserd, addUser }) => {
  const validateData = !data.fName || !data.lName || !data.email;
  return (
    <div
      className="d-flex flex-column gap-3 p-0 edit-role"
      style={{ background: "transparent !important" }}
    >
      <div style={{ background: "#FFF" }} className="section-role">
        <div
          style={{
            background:
              !data?.role && data?.position !== "ADMINISTRATEUR" && "#0084ff14",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            padding: "20px",
          }}
          className={
            !data?.role &&
            data?.position !== "ADMINISTRATEUR" &&
            "add-container"
          }
        >
          {roles.map((r) => (
            <div className="d-flex gap-3 align-items-center">
              <input
                type="radio"
                checked={data?.role === r.label}
                onChange={() =>
                  setAddUserd({
                    ...addUserd,
                    role: r.label,
                    roleColor: r.color,
                  })
                }
                disabled={data?.position === "ADMINISTRATEUR"}
              />
              <span>
                <Badge bg={r.color}>{r.label}</Badge>
              </span>
            </div>
          ))}
        </div>
      </div>
      <div style={{ background: "#FFF" }} className="section-role">
        <div
          style={{
            background: !data?.position && "#0084ff14",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            padding: "20px",
          }}
          className={!data?.position && "add-container"}
        >
          {position.map((p) => (
            <div className="d-flex gap-3 align-items-center">
              <input
                type="radio"
                checked={data?.position === p.label}
                onChange={() =>
                  setAddUserd({
                    ...addUserd,
                    position: p.label,
                    positionColor: p.color,
                    ...(p.label === "ADMINISTRATEUR"
                      ? { role: "", roleColor: "" }
                      : {}),
                  })
                }
              />
              <span>
                <Badge bg={p.color}>{p.label}</Badge>
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="d-flex gap-2 actions-role">
        <button
          className="btn btn-primary w-full d-flex gap-2"
          disabled={validateData}
          onClick={addUser}
        >
          <CheckIcon /> ADD USER
        </button>
        <button className="btn border w-full d-flex gap-2" onClick={onClose}>
          <XIcon />
          CANCEL
        </button>
      </div>
    </div>
  );
};

export default AddUser;
