import { useEffect, useState } from "react";
import ManageCompanySection from "./ManageCompanySection";
import { useFormik } from "formik";
import {
  addSubscription,
  getSubscription,
  updataSubscriptionValue,
} from "../../services/Company/addSubscriptionApi";
import { useOutletContext } from "react-router-dom";
import { ButtonBase } from "@mui/material";

const AbonementPlus = ({ sub, forS, compId }) => {
  const allProps = useOutletContext();
  const setFetchCompanylist = allProps[4];
  const [getSubscriptionD, setgetSubscriptionD] = useState();
  const [loading, setloading] = useState(false);
  const [cats, setCats] = useState([1, 2, 3, 4, 5, 6]);

  useEffect(() => {
    if (sub?.sub_id) {
      // eslint-disable-next-line no-unused-expressions
      (async () => {
        const subscriptionData = await getSubscription(sub?.sub_id);
        setgetSubscriptionD(subscriptionData?.data);
        console.log(subscriptionData);
        // Update Formik values after the data is set
        formik.setValues({
          comp_id: subscriptionData?.data?.subscription?.comp_id || compId,
          subscription_name:
            subscriptionData?.data?.subscription?.subscription_name || forS,
          cpr: subscriptionData?.data?.subscription?.cpr || 0,
          base_cost: {
            category1:
              subscriptionData?.data?.subscription?.base_cost?.category1 || "",
            category2:
              subscriptionData?.data?.subscription?.base_cost?.category2 || "",
            category3:
              subscriptionData?.data?.subscription?.base_cost?.category3 || "",
            category4:
              subscriptionData?.data?.subscription?.base_cost?.category4 || "",
            category5:
              subscriptionData?.data?.subscription?.base_cost?.category5 || "",
            category6:
              subscriptionData?.data?.subscription?.base_cost?.category6 || "",
          },
          cost_per_adk: {
            category1:
              subscriptionData?.data?.subscription?.cost_per_adk?.category1 ||
              "",
            category2:
              subscriptionData?.data?.subscription?.cost_per_adk?.category2 ||
              "",
            category3:
              subscriptionData?.data?.subscription?.cost_per_adk?.category3 ||
              "",
            category4:
              subscriptionData?.data?.subscription?.cost_per_adk?.category4 ||
              "",
            category5:
              subscriptionData?.data?.subscription?.cost_per_adk?.category5 ||
              "",
            category6:
              subscriptionData?.data?.subscription?.cost_per_adk?.category6 ||
              "",
          },
          total_loss_theft_access: {
            category1:
              subscriptionData?.data?.subscription?.total_loss_theft_access
                ?.category1 || "",
            category2:
              subscriptionData?.data?.subscription?.total_loss_theft_access
                ?.category2 || "",
            category3:
              subscriptionData?.data?.subscription?.total_loss_theft_access
                ?.category3 || "",
            category4:
              subscriptionData?.data?.subscription?.total_loss_theft_access
                ?.category4 || "",
            category5:
              subscriptionData?.data?.subscription?.total_loss_theft_access
                ?.category5 || "",
            category6:
              subscriptionData?.data?.subscription?.total_loss_theft_access
                ?.category6 || "",
          },
          bail: {
            category1:
              subscriptionData?.data?.subscription?.bail?.category1 || "",
            category2:
              subscriptionData?.data?.subscription?.bail?.category2 || "",
            category3:
              subscriptionData?.data?.subscription?.bail?.category3 || "",
            category4:
              subscriptionData?.data?.subscription?.bail?.category4 || "",
            category5:
              subscriptionData?.data?.subscription?.bail?.category5 || "",
            category6:
              subscriptionData?.data?.subscription?.bail?.category6 || "",
          },
          exte_cleaning: {
            category1:
              subscriptionData?.data?.subscription?.exte_cleaning?.category1 ||
              "",
            category2:
              subscriptionData?.data?.subscription?.exte_cleaning?.category2 ||
              "",
            category3:
              subscriptionData?.data?.subscription?.exte_cleaning?.category3 ||
              "",
            category4:
              subscriptionData?.data?.subscription?.exte_cleaning?.category4 ||
              "",
            category5:
              subscriptionData?.data?.subscription?.exte_cleaning?.category5 ||
              "",
            category6:
              subscriptionData?.data?.subscription?.exte_cleaning?.category6 ||
              "",
          },
          inte_cleaning: {
            category1:
              subscriptionData?.data?.subscription?.inte_cleaning?.category1 ||
              "",
            category2:
              subscriptionData?.data?.subscription?.inte_cleaning?.category2 ||
              "",
            category3:
              subscriptionData?.data?.subscription?.inte_cleaning?.category3 ||
              "",
            category4:
              subscriptionData?.data?.subscription?.inte_cleaning?.category4 ||
              "",
            category5:
              subscriptionData?.data?.subscription?.inte_cleaning?.category5 ||
              "",
            category6:
              subscriptionData?.data?.subscription?.inte_cleaning?.category6 ||
              "",
          },
          vehicle_immobilization_cost: {
            category1:
              subscriptionData?.data?.subscription?.vehicle_immobilization_cost
                ?.category1 || "",
            category2:
              subscriptionData?.data?.subscription?.vehicle_immobilization_cost
                ?.category2 || "",
            category3:
              subscriptionData?.data?.subscription?.vehicle_immobilization_cost
                ?.category3 || "",
            category4:
              subscriptionData?.data?.subscription?.vehicle_immobilization_cost
                ?.category4 || "",
            category5:
              subscriptionData?.data?.subscription?.vehicle_immobilization_cost
                ?.category5 || "",
            category6:
              subscriptionData?.data?.subscription?.vehicle_immobilization_cost
                ?.category6 || "",
          },
          miscSetting: {
            admin_cost: subscriptionData?.data?.miscSetting?.admin_cost || "",
            add_cost: subscriptionData?.data?.miscSetting?.add_cost || "",
            svarnish: subscriptionData?.data?.miscSetting?.svarnish || "",
            lvarnish: subscriptionData?.data?.miscSetting?.lvarnish || "",
            redowheel: subscriptionData?.data?.miscSetting?.redowheel || "",
            fraisdActivation:
              subscriptionData?.miscSetting?.fraisdActivation || "",
          },
          cats: !subscriptionData?.data?.subscription?.cats?.length
            ? cats
            : subscriptionData?.data?.subscription?.cats,
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub]);

  const isInitialValues2 = {
    comp_id: getSubscriptionD?.subscription?.comp_id || compId,
    subscription_name:
      getSubscriptionD?.subscription?.subscription_name || forS,
    cpr: getSubscriptionD?.subscription?.cpr || 0,
    base_cost: {
      category1: getSubscriptionD?.subscription?.base_cost?.category1 || "",
      category2: getSubscriptionD?.subscription?.base_cost?.category2 || "",
      category3: getSubscriptionD?.subscription?.base_cost?.category3 || "",
      category4: getSubscriptionD?.subscription?.base_cost?.category4 || "",
      category5: getSubscriptionD?.subscription?.base_cost?.category5 || "",
      category6: getSubscriptionD?.subscription?.base_cost?.category6 || "",
    },
    cost_per_adk: {
      category1: getSubscriptionD?.subscription?.cost_per_adk?.category1 || "",
      category2: getSubscriptionD?.subscription?.cost_per_adk?.category2 || "",
      category3: getSubscriptionD?.subscription?.cost_per_adk?.category3 || "",
      category4: getSubscriptionD?.subscription?.cost_per_adk?.category4 || "",
      category5: getSubscriptionD?.subscription?.cost_per_adk?.category5 || "",
      category6: getSubscriptionD?.subscription?.cost_per_adk?.category6 || "",
    },
    total_loss_theft_access: {
      category1:
        getSubscriptionD?.subscription?.total_loss_theft_access?.category1 ||
        "",
      category2:
        getSubscriptionD?.subscription?.total_loss_theft_access?.category2 ||
        "",
      category3:
        getSubscriptionD?.subscription?.total_loss_theft_access?.category3 ||
        "",
      category4:
        getSubscriptionD?.subscription?.total_loss_theft_access?.category4 ||
        "",
      category5:
        getSubscriptionD?.subscription?.total_loss_theft_access?.category5 ||
        "",
      category6:
        getSubscriptionD?.subscription?.total_loss_theft_access?.category6 ||
        "",
    },
    bail: {
      category1: getSubscriptionD?.subscription?.bail?.category1 || "",
      category2: getSubscriptionD?.subscription?.bail?.category2 || "",
      category3: getSubscriptionD?.subscription?.bail?.category3 || "",
      category4: getSubscriptionD?.subscription?.bail?.category4 || "",
      category5: getSubscriptionD?.subscription?.bail?.category5 || "",
      category6: getSubscriptionD?.subscription?.bail?.category6 || "",
    },
    exte_cleaning: {
      category1: getSubscriptionD?.subscription?.exte_cleaning?.category1 || "",
      category2: getSubscriptionD?.subscription?.exte_cleaning?.category2 || "",
      category3: getSubscriptionD?.subscription?.exte_cleaning?.category3 || "",
      category4: getSubscriptionD?.subscription?.exte_cleaning?.category4 || "",
      category5: getSubscriptionD?.subscription?.exte_cleaning?.category5 || "",
      category6: getSubscriptionD?.subscription?.exte_cleaning?.category6 || "",
    },
    inte_cleaning: {
      category1: getSubscriptionD?.subscription?.inte_cleaning?.category1 || "",
      category2: getSubscriptionD?.subscription?.inte_cleaning?.category2 || "",
      category3: getSubscriptionD?.subscription?.inte_cleaning?.category3 || "",
      category4: getSubscriptionD?.subscription?.inte_cleaning?.category4 || "",
      category5: getSubscriptionD?.subscription?.inte_cleaning?.category5 || "",
      category6: getSubscriptionD?.subscription?.inte_cleaning?.category6 || "",
    },
    vehicle_immobilization_cost: {
      category1:
        getSubscriptionD?.subscription?.vehicle_immobilization_cost
          ?.category1 || "",
      category2:
        getSubscriptionD?.subscription?.vehicle_immobilization_cost
          ?.category2 || "",
      category3:
        getSubscriptionD?.subscription?.vehicle_immobilization_cost
          ?.category3 || "",
      category4:
        getSubscriptionD?.subscription?.vehicle_immobilization_cost
          ?.category4 || "",
      category5:
        getSubscriptionD?.subscription?.vehicle_immobilization_cost
          ?.category5 || "",
      category6:
        getSubscriptionD?.subscription?.vehicle_immobilization_cost
          ?.category6 || "",
    },
    miscSetting: {
      admin_cost: getSubscriptionD?.miscSetting?.admin_cost || "",
      add_cost: getSubscriptionD?.miscSetting?.add_cost || "",
      svarnish: getSubscriptionD?.miscSetting?.svarnish || "",
      lvarnish: getSubscriptionD?.miscSetting?.lvarnish || "",
      redowheel: getSubscriptionD?.miscSetting?.redowheel || "",
      fraisdActivation: getSubscriptionD?.miscSetting?.fraisdActivation || "",
    },
    cats: cats,
  };
  const formik = useFormik({
    initialValues: isInitialValues2,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const formData = {
        ...values,
        comp_id: values?.comp_id?._id || values?.comp_id,
      };
      if (getSubscriptionD) {
        setloading(true);
        await updataSubscriptionValue(
          getSubscriptionD?.subscription?._id,
          formData
        );
        setFetchCompanylist(getSubscriptionD?._id, formData); //callCompanyList
        setloading(false);
      } else {
        setloading(true);
        await addSubscription(formData);
        setFetchCompanylist(values?.subscription_name); //callCompanyList
        setloading(false);
      }
    },
    // Add validation rules here
    validate: (values) => {
      console.log("🚀 ~ AddNewSubscription ~ values:", values);
      const errors = {};
      console.log("🚀 ~ AddNewSubscription ~ errors:", errors);
      if (!values.comp_id) {
        errors.comp_id = "Company is required.";
      }
      if (!values.subscription_name.trim()) {
        errors.subscription_name = "Subscription name is required.";
      }
      if (!values.cpr.toString().trim()) {
        errors.cpr = "CPR is required.";
      }
      //   if (!values?.base_cost?.category1?.toString()?.trim()) {
      //     errors.base_cost = "Base Cost is required.";
      //   }
      if (!values.cost_per_adk?.category1?.toString()?.trim()) {
        errors.cost_per_adk = "Cost per ADK is required.";
      }
      if (!values.total_loss_theft_access?.category1?.toString()?.trim()) {
        errors.total_loss_theft_access = "Total Loss Theft is required.";
      }
      if (!values.bail?.category1?.toString()?.trim()) {
        errors.bail = "Bail is required.";
      }
      if (!values.exte_cleaning?.category1?.toString()?.trim()) {
        errors.exte_cleaning = "Exte. Cleaning is required.";
      }
      if (!values.inte_cleaning?.category1?.toString()?.trim()) {
        errors.inte_cleaning = "Inte. Cleaning is required.";
      }
      if (!values.vehicle_immobilization_cost?.category1?.toString()?.trim()) {
        errors.vehicle_immobilization_cost = "Immobilization Cost is required.";
      }
      return errors;
      // Add validation for other fields
    },
  });

  return (
    <ManageCompanySection
      label={`Abonnements - ${sub?.sub_name || forS}`}
      expandClose={true}
      component={(editMode) => (
        <div className="comp-content-form">
          <div className="row ">
            <div className="col-2">
              <label className="form-label" htmlFor="username-modern-vertical">
                Frais d’activation
              </label>
              <input
                type="number"
                id="username-modern-vertical"
                className={`${!editMode ? "hide-input" : "form-control"}`}
                placeholder="Coût"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.miscSetting.fraisdActivation}
                name="miscSetting.fraisdActivation"
                disabled={!editMode}
              />
            </div>
            <div className="col-2">
              <label className="form-label" htmlFor="username-modern-vertical">
                Cout Mensuel
              </label>
              <input
                type="number"
                id="username-modern-vertical"
                className={`${!editMode ? "hide-input" : "form-control"}`}
                placeholder="Coût"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cpr}
                name="cpr"
                disabled={!editMode}
              />
            </div>
            <div className="col-2">
              <label className="form-label" htmlFor="username-modern-vertical">
                Coût Administratif
              </label>
              <input
                type="number"
                id="username-modern-vertical"
                placeholder="Coût"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.miscSetting.admin_cost}
                name="miscSetting.admin_cost"
                className={`${!editMode ? "hide-input" : "form-control"}`}
                disabled={!editMode}
              />
            </div>
            <div className="col-2">
              <label className="form-label" htmlFor="password-modern-vertical">
                Petit Polissage
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="number"
                  id="password-modern-vertical"
                  placeholder="Petit vernis"
                  aria-describedby="password2-modern-vertical"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.miscSetting.svarnish}
                  name="miscSetting.svarnish"
                  className={`${!editMode ? "hide-input" : "form-control"}`}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="col-2">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Gros Polissage
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="number"
                  id="confirm-password-modern-vertical"
                  placeholder="Coût"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.miscSetting.lvarnish}
                  name="miscSetting.lvarnish"
                  className={`${!editMode ? "hide-input" : "form-control"}`}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="col-2">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Réparation Jantes
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="number"
                  id="confirm-password-modern-vertical"
                  placeholder="Coût"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.miscSetting.redowheel}
                  name="miscSetting.redowheel"
                  className={`${!editMode ? "hide-input" : "form-control"}`}
                  disabled={!editMode}
                />
              </div>
            </div>
          </div>
          <hr />
          {formik.errors && (
            <div className="text-danger">
              {Object.values(formik.errors).map((error, index) => (
                <small key={index}>{error}, </small>
              ))}
            </div>
          )}
          <table>
            <thead>
              <tr>
                <td width="4%" align="center">
                  <label className="form-label">Catégorie</label>
                </td>
                <td width="10%" align="center">
                  <label className="form-label">Cout KM supp</label>
                </td>
                <td width="10%" align="center">
                  <label className="form-label">
                    Franchise vol ou perte totale
                  </label>
                </td>
                <td width="10%" align="center">
                  <label className="form-label">Caution</label>
                </td>
                <td width="15%" align="center">
                  <label className="form-label">Coût netoyage extérieur</label>
                </td>
                <td width="15%" align="center">
                  <label className="form-label">Coût netoyage intérieur</label>
                </td>
                <td width="15%" align="center">
                  <label className="form-label">
                    Coût d'immobilisation véhicule
                  </label>
                </td>
              </tr>
            </thead>
            <tbody>
              {formik?.values?.cats.map((cat) => (
                <tr>
                  <td align="center">
                    <div className="d-flex gap-2 align-items-center justify-content-center">
                      {editMode && (
                        <ButtonBase
                          sx={{
                            background: "#4B465C",
                            color: "#FFF",
                            height: 15,
                            width: 18,
                            textAlign: "center",
                            borderRadius: "6px",
                          }}
                          onClick={() => {
                            setCats((prev) => [
                              ...prev.filter((r) => r !== cat),
                            ]);
                          }}
                        >
                          -
                        </ButtonBase>
                      )}
                      {cat}
                    </div>
                  </td>
                  <td align="center">
                    <input
                      type="number"
                      id="password-modern-vertical"
                      className={`text-center ${
                        !editMode ? "hide-input" : "form-control"
                      }`}
                      disabled={!editMode}
                      placeholder="Coût"
                      aria-describedby="password2-modern-vertical"
                      name={`cost_per_adk[category${cat}]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cost_per_adk[`category${cat}`]}
                    />
                  </td>
                  <td align="center">
                    <input
                      type="number"
                      id="password-modern-vertical"
                      className={`text-center ${
                        !editMode ? "hide-input" : "form-control"
                      }`}
                      disabled={!editMode}
                      placeholder="Coût"
                      aria-describedby="password2-modern-vertical"
                      name={`total_loss_theft_access[category${cat}]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.total_loss_theft_access[`category${cat}`]
                      }
                    />
                  </td>
                  <td align="center">
                    <input
                      type="number"
                      id="password-modern-vertical"
                      className={`text-center ${
                        !editMode ? "hide-input" : "form-control"
                      }`}
                      disabled={!editMode}
                      placeholder="Coût"
                      aria-describedby="password2-modern-vertical"
                      name={`bail[category${cat}]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.bail[`category${cat}`]}
                    />
                  </td>
                  <td align="center">
                    <input
                      type="number"
                      id="password-modern-vertical"
                      className={`text-center ${
                        !editMode ? "hide-input" : "form-control"
                      }`}
                      disabled={!editMode}
                      placeholder="Coût"
                      aria-describedby="password2-modern-vertical"
                      name={`exte_cleaning[category${cat}]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.exte_cleaning[`category${cat}`]}
                    />
                  </td>
                  <td align="center">
                    <input
                      type="number"
                      id="password-modern-vertical"
                      className={`text-center ${
                        !editMode ? "hide-input" : "form-control"
                      }`}
                      disabled={!editMode}
                      placeholder="Coût"
                      aria-describedby="password2-modern-vertical"
                      name={`inte_cleaning[category${cat}]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.inte_cleaning[`category${cat}`]}
                    />
                  </td>
                  <td align="center">
                    <input
                      type="number"
                      id="password-modern-vertical"
                      className={`text-center ${
                        !editMode ? "hide-input" : "form-control"
                      }`}
                      disabled={!editMode}
                      placeholder="Coût"
                      aria-describedby="password2-modern-vertical"
                      name={`vehicle_immobilization_cost[category${cat}]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.vehicle_immobilization_cost[
                          `category${cat}`
                        ]
                      }
                    />
                  </td>
                </tr>
              ))}

              {/* {editMode && (
                <tr>
                  <td className="d-flex gap-2 align-items-center justify-content-end">
                    <ButtonBase
                      sx={{
                        background: "#0077E5",
                        color: "#FFF",
                        height: 26,
                        width: 34,
                        textAlign: "center",
                        borderRadius: "6px",
                        fontSize: 15,
                      }}
                    >
                      +
                    </ButtonBase>
                  </td>
                </tr>
              )} */}
            </tbody>
          </table>
        </div>
      )}
      formik={formik}
      closeEdit={loading}
    />
  );
};

export default AbonementPlus;
