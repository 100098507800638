import React, { useState } from "react";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { deleteClient } from "../../../services/client/addclientApi";
import { fetchAllUserList } from "../../../Redux/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Common/DeleteModal";

const DriverNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.userSlice);

  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);

  const handleDelete = async () => {
    const data = await deleteClient(id);
    if (data?.status) {
      dispatch(fetchAllUserList());
      setModalShow(false);
      navigate("/driver");
    }
  };
  return (
    <div className="col-md">
      <div className="d-flex justify-content-between mb-4">
        <ul className="nav nav-pills flex-column flex-sm-row">
          {permissions.includes("ABONNES.PROFILE") && (
            <li
              className={`${
                location.pathname === "/driver/profile" ? "active" : ""
              }`}
            >
              <NavLink className="nav-link" to={`profile/${id}`}>
                <i className="ti-xs ti ti-user-check me-1" />
                <span className="text-c-white">Profile</span>
              </NavLink>
            </li>
          )}

          {permissions.includes("ABONNES.DOCUMENTS") && (
            <li
              className={`${
                location.pathname === "/driver/document" ? "active" : ""
              }`}
            >
              <NavLink className="nav-link" to={`document/${id}`}>
                <i className="ti-xs ti ti-files me-1 text-c-white" />
                <span className="text-c-white">Documents</span>
              </NavLink>
            </li>
          )}

          {permissions.includes("ABONNES.INVOICES") && (
            <li
              className={`${
                location.pathname === "/driver/invice" ? "active" : ""
              }`}
            >
              <NavLink className="nav-link" to={`invice/${id}`}>
                <i className="ti-xs ti ti-file-euro me-1 text-c-white" />
                <span className="text-c-white">Invoices</span>
              </NavLink>
            </li>
          )}
          {permissions.includes("ABONNES.DAMMAGES") && (
            <li
              className={`nav-item ${location.pathname === "" ? "active" : ""}`}
            >
              <NavLink className="nav-link" is="dmx-link">
                <i className="ti ti-tool me-1 text-c-white" />
                <span className="text-c-white">Dammages</span>
              </NavLink>
            </li>
          )}
        </ul>
        {permissions.includes("ABONNES.DELETE") && (
          <ul
            className="nav nav-pills flex-column flex-sm-row "
            onClick={() => setModalShow(true)}
          >
            <li className="">
              <div className="btn btn-label-danger waves-effect">
                <span className="text-c-white">Delete</span>
              </div>
            </li>
          </ul>
        )}
      </div>
      <Outlet />

      {/* modal start */}
      <DeleteModal
        title={"Supprimer Cette véhicule"}
        description={
          "Etes-vous sur de vouloir supprimer ce client et tout les conducteurs liés?"
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
        deleteData={() => handleDelete(false)}
      />
      {/* modal end */}
    </div>
  );
};

export default DriverNavbar;
