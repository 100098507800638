/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Common/Loader";
import EditChecks from "./EditChecks";
import { fetchCheck, fetchCheckSingle } from "../../Redux/checkSlice";
import { Row, Col, Button } from "react-bootstrap";
import ImageAnnotation from "./ImageEdit";
import ImageUpload from "./ImageUpload";
import { useNavigate, useParams } from "react-router-dom";

import { BsFillQuestionCircleFill } from "react-icons/bs";
import { BsCheckCircleFill, BsFillExclamationCircleFill } from "react-icons/bs";
import { getSingleCheckData } from "../../services/check";

const CheackOut = () => {
  const [showModal, setShowModal] = useState(false);
  const [isId, setId] = useState("");
  const dispatch = useDispatch();
  // const { checkListSingle, loading } = useSelector((state) => state?.checkSlice);
  let imgObject = {
    topImg:
      "https://as2.ftcdn.net/v2/jpg/05/27/88/45/1000_F_527884528_s1NzAVCYsLnipToS4In7QCFrfbIE8SZm.jpg",
    backImg:
      "https://cdni.autocarindia.com/utils/imageresizer.ashx?n=https://cms.haymarketindia.net/model/uploads/modelimages/HuracanModelImage.jpg&w=350&h=251&q=91&c=1",
    frontImg:
      "https://thumbs.dreamstime.com/z/sketch-luxury-sports-car-seen-front-simple-clean-vector-sketch-luxury-sports-car-viewed-front-220797869.jpg",
    leftSideImg:
      "https://www.shutterstock.com/image-illustration/coupe-car-sketch-side-view-260nw-2106387539.jpg",
    rightSideImg:
      "https://www.shutterstock.com/image-illustration/coupe-car-sketch-side-view-260nw-2106387539.jpg",
  };
  const [url, setUrl] = useState(imgObject.topImg);
  const { id, generalId } = useParams();
  const [showDamageReport, setShowDamageReport] = useState(false);
  const navigate = useNavigate();
  let baseImgPath = process.env.REACT_APP_IMG_URL;
  const [checkListSingle, setCheckListSingle] = useState(null);
  let [resvStatus, setResvStatus] = useState(null);

  useEffect(() => {
    const getCheckData = async () => {
      let payload = {
        reservationId: id,
        generalId: generalId,
        is_latest_yn: true,
      };
      let response = await getSingleCheckData(payload);
      setCheckListSingle(response ? response : null);
      setResvStatus(
        response && response.reservation_status
          ? response.reservation_status
          : null
      );
    };
    getCheckData();
  }, [generalId]);

  const handlebtnclick = () => {
    navigate("/checks/check-final/" + id + "/" + generalId + "/" + resvStatus);
  };

  const getImagebyType = (type) => {
    if (!checkListSingle || !checkListSingle.checkImages) return null;
    const checkImageData = checkListSingle.checkImages.length
      ? checkListSingle.checkImages
      : [];
    const imgData = checkImageData.find((res) => res.image_type === type);
    return imgData?.images?.[0]?.image_path
      ? `${baseImgPath}/${imgData.images[0].image_path}`
      : null;
  };

  const isImageRenewed = (type) => {
    if (
      !checkListSingle ||
      (checkListSingle &&
        checkListSingle.checkImages &&
        checkListSingle.checkImages.length == 0)
    )
      return "qus";

    const imgData = checkListSingle.checkImages.find(
      (item) => item.image_type === type
    );

    if (!imgData || !imgData.images || !imgData.images.length) return "qus";

    const image = imgData.images[0];

    if (image) {
      if (!image.annotations || image.annotations.length === 0) return "green";
      const isDamaged = image.annotations.some(
        (annotation) => annotation.is_damaged_yn
      );
      if (isDamaged) return "orange";
      return "green";
    } else {
      if (!image.annotations || image.annotations.length === 0) return "qus";
      const isDamaged = image.annotations.some(
        (annotation) => annotation.is_damaged_yn
      );
      if (isDamaged) return "orange";
      return "qus";
    }
  };

  const getAdditionalImgBatch = (images) => {
    const image = images;

    if (image) {
      if (!image.annotations || image.annotations.length === 0) return "green";
      const isDamaged = image.annotations.some(
        (annotation) => annotation.is_damaged_yn
      );
      if (isDamaged) return "orange";
      return "green";
    } else {
      if (!image.annotations || image.annotations.length === 0) return "qus";
      const isDamaged = image.annotations.some(
        (annotation) => annotation.is_damaged_yn
      );
      if (isDamaged) return "orange";
      return "qus";
    }
  };

  const additionalImages =
    checkListSingle?.checkImages?.filter((item) =>
      item.image_type.includes("additional")
    ) || [];

  const renderAdditionalImages = () => {
    const rows = [];
    const fixedBlocks = [{ image_type: "add" }];

    const allImages = [...fixedBlocks, ...additionalImages];
    for (let i = 0; i < allImages.length; i += 4) {
      const rowItems = allImages.slice(i, i + 4);
      rows.push(
        <Row key={i}>
          {rowItems.map((item, index) => (
            <Col
              key={index}
              md={3}
              sm={3}
              xs={3}
              className="border p-0 d-flex align-items-center justify-content-center icon-container"
              onClick={() => {
                navigate(
                  `/checks/annotate/${id}/${generalId}/11/${item.image_type}/from-check`
                );
              }}
            >
              {item.image_type === "add" ? (
                <span>+</span>
              ) : (
                item &&
                item.images &&
                item.images.length &&
                item.images[0].image_path && (
                  <img
                    src={`${baseImgPath}/${item.images[0].image_path}`}
                    alt={item.image_type}
                  />
                )
              )}
              {item &&
                item.images &&
                item.images.length &&
                item.images[0].image_path &&
                getAdditionalImgBatch(item.images[0]) === "qus" && (
                  <BsFillQuestionCircleFill className="info-icon" />
                )}
              {item &&
                item.images &&
                item.images.length &&
                item.images[0].image_path &&
                getAdditionalImgBatch(item.images[0]) === "green" && (
                  <BsCheckCircleFill className="check-circle-icon" />
                )}
              {item &&
                item.images &&
                item.images.length &&
                item.images[0].image_path &&
                getAdditionalImgBatch(item.images[0]) === "orange" && (
                  <BsFillExclamationCircleFill className="exclamatory-icon" />
                )}
            </Col>
          ))}
        </Row>
      );
    }
    return rows;
  };

  return (
    <div className="mt-4">
      <Row>
        <Col className="bg-primary text-white text-center py-2" md={12}>
          {checkListSingle && checkListSingle.reservation_status
            ? checkListSingle.reservation_status === "CHECKOUT"
              ? "Check-Out"
              : "Check-In"
            : ""}{" "}
          -{checkListSingle && checkListSingle.manufacturer_name}{" "}
          {checkListSingle && checkListSingle.model_name}{" "}
          {checkListSingle && checkListSingle.license_plate}
        </Col>
      </Row>
      <Row>
        <Col
          md={3}
          sm={3}
          xs={3}
          className="border p-0 d-flex align-items-center justify-content-center icon-container"
          onClick={() => {
            navigate(
              `/checks/annotate/${id}/${generalId}/1/face-avant/from-check`
            );
            setUrl(imgObject.frontImg);
          }}
        >
          {checkListSingle &&
          checkListSingle.checkImages &&
          getImagebyType("Face Avant") ? (
            <img src={getImagebyType("Face Avant")} alt="Face Avant"></img>
          ) : (
            <span>Face Avant</span>
          )}
          {(!checkListSingle ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length === 0) ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length &&
              isImageRenewed("Face Avant") === "qus")) && (
            <BsFillQuestionCircleFill className="info-icon" />
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Face Avant") === "green" ? (
            <BsCheckCircleFill className="check-circle-icon" />
          ) : (
            <></>
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Face Avant") === "orange" ? (
            <BsFillExclamationCircleFill className="exclamatory-icon" />
          ) : (
            <></>
          )}
        </Col>
        <Col
          md={3}
          sm={3}
          xs={3}
          className="border p-0 d-flex align-items-center justify-content-center icon-container text-wrap"
          onClick={() => {
            navigate(
              `/checks/annotate/${id}/${generalId}/2/face-arriere/from-check`
            );
            setUrl(imgObject.backImg);
          }}
        >
          {checkListSingle &&
          checkListSingle.checkImages &&
          getImagebyType("Face Arriere") ? (
            <img src={getImagebyType("Face Arriere")} alt="Face Arrière"></img>
          ) : (
            <span>Face Arrière</span>
          )}
          {(!checkListSingle ||
            (checkListSingle.checkImages &&
              checkListSingle.checkImages.length === 0) ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length &&
              isImageRenewed("Face Arriere") === "qus")) && (
            <BsFillQuestionCircleFill className="info-icon" />
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Face Arriere") === "green" ? (
            <BsCheckCircleFill className="check-circle-icon" />
          ) : (
            <></>
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Face Arriere") === "orange" ? (
            <BsFillExclamationCircleFill className="exclamatory-icon" />
          ) : (
            <></>
          )}
        </Col>
        <Col
          md={3}
          sm={3}
          xs={3}
          className="border p-0 d-flex align-items-center justify-content-center icon-container"
          onClick={() => {
            navigate(
              `/checks/annotate/${id}/${generalId}/3/profil-conducteur/from-check`
            );
          }}
        >
          {checkListSingle &&
          checkListSingle.checkImages &&
          getImagebyType("Profil Conducteur") ? (
            <img
              src={getImagebyType("Profil Conducteur")}
              alt="Profil Conducteur"
            ></img>
          ) : (
            <span>Profil Conducteur</span>
          )}
          {(!checkListSingle ||
            (checkListSingle.checkImages &&
              checkListSingle.checkImages.length === 0) ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length &&
              isImageRenewed("Profil Conducteur") === "qus")) && (
            <BsFillQuestionCircleFill className="info-icon" />
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Profil Conducteur") === "green" ? (
            <BsCheckCircleFill className="check-circle-icon" />
          ) : (
            <></>
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Profil Conducteur") === "orange" ? (
            <BsFillExclamationCircleFill className="exclamatory-icon" />
          ) : (
            <></>
          )}
        </Col>
        <Col
          md={3}
          sm={3}
          xs={3}
          className="border p-0 d-flex align-items-center justify-content-center icon-container"
          onClick={() => {
            navigate(
              `/checks/annotate/${id}/${generalId}/4/profil-passanger/from-check`
            );
          }}
        >
          {checkListSingle &&
          checkListSingle.checkImages &&
          getImagebyType("Profil Passager") ? (
            <img
              src={getImagebyType("Profil Passager")}
              alt="Profil Passager"
            ></img>
          ) : (
            <span>Profil Passager</span>
          )}
          {(!checkListSingle ||
            (checkListSingle.checkImages &&
              checkListSingle.checkImages.length === 0) ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length &&
              isImageRenewed("Profil Passager") === "qus")) && (
            <BsFillQuestionCircleFill className="info-icon" />
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Profil Passager") === "green" ? (
            <BsCheckCircleFill className="check-circle-icon" />
          ) : (
            <></>
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Profil Passager") === "orange" ? (
            <BsFillExclamationCircleFill className="exclamatory-icon" />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row>
        <Col
          md={3}
          sm={3}
          xs={3}
          className="border p-0 d-flex align-items-center justify-content-center icon-container"
          onClick={() => {
            navigate(
              `/checks/annotate/${id}/${generalId}/5/roue-avg/from-check`
            );
          }}
        >
          {checkListSingle &&
          checkListSingle.checkImages &&
          getImagebyType("Roue AV.G") ? (
            <img src={getImagebyType("Roue AV.G")} alt="Roue AV.G"></img>
          ) : (
            <span>Roue AV.G</span>
          )}
          {(!checkListSingle ||
            (checkListSingle.checkImages &&
              checkListSingle.checkImages.length === 0) ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length &&
              isImageRenewed("Roue AV.G") === "qus")) && (
            <BsFillQuestionCircleFill className="info-icon" />
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Roue AV.G") === "green" ? (
            <BsCheckCircleFill className="check-circle-icon" />
          ) : (
            <></>
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Roue AV.G") === "orange" ? (
            <BsFillExclamationCircleFill className="exclamatory-icon" />
          ) : (
            <></>
          )}
        </Col>
        <Col
          md={3}
          sm={3}
          xs={3}
          className="border p-0 d-flex align-items-center justify-content-center icon-container"
          onClick={() => {
            navigate(
              `/checks/annotate/${id}/${generalId}/6/roue-avd/from-check`
            );
          }}
        >
          {checkListSingle &&
          checkListSingle.checkImages &&
          getImagebyType("Roue AV.D") ? (
            <img src={getImagebyType("Roue AV.D")} alt="Roue AV.D"></img>
          ) : (
            <span>Roue AV.D</span>
          )}
          {(!checkListSingle ||
            (checkListSingle.checkImages &&
              checkListSingle.checkImages.length === 0) ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length &&
              isImageRenewed("Roue AV.D") === "qus")) && (
            <BsFillQuestionCircleFill className="info-icon" />
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Roue AV.D") === "green" ? (
            <BsCheckCircleFill className="check-circle-icon" />
          ) : (
            <></>
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Roue AV.D") === "orange" ? (
            <BsFillExclamationCircleFill className="exclamatory-icon" />
          ) : (
            <></>
          )}
        </Col>
        <Col
          md={3}
          sm={3}
          xs={3}
          className="border p-0 d-flex align-items-center justify-content-center icon-container"
          onClick={() => {
            navigate(
              `/checks/annotate/${id}/${generalId}/7/roue-ard/from-check`
            );
          }}
        >
          {checkListSingle &&
          checkListSingle.checkImages &&
          getImagebyType("Roue AR.D") ? (
            <img src={getImagebyType("Roue AR.D")} alt="Roue AR.D"></img>
          ) : (
            <span>Roue AR.D</span>
          )}
          {(!checkListSingle ||
            (checkListSingle.checkImages &&
              checkListSingle.checkImages.length === 0) ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length &&
              isImageRenewed("Roue AR.D") === "qus")) && (
            <BsFillQuestionCircleFill className="info-icon" />
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Roue AR.D") === "green" ? (
            <BsCheckCircleFill className="check-circle-icon" />
          ) : (
            <></>
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Roue AR.D") === "orange" ? (
            <BsFillExclamationCircleFill className="exclamatory-icon" />
          ) : (
            <></>
          )}
        </Col>
        <Col
          md={3}
          sm={3}
          xs={3}
          className="border p-0 d-flex align-items-center justify-content-center icon-container"
          onClick={() => {
            navigate(
              `/checks/annotate/${id}/${generalId}/8/roue-arg/from-check`
            );
          }}
        >
          {checkListSingle &&
          checkListSingle.checkImages &&
          getImagebyType("Roue AR.G") ? (
            <img src={getImagebyType("Roue AR.G")} alt="Roue AR.G"></img>
          ) : (
            <span>Roue AR.G</span>
          )}
          {(!checkListSingle ||
            (checkListSingle.checkImages &&
              checkListSingle.checkImages.length === 0) ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length &&
              isImageRenewed("Roue AR.G") === "qus")) && (
            <BsFillQuestionCircleFill className="info-icon" />
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Roue AR.G") === "green" ? (
            <BsCheckCircleFill className="check-circle-icon" />
          ) : (
            <></>
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Roue AR.G") === "orange" ? (
            <BsFillExclamationCircleFill className="exclamatory-icon" />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row>
        <Col
          md={3}
          sm={3}
          xs={3}
          className="border p-0 d-flex align-items-center justify-content-center icon-container"
          onClick={() => {
            navigate(
              `/checks/annotate/${id}/${generalId}/9/tableau-de-bord/from-check`
            );
          }}
        >
          {checkListSingle &&
          checkListSingle.checkImages &&
          getImagebyType("Tableau de bord") ? (
            <img
              src={getImagebyType("Tableau de bord")}
              alt="Tableau de bord"
            ></img>
          ) : (
            <span>Tableau de bord</span>
          )}
          {(!checkListSingle ||
            (checkListSingle.checkImages &&
              checkListSingle.checkImages.length === 0) ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length &&
              isImageRenewed("Tableau de bord") === "qus")) && (
            <BsFillQuestionCircleFill className="info-icon" />
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Tableau de bord") === "green" ? (
            <BsCheckCircleFill className="check-circle-icon" />
          ) : (
            <></>
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Tableau de bord") === "orange" ? (
            <BsFillExclamationCircleFill className="exclamatory-icon" />
          ) : (
            <></>
          )}
        </Col>
        <Col
          md={3}
          sm={3}
          xs={3}
          className="border p-0 d-flex align-items-center justify-content-center icon-container"
          onClick={() => {
            navigate(
              `/checks/annotate/${id}/${generalId}/10/interieur/from-check`
            );
          }}
        >
          {checkListSingle &&
          checkListSingle.checkImages &&
          getImagebyType("Interieur") ? (
            <img src={getImagebyType("Interieur")} alt="Interieur"></img>
          ) : (
            <span>Interieur</span>
          )}
          {(!checkListSingle ||
            (checkListSingle.checkImages &&
              checkListSingle.checkImages.length === 0) ||
            (checkListSingle &&
              checkListSingle.checkImages &&
              checkListSingle.checkImages.length &&
              isImageRenewed("Interieur") === "qus")) && (
            <BsFillQuestionCircleFill className="info-icon" />
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Interieur") === "green" ? (
            <BsCheckCircleFill className="check-circle-icon" />
          ) : (
            <></>
          )}
          {checkListSingle &&
          checkListSingle.checkImages &&
          checkListSingle.checkImages.length &&
          isImageRenewed("Interieur") === "orange" ? (
            <BsFillExclamationCircleFill className="exclamatory-icon" />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12} className="bg-primary text-white text-center py-2">
          PHOTO SUPPLÉMENTAIRES
        </Col>
      </Row>
      {/* <Row>
        <Col md={3} className="border p-3 d-flex align-items-center justify-content-center icon-container">+</Col>
        <Col md={3} className="border p-3 d-flex align-items-center justify-content-center icon-container">Face Arrière</Col>
        <Col md={3} className="border p-3 icon-container"></Col>
        <Col md={3} className="border p-3 icon-container"></Col>

      </Row> */}
      {renderAdditionalImages()}

      <Row className="mt-4">
        <Col md={12} className="text-center">
          <Button
            variant="secondary"
            className="bg-primary w-100"
            onClick={handlebtnclick}
          >
            Valider
          </Button>
        </Col>
      </Row>

      {showDamageReport && (
        <>
          <ImageUpload />
        </>
      )}
      {url && (
        <ImageAnnotation
          url={url}
          showModal={showModal}
          setShowModal={setShowModal}
          setShowDamageForm={setShowDamageReport}
        />
      )}
    </div>
  );
};

export default CheackOut;
