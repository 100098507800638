import { useState } from "react";
import { ReactComponent as DropArrow } from "../../../../../../assets/icons/down-drop.svg";
import { BsPlus, BsXLg } from "react-icons/bs";
import { Autocomplete, Box, InputBase, Paper } from "@mui/material";
import { Dropdown } from "primereact/dropdown";

const Suggestions = ({ formik, isEdit, vehicles }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [selectedCars, setSelectedCars] = useState([]);
  const suggestions = formik?.values?.vehicleSelection || [];
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const toggleInput = () => setShowInput(!showInput);

  return (
    <div>
      <div
        className="d-flex gap-1 align-items-center mb-2"
        role="button"
        onClick={toggleCollapse}
      >
        <div className="drive-section-head ">Suggestions</div>
        <DropArrow
          height={18}
          style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        />
      </div>
      {isOpen && (
        <div className="collapsible-content">
          <div className="d-flex gap-1 flex-wrap">
            {isEdit && (
              <div className="drive-values-item no-border">
                {suggestions.map((s) => s.title).join(", ")}
              </div>
            )}
            {!isEdit &&
              suggestions.map((badge, index) => (
                <div className="drive-values-item gap-2">
                  {badge.title}{" "}
                  <Box display="flex" gap={0.5} alignItems="center">
                    {badge?.colors?.length > 0 && (
                      <Dropdown
                        options={badge?.colors}
                        optionLabel="color"
                        optionValue="color"
                        value={suggestions[index]?.color || null} // Show selected color if available
                        onChange={(e) => {
                          // Update the color in the selected vehicle
                          const updatedSelection = [...suggestions];
                          updatedSelection[index] = {
                            ...updatedSelection[index],
                            color: e.value, // Ensure the color is properly updated
                          };
                          formik.setFieldValue("vehicleSelection", [
                            ...updatedSelection,
                          ]);
                        }}
                        style={{
                          height: 30,
                          maxWidth: "150px",
                          padding: 0,
                        }}
                      />
                    )}
                  </Box>
                  {!isEdit && (
                    <BsXLg
                      role="button"
                      onClick={() =>
                        formik.setFieldValue("vehicleSelection", [
                          ...suggestions.filter((b) => b !== badge),
                        ])
                      }
                    />
                  )}
                </div>
              ))}

            {!isEdit && (
              <div className="d-flex gap-1">
                {showInput && (
                  <Autocomplete
                    multiple
                    id="vehicle-selection-filled"
                    disabled={isEdit}
                    options={vehicles} // Use grouped vehicles as options
                    getOptionLabel={(option) => option?.title?.toString()}
                    onChange={(e, newVal) => {
                      formik.setFieldValue("vehicleSelection", [
                        ...suggestions,
                        ...newVal,
                      ]);
                      setSelectedCars([]);
                      toggleInput();
                    }} // Update vehicle selection in Formik
                    value={selectedCars} // Use Formik's value for vehicle selection
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        paddingRight: "0px !important", // Removes right-side padding
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        display: "none", // Hides the dropdown icon
                      },
                      "& .MuiChip-label": {
                        padding: 0,
                      },
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key } = getTagProps({ index });
                        return (
                          <Box
                            variant="outlined"
                            label={option.title}
                            key={key || option.title}
                            sx={{
                              color: "#000000",
                              borderRadius: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              border: "none",
                              fontSize: 12,
                              textAlign: "left",
                              py: 0.5,
                              gap: 0.5,
                              flexWrap: "no-wrap",
                            }}
                          >
                            {option.title}
                          </Box>
                        );
                      })
                    }
                    clearIcon={false}
                    popupIcon={null}
                    renderInput={(params) => (
                      <Paper component="div" elevation={0}>
                        <InputBase
                          {...params.InputProps}
                          inputProps={params.inputProps}
                          style={{ flex: 1, p: 0 }}
                          placeholder="+ Ajouter un vehicule"
                          className="drive-values-item"
                          size="small"
                        />
                      </Paper>
                    )}
                  />
                )}
                <div
                  className="drive-values-item"
                  role="button"
                  onClick={toggleInput}
                >
                  {showInput ? (
                    <BsXLg style={{ fontSize: "15px" }} />
                  ) : (
                    <BsPlus style={{ fontSize: "15px" }} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Suggestions;
