import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createColor,
  deleteColorById,
  getColors,
  updateColorById,
} from "../services/visibilitee";

export const addColorAction = createAsyncThunk(
  "colors/add",
  async ({ data, callBack }, { dispatch }) => {
    try {
      await createColor(data);
      callBack();
      dispatch(fetchColors());
    } catch {}
  }
);

export const fetchColors = createAsyncThunk("colors/colors", async () => {
  try {
    const res = await getColors();
    return res.data;
  } catch {}
});

export const deleteColor = createAsyncThunk(
  "colors/delete",
  async ({ data, callBack }, { dispatch }) => {
    try {
      await deleteColorById(data);
      callBack();
      dispatch(fetchColors());
    } catch {}
  }
);

export const updateColor = createAsyncThunk(
  "colors/updateColor",
  async ({ data, callBack }, { dispatch }) => {
    try {
      await updateColorById(data);
      callBack();
      dispatch(fetchColors());
    } catch {}
  }
);

const colorSlice = createSlice({
  name: "colorSlice",
  initialState: { colors: [], masterList: [] },
  reducers: {
    searchColors: (state, { payload }) => {
      state.colors = state.masterList.filter((r) => r.name.includes(payload));
    },
  },
  extraReducers: (builder) =>
    builder.addCase(fetchColors.fulfilled, (state, { payload }) => {
      state.colors = payload;
      state.masterList = payload;
    }),
});

export const { searchColors } = colorSlice.actions;
export default colorSlice.reducer;
