/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import { Dropdown } from "primereact/dropdown";
import DeleteModal from "../components/Common/DeleteModal";
import {
  changeSearchText,
  fetchManufacturerDropdown,
  fetchVehicleList,
  searchVehicleData,
  setFleetCurrentPage,
  sortingAtoZ,
  sortingZtoA,
} from "../Redux/vehicleSlice";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  deleteDocumentGerenalId,
  deleteVehiclet,
} from "../services/AddVehicle";
import { Status, locationOptions } from "../components/Common/DropdownValue";
import { Loader } from "../components/Common/Loader";
import {
  BsCheck,
  BsChevronDown,
  BsChevronLeft,
  BsChevronRight,
  BsChevronUp,
  BsFunnel,
  BsTrash,
  BsX,
  BsXLg,
} from "react-icons/bs";
import { pagination } from "../components/Common/Pagination";
import TableFooter from "../components/Common/TableFooter";
import activeImage from "../assets/img/icons/fleet/Green img.svg"; // Adjust the path as necessary
import inactiveImage from "../assets/img/icons/fleet/Orange Img.svg"; // Adjust the path as necessary

import { ReactComponent as PlusIcon } from "../assets/img/icons/fleet/plus.svg";
import { ReactComponent as FilterIcon } from "../assets/img/icons/fleet/filter.svg";
import { ReactComponent as SettingIcon } from "../assets/img/icons/fleet/settings.svg";
import { ReactComponent as DotsVertIcon } from "../assets/img/icons/fleet/dots-vertical.svg";
import DaterangePicker from "../components/Prospect/DateRange";

const Fleet = () => {
  const { permissions } = useSelector((state) => state.userSlice);
  const [modalShow, setModalShow] = useState(false);
  const [isStatus, setStatus] = useState("");
  const [istId, setId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemsPerPage, setitemsPerPage] = useState("25");
  const [isPageNumberArray, setPageNumberArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { manufacturersDropdown } = useSelector((state) => state?.vehicleSlice);
  const {
    vehicleList,
    vehicleSearchList,
    searchText,
    loading,
    currentFleetPage,
  } = useSelector((state) => state.vehicleSlice);
  const [selectAll, setSelectAll] = useState(false);
  const [filterKey, setFilterKey] = useState("");
  const filters = [
    { key: "company", label: "Marques" },
    { key: "status", label: "Status" },
    { key: "vehicle_location", label: "Localisation" },
    { key: "active", label: "Activité" },
    { key: "date", label: "Disponibilité" },
  ];

  const [initialValue, setInitialValue] = useState({
    search: "",
    company: "",
    status: "",
    owner: "",
    vehicle_location: "",
    date: "",
  });
  const [showFilters, setShowFilters] = useState(false);
  const [filterOpts, setFilterOpts] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [reload, setReload] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentPage(0);
    setInitialValue((prev) => {
      const updatedFilters = { ...prev, [name]: value };
      return updatedFilters;
    });
    toggleFilters();
  };

  const editFleet = (id) => {
    navigate(`/fleet/general/${id}`);
  };

  const deleteData = async (id) => {
    await deleteVehiclet(id || istId);
    await deleteDocumentGerenalId(id || istId);
    setModalShow(false);
    dispatch(fetchVehicleList());
  };

  const aTozSorting = (value) => {
    dispatch(sortingAtoZ(value));
  };

  const zToaSorting = (value) => {
    dispatch(sortingZtoA(value));
  };
  // pagination start

  const vehicleListData = pagination(
    vehicleSearchList,
    currentPage,
    itemsPerPage
  );

  const handlePageChange = ({ selected }) => {
    selected !== 0 && dispatch(setFleetCurrentPage(selected));
    setCurrentPage(selected);
  };

  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };

  useEffect(() => {
    setPageNumberArray(vehicleSearchList);
  }, [vehicleSearchList]);
  // pagination end

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(changeSearchText(initialValue));
      dispatch(searchVehicleData(initialValue));
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [
    initialValue.search,
    initialValue.vehicle_location,
    initialValue.status,
    initialValue.company,
    initialValue.active,
    reload,
  ]);

  const handleActive = () => {
    setCurrentPage(currentFleetPage);
  };

  useEffect(() => {
    handleActive();
    vehicleList?.length === 0 && dispatch(fetchVehicleList());
    dispatch(fetchManufacturerDropdown());
  }, []);

  useEffect(() => {
    setInitialValue((prev) => ({
      ...prev,
      search: searchText.search,
      company: searchText.company,
      status: searchText.status,
      vehicle_location: searchText.vehicle_location,
      active: searchText.active,
    }));
  }, [searchText]);

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
    setFilterKey("");
    setFilterOpts([]);
  };

  const toggleFilterOpts = (key) => {
    const opts = {
      company: manufacturersDropdown,
      status: Status,
      vehicle_location: locationOptions,
      active: [
        { key: "active", label: "Active" },
        { key: "inactive", label: "Command" },
      ],
      date: [
        {
          key: "today",
          label: "Aujourd’hui",
        },
        {
          key: "last7days",
          label: "Les 7 derniers Jours",
        },
        {
          key: "lastmonth",
          label: "Dans le dernier mois",
        },
      ],
    };
    toggleFilters();
    setFilterKey(key);
    setFilterOpts(opts[key]);
  };

  const toggleDeleteConfirmation = () =>
    setDeleteConfirmation(!deleteConfirmation);

  const deleteSelectedRows = async () => {
    try {
      for (const row of selectedRows) {
        await deleteData(row);
      }
      setReload(!reload);
      toggleDeleteConfirmation();
      setSelectedRows([]);
    } catch {}
  };

  // When no list permission assigned
  if (!permissions.includes("FLOTTE.LIST")) return <></>;

  return (
    <div className="content-wrapper">
      <div className="container-fluid flex-grow-1 container-p-y">
        {/* page-titel start */}
        {/* <BreadCrumbs title={"Flotte"} subTitle={"Flotte"} /> */}
        {/* page-titel end */}

        {/* main containe start */}
        <div className="card" style={{ borderRadius: "0" }}>
          {/* <div className="card-header border-bottom">
            <div className="d-flex align-items-center justify-content-end gap-3">
              <div className="flex-grow-1">
                <input
                  value={initialValue?.search}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="search"
                  filter
                  type="search"
                  placeholder="Rechercher.."
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
                <Dropdown
                  value={initialValue?.company}
                  options={manufacturersDropdown}
                  optionLabel="name"
                  filter
                  name="company"
                  // showClear
                  placeholder="Marques"
                  className="w-full"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  {...(initialValue?.company && { showClear: true })}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
                <Dropdown
                  // showClear
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  options={Status}
                  name="status"
                  value={initialValue?.status}
                  placeholder="Statuts"
                  className="w-full"
                  {...(initialValue?.status && { showClear: true })}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
                <Dropdown
                  // showClear
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  options={locationOptions}
                  name="vehicle_location"
                  value={initialValue?.vehicle_location}
                  placeholder="Localisation"
                  className="w-full"
                  {...(initialValue?.vehicle_location && { showClear: true })}
                />
              </div>
              <div className="d-flex align-items-center flex-grow-0">
                <NavLink
                  to={"/add-vehicle"}
                  className="btn btn-primary waves-effect waves-light"
                  type="button"
                >
                  <i className="ti ti-plus me-0 me-sm-1 ti-xs" />
                  <span className="d-none d-sm-inline-block">Voiture</span>
                </NavLink>
              </div>
            </div>
          </div> */}
          {showFilters && (
            <div className="d-flex gap-3 p-2">
              {filters.map((filt) => (
                <button
                  className="btn fleet-new-filt"
                  onClick={() => toggleFilterOpts(filt.key)}
                >
                  {filt.label} <BsChevronRight />
                </button>
              ))}
            </div>
          )}

          {filterOpts?.length > 0 && (
            <div className="d-flex gap-3 align-items-center  p-2 ">
              <div className="d-flex gap-3 fleet-new-filt-opt-container">
                <button className="btn fleet-new-filt-opt">
                  {filters.find((r) => r.key === filterKey)?.label}
                </button>
                {filterKey === "date" && (
                  <DaterangePicker
                    className={`btn fleet-new-filt-opt ${
                      (typeof initialValue[filterKey] === "string"
                        ? initialValue[filterKey]
                        : initialValue[filterKey]?.name ||
                          initialValue[filterKey]?.label) === "custom"
                        ? `fleet-new-filt-opt-active`
                        : ``
                    }`}
                    onDateChange={(r) => {
                      if (r.start && r.end) {
                        handleChange({
                          target: {
                            name: filterKey,
                            value: `${r.start}-${r.end}`,
                          },
                        });
                      }
                    }}
                  />
                )}
                {filterOpts.map((filt) => {
                  const optVal =
                    typeof filt === "string" ? filt : filt?.name || filt?.label;
                  return (
                    <button
                      className={`btn fleet-new-filt-opt ${
                        initialValue[filterKey] === optVal
                          ? `fleet-new-filt-opt-active`
                          : ``
                      }`}
                      onClick={() =>
                        handleChange({
                          target: {
                            name: filterKey,
                            value: optVal,
                          },
                        })
                      }
                    >
                      {optVal}
                    </button>
                  );
                })}
              </div>
              <button
                className="btn fleet-new-filt-opt-clear"
                onClick={toggleFilters}
              >
                <BsChevronLeft />
              </button>
            </div>
          )}
          <div className="d-flex align-items-center white-bg-card-filt">
            <input
              value={initialValue?.search}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder="Search..."
              className="form-control me-2"
              style={{ width: "200px" }}
              type="text"
              name="search"
              filter
            />
            <NavLink to={"/add-vehicle"}>
              <button className="btn p-2 plus-ic">
                <PlusIcon />
              </button>
            </NavLink>
            <button
              className={`btn p-2 ${showFilters ? `active-action` : ``}`}
              onClick={toggleFilters}
            >
              <BsFunnel style={{ fontSize: "20px" }} />
            </button>
            <button className="btn p-2">
              <SettingIcon />
            </button>
            <button className="btn p-2">
              <DotsVertIcon />
            </button>
            <div className="d-flex gap-2">
              {Object.values(initialValue).length > 0 &&
                Object.entries(initialValue).map(
                  (k) =>
                    k[1] && (
                      <div
                        className="selected-filt"
                        role="button"
                        onClick={() =>
                          setInitialValue({ ...initialValue, [k[0]]: "" })
                        }
                      >
                        {k[1]} <BsXLg />
                      </div>
                    )
                )}

              {selectedRows.length > 0 && !deleteConfirmation && (
                <button
                  className="btn btn-primary  p-2"
                  onClick={toggleDeleteConfirmation}
                >
                  <BsTrash />
                  &nbsp;&nbsp; Supprimer
                </button>
              )}
              {selectedRows.length > 0 && deleteConfirmation && (
                <div className="d-flex gap-2  p-2">
                  <button
                    className="btn btn-danger"
                    onClick={deleteSelectedRows}
                  >
                    <BsCheck />
                    &nbsp;&nbsp; Valider
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={toggleDeleteConfirmation}
                  >
                    <BsX />
                    &nbsp;&nbsp; Annuler
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="new-table mt-3">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={(e) =>
                          setSelectedRows(
                            !e.target.checked
                              ? []
                              : vehicleListData.map((r) => r.general_id)
                          )
                        }
                        checked={selectedRows.length === vehicleListData.length}
                      />
                    </th>
                    <th className="d-flex gap-2 align-items-center">
                      <span>VOITURE</span>
                      <span className="d-flex flex-column ps-1">
                        <BsChevronUp
                          fontSize={12}
                          onClick={() => aTozSorting("status")}
                        />
                        <BsChevronDown
                          fontSize={12}
                          onClick={() => zToaSorting("status")}
                        />
                      </span>
                    </th>
                    <th>
                      <span>STATUS</span>
                    </th>

                    <th className="text-center">LOCALISATION</th>
                    <th className="text-center">INFO</th>
                    <th className="text-center">ACTIVITÉ</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicleListData.length > 0 ? (
                    vehicleListData.map((item, i) => (
                      <tr key={i}>
                        <td onClick={(e) => e.stopPropagation()}>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={selectedRows.includes(item.general_id)}
                            onChange={(e) => {
                              setSelectedRows(
                                !e.target.checked
                                  ? selectedRows.filter(
                                      (r) => r !== item.general_id
                                    )
                                  : [...selectedRows, item.general_id]
                              );
                            }}
                          />
                        </td>
                        <td
                          className="cursor-pointer font-weight-bold"
                          onClick={() => editFleet(item.general_id)}
                        >
                          <small
                            style={{
                              minWidth: "120px",
                              display: "inline-block",
                            }}
                          >
                            {item.license_plate}{" "}
                          </small>
                          &nbsp;&nbsp;
                          <strong>
                            {item.manufacturer} {item.model} {item.declination}
                          </strong>
                        </td>
                        <td>
                          <Dropdown
                            onClick={() => setId(item.general_id)}
                            value={
                              istId === item.general_id && isStatus
                                ? isStatus
                                : item.status
                            }
                            onChange={(e) => setStatus(e.target.value)}
                            options={Status}
                            filter
                            placeholder="Status"
                            className="w-full"
                          />
                        </td>
                        <td>
                          <div className="text-center">
                            <strong>{item?.company?.name}</strong>
                            {/* <div>
                              {new Date(item.date).toLocaleDateString()}{" "}
                              {new Date(item.date).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              })}
                            </div> */}
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="text-center">
                            <div>{item.km_counter} KM</div>
                            <div>
                              {new Date(item.updated_date).toLocaleDateString()}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="d-flex align-items-center justify-content-center gap-2">
                            <img
                              src={
                                item.active === "Active"
                                  ? activeImage
                                  : inactiveImage
                              }
                              alt={
                                item.active === "Active" ? "Active" : "Inactive"
                              }
                              width="25"
                            />
                            <BsTrash
                              className="cursor-pointer"
                              onClick={() => {
                                setId(item.general_id);
                                setModalShow(true);
                              }}
                              style={{
                                fontSize: "20px",
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {loading && (
            <div className="card-header my-4 mx-auto">
              <Loader />
            </div>
          )}

          {!loading && isPageNumberArray?.length === 0 && (
            <div className="text-center my-4 mx-auto">No Data Found</div>
          )}

          {vehicleListData !== false && (
            <TableFooter
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              handleSelectChange={handleSelectChange}
              listData={vehicleSearchList}
              handlePageChange={handlePageChange}
              handleActive={handleActive}
            />
          )}
        </div>

        <DeleteModal
          title={"Supprimer Cette véhicule"}
          description={"Êtes-vous sûr de supprimer le véhicule ?"}
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteData={() => deleteData()}
        />
      </div>
    </div>
  );
};

export default Fleet;

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { BsChevronDown, BsChevronUp, BsTrash } from "react-icons/bs";
// import { ReactComponent as PlusIcon } from "../assets/img/icons/fleet/plus.svg";
// import { ReactComponent as FilterIcon } from "../assets/img/icons/fleet/filter.svg";
// import { ReactComponent as SettingIcon } from "../assets/img/icons/fleet/settings.svg";
// import { ReactComponent as DotsVertIcon } from "../assets/img/icons/fleet/dots-vertical.svg";
// import DeleteModal from "../components/Common/DeleteModal";
// import TableFooter from "../components/Common/TableFooter";
// import {
//   fetchVehicleList,
//   searchVehicleData,
//   setFleetCurrentPage,
//   sortingAtoZ,
//   sortingZtoA,
// } from "../Redux/vehicleSlice";
// import {
//   deleteDocumentGerenalId,
//   deleteVehiclet,
// } from "../services/AddVehicle";
// import { Loader } from "../components/Common/Loader";

// // Import status images
// import activeImage from "../assets/img/icons/fleet/Green img.svg"; // Adjust the path as necessary
// import inactiveImage from "../assets/img/icons/fleet/Orange Img.svg"; // Adjust the path as necessary

// // Utility function for pagination
// const pagination = (data, currentPage, itemsPerPage) => {
//   const startIndex = currentPage * parseInt(itemsPerPage);
//   const endIndex = startIndex + parseInt(itemsPerPage);
//   return data.slice(startIndex, endIndex);
// };

// const Fleet = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [modalShow, setModalShow] = useState(false);
//   const [currentPage, setCurrentPage] = useState(0);
//   const [itemsPerPage, setItemsPerPage] = useState("25");
//   const [isStatus, setStatus] = useState("");
//   const [istId, setId] = useState("");
//   const [showFilter, setShowFilter] = useState(false);
//   const [selectedFilter, setSelectedFilter] = useState(null);
//   const [expandedFilters, setExpandedFilters] = useState({});
//   const [initialValue, setInitialValue] = useState({
//     search: "",
//     company: "",
//     status: "",
//     owner: "",
//     vehicle_location: "",
//   });

//   const { vehicleList, vehicleSearchList, loading, currentFleetPage } =
//     useSelector((state) => state.vehicleSlice);

//   const [searchValue, setSearchValue] = useState("");
//   const [selectAll, setSelectAll] = useState(false);

//   const handleSearchChange = (e) => {
//     setSearchValue(e.target.value);
//     setCurrentPage(0);
//     dispatch(searchVehicleData({ search: e.target.value }));
//   };

//   const handleSelectAllChange = () => {
//     setSelectAll(!selectAll);
//   };

//   const editFleet = (id) => {
//     navigate(`/fleet/general/${id}`);
//   };

//   const deleteData = async () => {
//     await deleteVehiclet(istId);
//     await deleteDocumentGerenalId(istId);
//     setModalShow(false);
//     dispatch(fetchVehicleList());
//   };

//   const aTozSorting = (value) => {
//     dispatch(sortingAtoZ(value));
//   };

//   const zToaSorting = (value) => {
//     dispatch(sortingZtoA(value));
//   };

//   const vehicleListData = pagination(
//     vehicleSearchList,
//     currentPage,
//     itemsPerPage
//   );

//   const handlePageChange = ({ selected }) => {
//     setCurrentPage(selected);
//   };

//   useEffect(() => {
//     vehicleList?.length === 0 && dispatch(fetchVehicleList());
//   }, []);

//   useEffect(() => {
//     dispatch(searchVehicleData({ search: searchValue }));
//   }, [searchValue]);

//   const toggleFilter = () => {
//     setShowFilter(!showFilter);
//     setSelectedFilter(null); // Reset selected filter when opening/closing
//   };

//   const toggleFilterOption = (filter) => {
//     setSelectedFilter(selectedFilter === filter ? null : filter);
//   };

//   const handleToggleDrawer = (filter) => {
//     setExpandedFilters((prev) => ({
//       ...prev,
//       [filter]: !prev[filter],
//     }));
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setCurrentPage(0);
//     setInitialValue((prev) => {
//       const updatedFilters = { ...prev, [name]: value };
//       return updatedFilters;
//     });
//   };

//   return (
//     <div className="content-wrapper">
//       <div className="container-fluid flex-grow-1 container-p-y">
//         <div className="white-bg-card">
//           {showFilter && (
//             <div className="d-flex gap-3 mt-3 mb-2">
//               {[
//                 "Marques",
//                 "Status",
//                 "Localisation",
//                 "Activité",
//                 "Disponibilité",
//               ].map((filter) => (
//                 <div key={filter} className="filter-option">
//                   <button
//                     className="btn btn-light"
//                     onClick={() => handleToggleDrawer(filter)}
//                   >
//                     {filter}{" "}
//                     {expandedFilters[filter] ? (
//                       <BsChevronUp />
//                     ) : (
//                       <BsChevronDown />
//                     )}
//                   </button>
//                   {expandedFilters[filter] && (
//                     <div className="dropdown-list mt-2">
//                       <ul className="d-flex gap-2">
//                         <li className="btn btn-outline-primary">
//                           Option 1 for {filter}
//                         </li>
//                         <li className="btn btn-outline-primary">
//                           Option 2 for {filter}
//                         </li>
//                         <li className="btn btn-outline-primary">
//                           Option 3 for {filter}
//                         </li>
//                       </ul>
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           )}

//           <div className="d-flex align-items-center white-bg-card-filt">
//             <input
//               value={initialValue?.search}
//               onChange={(e) => {
//                 handleChange(e);
//               }}
//               placeholder="Search..."
//               className="form-control me-2"
//               style={{ width: "200px" }}
//               type="text"
//               name="search"
//               filter
//             />
//             <button className="btn p-2">
//               <PlusIcon />
//             </button>
//             <button className="btn p-2" onClick={toggleFilter}>
//               <FilterIcon />
//             </button>
//             <button className="btn p-2">
//               <SettingIcon />
//             </button>
//             <button className="btn p-2">
//               <DotsVertIcon />
//             </button>
//           </div>

//           <div className="new-table mt-3">
//             <div className="table-responsive">
//               <table className="table table-hover">
//                 <thead>
//                   <tr>
//                     <th>
//                       <input
//                         type="checkbox"
//                         checked={selectAll}
//                         onChange={handleSelectAllChange}
//                       />
//                     </th>
//                     <th className="d-flex gap-2">
//                       <span>VOITURE</span>
//                       <span className="d-flex flex-column ps-1">
//                         <BsChevronUp
//                           fontSize={12}
//                           onClick={() => aTozSorting("status")}
//                         />
//                         <BsChevronDown
//                           fontSize={12}
//                           onClick={() => zToaSorting("status")}
//                         />
//                       </span>
//                     </th>
//                     <th>
//                       <span>STATUS</span>
//                     </th>
//                     <th>LOCALISATION</th>
//                     <th className="text-center">INFO</th>
//                     <th className="text-center">ACTIVITÉ</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {vehicleListData.length > 0 ? (
//                     vehicleListData.map((item, i) => (
//                       <tr key={i}>
//                         <td>
//                           <input
//                             type="checkbox"
//                             checked={selectAll}
//                             onChange={() => {}}
//                           />
//                         </td>
//                         <td
//                           className="cursor-pointer font-weight-bold"
//                           onClick={() => editFleet(item.general_id)}
//                         >
//                           <small>{item.license_plate} </small>
//                           <strong>
//                             {item.manufacturer} {item.model} {item.declination}
//                           </strong>
//                         </td>
//                         <td>
//                           <select
//                             value={
//                               istId === item.general_id && isStatus
//                                 ? isStatus
//                                 : item.status
//                             }
//                             onChange={(e) => setStatus(e.target.value)}
//                             className="form-select"
//                           >
//                             <option value="Active">Active</option>
//                             <option value="Inactive">Inactive</option>
//                           </select>
//                         </td>
//                         <td>
//                           <div className="text-center">
//                             <div>{item.vehicle_location}</div>
//                             <div>
//                               {new Date(item.date).toLocaleDateString()}{" "}
//                               {new Date(item.date).toLocaleTimeString([], {
//                                 hour: "2-digit",
//                                 minute: "2-digit",
//                                 hour12: true,
//                               })}
//                             </div>
//                           </div>
//                         </td>
//                         <td className="text-center">
//                           <div className="text-center">
//                             <div>{item.km_counter} KM</div>
//                             <div>
//                               {new Date(item.date)
//                                 .getHours()
//                                 .toString()
//                                 .padStart(2, "0")}
//                               :
//                               {new Date(item.date)
//                                 .getMinutes()
//                                 .toString()
//                                 .padStart(2, "0")}{" "}
//                               - {new Date(item.date).toLocaleDateString()}
//                             </div>
//                           </div>
//                         </td>
//                         <td className="text-center">
//                           <div className="d-flex align-items-center justify-content-center gap-2">
//                             <img
//                               src={
//                                 item.active === "Active"
//                                   ? activeImage
//                                   : inactiveImage
//                               }
//                               alt={
//                                 item.active === "Active" ? "Active" : "Inactive"
//                               }
//                               width="25"
//                             />
//                             <BsTrash
//                               className="cursor-pointer"
//                               onClick={() => {
//                                 setId(item.general_id);
//                                 setModalShow(true);
//                               }}
//                             />
//                           </div>
//                         </td>
//                       </tr>
//                     ))
//                   ) : (
//                     <tr>
//                       <td colSpan="6" className="text-center">
//                         No data found.
//                       </td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//             </div>
//           </div>

//           <TableFooter
//             pageCount={Math.ceil(vehicleSearchList.length / itemsPerPage)}
//             onPageChange={handlePageChange}
//             currentPage={currentPage}
//             setItemsPerPage={setItemsPerPage}
//             itemsPerPage={itemsPerPage}
//           />

//           {modalShow && (
//             <DeleteModal
//               show={modalShow}
//               onHide={() => setModalShow(false)}
//               onDelete={deleteData}
//             />
//           )}

//           {loading && <Loader />}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Fleet;
