import { createSlice, current } from "@reduxjs/toolkit";
import { companyDropdownList } from "./../services/Company/addSubscriptionApi";
import Countries from "../components/Common/Countries.json";
import {
  financetypeDropdown,
  vehicleCategoryDropdown,
} from "../services/AddVehicle";
import { searchCustomerApi } from "../services/client/addclientApi";
import { googleAutoAddress } from "../services/loginApi";

const commonSlice = createSlice({
  name: "commonSlice",
  initialState: {
    companyDropdown: [],
    vehicleCategoryDropdownList: [],
    financetypeDropdownList: [],
    countriesList: Countries.sort() || [],
    driverList: [],
    tempDriverList: [],
    clientList: [],
    tempClientList: [],
    basicList: [],
    tempBasicList: [],
    loading: "",
    googlePlaceList: [],
    searchText: {
      search: "",
      company: "",
      subscription: "",
    },
    searchProsText: {
      search: "",
      company: "",
      subscription: "",
    },
    prospectSearches: {
      search: "",
      company: "",
      status: "",
      subscription: "",
      assignedUser: "",
      date: "",
    },
    prospectSortBy: {
      key: "",
      order: "",
    },
  },
  reducers: {
    companyDropdown: (state, action) => {
      state.companyDropdown = action.payload;
    },

    vehicleCategory: (state, action) => {
      state.vehicleCategoryDropdownList = action.payload;
    },

    financeTypeCategory: (state, action) => {
      state.financetypeDropdownList = action.payload;
    },

    allUserList: (state, action) => {
      const rolesToFilter = ["MAIN"];

      const data = action.payload?.sort((a, b) =>
        a?.country?.localeCompare(b?.country)
      );
      const driverList =
        data?.length > 0 &&
        data
          ?.filter((item) =>
            rolesToFilter?.some((role) => item?.role?.includes(role))
          )
          .sort((a, b) => a?.index - b?.index);

      const clentList =
        data?.length > 0 &&
        data
          ?.filter((client) => client.role?.includes("CLIENT"))
          .sort((a, b) => a?.index - b?.index);

      const basicList =
        data?.length > 0 && data?.sort((a, b) => a?.index - b?.index);

      state.driverList = driverList;
      state.tempDriverList = driverList;
      state.clientList = clentList;
      state.tempClientList = clentList;
      state.basicList = basicList;
      state.tempBasicList = basicList;
      state.loading = false;
    },

    driverDataFilter: (state, action) => {
      state.driverList = action.payload;
    },

    clientDataFilter: (state, action) => {
      state.clientList = action.payload;
    },

    basicDataFilter: (state, action) => {
      state.basicList = action.payload;
    },
    setAllbasicDataFilter: (state, action) => {
      state.tempBasicList = action.payload;
    },
    loader: (state, action) => {
      state.loading = action.payload;
    },
    alphabeticalDriver: (state, action) => {
      state.driverList = action.payload;
    },
    alphabeticalClient: (state, action) => {
      state.clientList = action.payload;
    },
    alphabeticalBasic: (state, action) => {
      state.basicList = action.payload;
    },
    googlePlace: (state, action) => {
      state.googlePlaceList = action.payload;
    },
    changeSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setProspectSearches: (state, action) => {
      state.prospectSearches = action.payload;
    },
    setProspectSortBy: (state, action) => {
      state.prospectSortBy = action.payload;
    },
  },
});

export const {
  companyDropdown,
  vehicleCategory,
  financeTypeCategory,
  countriesDropdown,
  allUserList,
  driverDataFilter,
  clientDataFilter,
  basicDataFilter,
  loader,
  alphabeticalDriver,
  alphabeticalClient,
  alphabeticalBasic,
  googlePlace,
  changeSearchText,
  setAllbasicDataFilter,
  setProspectSearches,
  setProspectSortBy,
} = commonSlice.actions;
export default commonSlice.reducer;

// api call
export const fetchcompanyDropdown = () => async (dispatch) => {
  try {
    const tempData = await companyDropdownList();
    dispatch(companyDropdown(tempData?.data));
  } catch (error) {
    console.error("Error fetchcompanyDropdown", error);
  }
};

export const fetchVehicleCategoryDropdown = () => async (dispatch) => {
  try {
    const tempData = await vehicleCategoryDropdown();
    dispatch(vehicleCategory(tempData?.data));
  } catch (error) {
    console.error("Error fetchVehicleCategoryDropdown", error);
  }
};

export const fetchfinancetypeDropdown = () => async (dispatch) => {
  try {
    const tempData = await financetypeDropdown();
    dispatch(financeTypeCategory(tempData?.data));
  } catch (error) {
    console.error("Error fetchfinancetypeDropdown", error);
  }
};

export const fetchAllUserList = (formData) => async (dispatch) => {
  try {
    dispatch(loader(true));
    const clientData = await searchCustomerApi(formData);
    dispatch(allUserList(clientData?.data));
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching manufacturer dropdown:", error);
  }
};

export const fetchgoogleAutoAddress = (body) => async (dispatch) => {
  try {
    const addressData = await googleAutoAddress(body);
    dispatch(googlePlace(addressData?.data));
  } catch (error) {
    console.error("Error fetching manufacturer dropdown:", error);
  }
};

export const searchProsUserList = (formData) => async (dispatch) => {
  try {
    dispatch(loader(true));
    const clientData = await searchCustomerApi(formData);
    dispatch(basicDataFilter(clientData?.data));
    dispatch(setAllbasicDataFilter(clientData?.data));
    dispatch(loader(false));
  } catch (error) {
    console.log("🚀 ~ searchProsUserList ~ error:", error);
  }
};

export const searchClientUserList = (formData) => async (dispatch) => {
  try {
    dispatch(loader(true));
    const clientData = await searchCustomerApi(formData);
    dispatch(clientDataFilter(clientData?.data));
    dispatch(loader(false));
  } catch (error) {
    console.log("🚀 ~ searchClientUserList ~ error:", error);
  }
};

export const searchDriverUserList = (formData) => async (dispatch) => {
  try {
    dispatch(loader(true));
    const clientData = await searchCustomerApi(formData);
    dispatch(driverDataFilter(clientData?.data));
    dispatch(loader(false));
  } catch (error) {
    console.log("🚀 ~ searchDriverUserList ~ error:", error);
  }
};
