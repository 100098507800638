/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { Dropdown } from "primereact/dropdown";
import {
  createNotes,
  deleteClient,
  deleteClients,
  getSubscriptionByCompanylist,
  updataClientUserApi,
} from "../services/client/addclientApi";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../components/Common/Loader";
import {
  BsCheck,
  BsChevronLeft,
  BsChevronRight,
  BsChevronUp,
  BsFunnel,
  BsPersonPlus,
  BsTrash,
  BsX,
  BsXLg,
} from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";
import AddNewProspect from "../components/Prospect/AddProspect";
import { NavLink, useNavigate } from "react-router-dom";
import { pagination } from "../components/Common/Pagination";
import TableFooter from "../components/Common/TableFooter";
import {
  alphabeticalBasic,
  fetchcompanyDropdown,
  searchProsUserList,
  setProspectSearches,
  setProspectSortBy,
} from "../Redux/commonSlice";
import DeleteModal from "../components/Common/DeleteModal";
import RoleAndsubscriptionname from "../components/Prospect/Components/RoleAndsubscriptionname";
import moment from "moment/moment";
import { ReactComponent as PlusIcon } from "../assets/img/icons/fleet/plus.svg";
import { ReactComponent as FilterIcon } from "../assets/img/icons/fleet/filter.svg";
import { ReactComponent as SettingIcon } from "../assets/img/icons/fleet/settings.svg";
import { ReactComponent as DotsVertIcon } from "../assets/img/icons/fleet/dots-vertical.svg";
import { ReactComponent as UserPlusIcon } from "../assets/icons/user-plus.svg";
import { ReactComponent as ReportSearchIcon } from "../assets/icons/report-search.svg";
import { Modal } from "react-bootstrap";
import { getAdminUsers } from "../services/Adminuser";
import { schengenCountries } from "../components/Common/AddressAutocompleteInput";
import DaterangePicker from "../components/Prospect/DateRange";
import { OverlayPanel } from "primereact/overlaypanel";

const Prospect = () => {
  const { permissions } = useSelector((state) => state.userSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addShow, setAddShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [clientId, setclientId] = useState(null);
  const [isSubscriptionValue, setSubscriptionValue] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState("25");
  const [assignUser, setAssignUser] = useState(false);
  const [initialValue, setInitialValue] = useState({
    search: "",
    company: "",
    status: "",
    subscription: "",
  });
  const [reload, setReload] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [filterOpts, setFilterOpts] = useState([]);
  const [filterKey, setFilterKey] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [prospectSearch, setProspectSearch] = useState(false);

  const {
    companyDropdown,
    basicList,
    tempBasicList,
    loading,
    prospectSearches,
    prospectSortBy,
  } = useSelector((state) => state?.commonSlice);

  // pagination start
  const basicListData = pagination(basicList, currentPage, itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  // pagination end

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async (id) => {
    const subscriptionValue = await getSubscriptionByCompanylist(id);
    if (subscriptionValue !== undefined) {
      setSubscriptionValue(subscriptionValue?.data);
    } else {
      setSubscriptionValue([]);
    }
  };
  // subscriptionDropdownValue end

  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };

  const aTozSorting = (key) => {
    dispatch(setProspectSortBy({ key, order: "aToz" }));
    // Create a temporary array with 'member' assigned to 'comp_id.name' for sorting.
    const tempData = tempBasicList.map((item) => ({
      ...item,
      member: item?.comp_id?.name,
    }));

    // Perform A-to-Z (ascending) sorting based on the key.
    const sortedData = tempData.sort((a, b) => {
      // Handle potential undefined or null values gracefully.
      const valA = a?.[key] ?? "";
      const valB = b?.[key] ?? "";

      // Check if the key is 'index' to perform numerical sorting.
      if (key === "index") {
        return Number(valA) - Number(valB); // Ascending numerical sort
      }

      return valA.localeCompare(valB);
    });

    // Dispatch the sorted data to the store.
    dispatch(alphabeticalBasic(sortedData));
  };

  const zToaSorting = (key) => {
    dispatch(setProspectSortBy({ key, order: "zToa" }));
    // Create a temporary array with 'member' assigned to 'comp_id.name' for sorting.
    const tempData = tempBasicList.map((item) => ({
      ...item,
      member: item?.comp_id?.name,
    }));

    // Perform Z-to-A (descending) sorting based on the key.
    const sortedData = tempData.sort((a, b) => {
      // Handle potential undefined or null values gracefully.
      const valA = a?.[key] ?? "";
      const valB = b?.[key] ?? "";

      // Check if the key is 'index' to perform numerical sorting.
      if (key === "index") {
        return Number(valB) - Number(valA); // Descending numerical sort
      }

      return valB.localeCompare(valA);
    });

    // Dispatch the sorted data to the store.
    dispatch(alphabeticalBasic(sortedData));
  };

  const handleSingleProspect = async (customer) => {
    if (customer?.role?.length > 1) {
      navigate(`/client/profile/${customer?._id}`);
    } else {
      if (customer?.role?.includes("BASIC")) {
        navigate(`/prospect/document/${customer?._id}`);
      } else if (
        customer?.role?.includes("AUX") ||
        customer?.role?.includes("MAIN")
      ) {
        navigate(`/driver/profile/${customer?._id}`);
      } else if (customer?.role?.includes("MAIN")) {
        navigate(`/driver/profile/${customer?._id}`);
      } else if (customer?.role?.includes("CLIENT")) {
        navigate(`/client/profile/${customer?._id}`);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentPage(0);
    dispatch(setProspectSearches({ ...prospectSearches, [name]: value }));
    // setInitialValue((prev) => {
    //   const updatedFilters = { ...prev, [name]: value };
    //   return updatedFilters;
    // });
  };

  const handleDelete = async (id) => {
    const form = new FormData();
    form.append("role", ["MAIN", "CLIENT", "AUX", "BASIC"]);
    await deleteClient(clientId);
    setModalShow(false);
    dispatch(searchProsUserList(form));
  };

  // Handle tag change
  const handleTagChange = async (id, newTags) => {
    const body = { tags: newTags };
    await createNotes(id, body);
  };

  const Status = [
    { label: "Basic", value: "BASIC" },
    { label: "Client", value: "CLIENT" },
    { label: "AUX", value: "AUX" },
    { label: "One", value: "One" },
    { label: "Plus", value: "Plus" },
    { label: "Demande", value: "Demande" },
  ];

  const filters = [
    { key: "company", label: "Société" },
    { key: "status", label: "Status" },
    { key: "localisation", label: "Localisation" },
    { key: "date", label: "Date de Creation" },
  ];

  useEffect(() => {
    const form = new FormData();
    console.log(prospectSearches);
    if (prospectSearches?.search)
      form.append("searchname", prospectSearches?.search);
    if (prospectSearches?.company?._id)
      form.append("comp_id", prospectSearches?.company?._id);
    if (prospectSearches?.subscription?._id)
      form.append("sub_id", prospectSearches?.subscription?._id);
    if (prospectSearches?.status)
      form.append("role", prospectSearches?.status?.value?.toLowerCase());
    if (prospectSearches?.assignedUser)
      form.append("assignedUser", prospectSearches?.assignedUser);
    const debounceTimeout = setTimeout(() => {
      dispatch(searchProsUserList(form));
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [
    prospectSearches?.search,
    prospectSearches?.company,
    prospectSearches?.subscription,
    prospectSearches?.status,
    prospectSearches?.assignedUser,
    reload,
  ]);

  useEffect(() => {
    dispatch(fetchcompanyDropdown());
  }, []);

  useEffect(() => {
    if (prospectSortBy?.key && tempBasicList.length > 0) {
      if (prospectSortBy?.order === "aToz") aTozSorting(prospectSortBy?.key);
      if (prospectSortBy?.order === "zToa") zToaSorting(prospectSortBy?.key);
    }
  }, [tempBasicList]);

  const toggleAssignUser = (user) => {
    setAssignUser(assignUser ? false : user);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
    setFilterKey("");
    setFilterOpts([]);
    setProspectSearch(false);
  };

  const toggleFilterOpts = (key) => {
    const opts = {
      company: companyDropdown,
      status: Status,
      localisation: schengenCountries,
      date: [
        {
          key: "today",
          label: "Aujourd’hui",
        },
        {
          key: "last7days",
          label: "Les 7 derniers Jours",
        },
        {
          key: "lastmonth",
          label: "Dans le dernier mois",
        },
      ],
    };
    toggleFilters();
    setFilterKey(key);
    setFilterOpts(opts[key]);
  };

  const searchMyProspects = () => {
    dispatch(
      setProspectSearches({ ...prospectSearches, assignedUser: "My Prospects" })
    );
    setProspectSearch(true);
  };

  const toggleDeleteConfirmation = () =>
    setDeleteConfirmation(!deleteConfirmation);

  const deleteSelectedRows = async () => {
    try {
      await deleteClients({ ids: selectedRows });
      setReload(!reload);
      toggleDeleteConfirmation();
      setSelectedRows([]);
    } catch {}
  };

  // When no list permission assigned
  if (!permissions.includes("PROSPECT.LIST")) return <></>;

  return (
    <div className="container-fluid flex-grow-1 container-p-y client">
      <div className="card" style={{ borderRadius: "0" }}>
        {showFilters && (
          <div className="d-flex gap-3 p-2">
            {filters.map((filt) => (
              <button
                className="btn fleet-new-filt"
                onClick={() => toggleFilterOpts(filt.key)}
              >
                {filt.label} <BsChevronRight />
              </button>
            ))}
          </div>
        )}
        {filterOpts?.length > 0 && (
          <div className="d-flex gap-3 align-items-center  p-2 ">
            <div className="d-flex gap-3 fleet-new-filt-opt-container">
              <button className="btn fleet-new-filt-opt">
                {filters.find((r) => r.key === filterKey)?.label}
              </button>
              {filterKey === "date" && (
                <DaterangePicker
                  className={`btn fleet-new-filt-opt ${
                    (typeof prospectSearches[filterKey] === "string"
                      ? prospectSearches[filterKey]
                      : prospectSearches[filterKey]?.name ||
                        prospectSearches[filterKey]?.label) === "custom"
                      ? `fleet-new-filt-opt-active`
                      : ``
                  }`}
                  onDateChange={(r) => {
                    if (r.start && r.end) {
                      handleChange({
                        target: {
                          name: filterKey,
                          value: `${r.start}-${r.end}`,
                        },
                      });
                    }
                  }}
                />
              )}
              {filterOpts.map((filt) => {
                const optVal =
                  typeof filt === "string" ? filt : filt?.name || filt?.label;
                return (
                  <button
                    className={`btn fleet-new-filt-opt ${
                      (typeof prospectSearches[filterKey] === "string"
                        ? prospectSearches[filterKey]
                        : prospectSearches[filterKey]?.name ||
                          prospectSearches[filterKey]?.label) === optVal
                        ? `fleet-new-filt-opt-active`
                        : ``
                    }`}
                    onClick={() =>
                      handleChange({
                        target: {
                          name: filterKey,
                          value: filt,
                        },
                      })
                    }
                  >
                    {optVal}
                  </button>
                );
              })}
            </div>
            <button
              className="btn fleet-new-filt-opt-clear"
              onClick={toggleFilters}
            >
              <BsChevronLeft />
            </button>
          </div>
        )}
        <div className="d-flex align-items-center white-bg-card-filt">
          <input
            placeholder="Search..."
            className="form-control me-2"
            style={{ width: "200px" }}
            type="text"
            name="search"
            onChange={handleChange}
            value={prospectSearches?.search}
          />

          {permissions.includes("PROSPECT.ADD") && (
            <button
              className="btn p-2 plus-ic"
              onClick={() => setAddShow(true)}
            >
              <PlusIcon />
            </button>
          )}
          <button
            data-type="filter"
            className={`btn p-2 ${showFilters ? `active-action` : ``}`}
            onClick={toggleFilters}
          >
            <BsFunnel style={{ fontSize: "20px" }} />
          </button>
          <button
            data-type="search"
            className={`btn p-2 ${prospectSearch ? `active-action` : ``}`}
            onClick={searchMyProspects}
          >
            <ReportSearchIcon />
          </button>
          <button data-type="setting" className="btn p-2">
            <SettingIcon />
          </button>
          <button data-type="filter" className="btn p-2">
            <DotsVertIcon />
          </button>
          <div className="d-flex gap-2">
            {Object.values(prospectSearches).length > 0 &&
              Object.entries(prospectSearches).map(
                (k) =>
                  k[1] && (
                    <div
                      className="selected-filt"
                      role="button"
                      onClick={() => {
                        dispatch(
                          setProspectSearches({
                            ...prospectSearches,
                            [k[0]]: "",
                          })
                        );
                        setProspectSearch(false);
                      }}
                    >
                      {typeof k[1] === "string"
                        ? k[1]
                        : k[1]?.name || k[1]?.label}{" "}
                      <BsXLg />
                    </div>
                  )
              )}

            {selectedRows.length > 0 && !deleteConfirmation && (
              <button
                className="btn btn-primary  p-2"
                onClick={toggleDeleteConfirmation}
              >
                <BsTrash />
                &nbsp;&nbsp; Supprimer
              </button>
            )}
            {selectedRows.length > 0 && deleteConfirmation && (
              <div className="d-flex gap-2  p-2">
                <button className="btn btn-danger" onClick={deleteSelectedRows}>
                  <BsCheck />
                  &nbsp;&nbsp; Valider
                </button>
                <button
                  className="btn btn-default"
                  onClick={toggleDeleteConfirmation}
                >
                  <BsX />
                  &nbsp;&nbsp; Annuler
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="new-table mt-3">
          <div className=" table-responsive">
            <table className="table  table-hover">
              <thead className="">
                <tr>
                  <th>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={(e) =>
                        setSelectedRows(
                          !e.target.checked
                            ? []
                            : basicListData.map((r) => r._id)
                        )
                      }
                      checked={selectedRows.length === basicListData.length}
                    />
                  </th>
                  <th>
                    #{" "}
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1"></span>
                    </div>
                  </th>
                  <th>
                    <span className="position-relative top-4">STATUS</span>
                    <div className="d-inline-block item-center">
                      <span
                        className="d-flex flex-column ps-1"
                        style={{ opacity: "0" }}
                      >
                        <BsChevronUp fontSize={12} />
                        <BsChevronDown fontSize={12} />
                      </span>
                    </div>
                  </th>

                  <th>
                    <span className="position-relative top-4">NOM</span>
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1">
                        <BsChevronUp
                          fontSize={12}
                          onClick={() => aTozSorting("firstname")}
                        />
                        <BsChevronDown
                          fontSize={12}
                          onClick={() => zToaSorting("firstname")}
                        />
                      </span>
                    </div>
                  </th>
                  <th>
                    <span className="position-relative top-4">E-MAIL</span>
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1"></span>
                    </div>
                  </th>
                  <th>
                    <span className="position-relative top-4">
                      Date de Creation
                    </span>
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1">
                        <BsChevronUp
                          fontSize={12}
                          onClick={() => aTozSorting("createdAt")}
                        />
                        <BsChevronDown
                          fontSize={12}
                          onClick={() => zToaSorting("createdAt")}
                        />
                      </span>
                    </div>
                  </th>
                  <th>ACTIONS </th>
                </tr>
              </thead>
              <tbody>
                {basicListData?.length > 0 &&
                  basicListData?.map((customer, index) => {
                    return (
                      <tr
                        className="cursor-pointer"
                        key={customer?.id}
                        onClick={() => {
                          handleSingleProspect(customer);
                        }}
                      >
                        <td onClick={(e) => e.stopPropagation()}>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={selectedRows.includes(customer._id)}
                            onChange={(e) => {
                              setSelectedRows(
                                !e.target.checked
                                  ? selectedRows.filter(
                                      (r) => r !== customer._id
                                    )
                                  : [...selectedRows, customer._id]
                              );
                            }}
                          />
                        </td>
                        <td>{customer?.index}</td>
                        <td className="text-nowrap">
                          <div className="d-flex gap-2">
                            <RoleAndsubscriptionname
                              roles={customer?.role}
                              beclient={customer?.beclient}
                              subscription_name={
                                customer?.sub_id?.subscription_name
                              }
                              onboardingStep={customer?.onboarding_last_step}
                              contactFilled={customer?.contact}
                            />
                          </div>
                        </td>

                        <td className="text-nowrap cursor-pointer font-weight-bold">
                          {customer?.firstname} &nbsp;{customer?.lastname}
                        </td>
                        <td className="font-weight-bold">{customer?.email}</td>
                        <td className="d-flex align-items-center gap-2 font-weight-bold justify-content-between">
                          {moment(customer?.createdAt).format(
                            `DD MMM yyyy hh:mm a`
                          )}

                          <td
                            onClick={(e) => e.stopPropagation()}
                            className="d-flex"
                          >
                            <AssignUserModel
                              setReload={() => setReload(!reload)}
                              data={customer}
                              customer={customer}
                            />
                            <TagsInput
                              value={customer.tags}
                              onChange={(newTags) =>
                                handleTagChange(customer._id, newTags)
                              }
                              placeHolder="tags"
                              style={{
                                height: "20px",
                              }}
                            />
                          </td>
                        </td>
                        <td>
                          <i
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalShow(true);
                              setclientId(customer?._id);
                            }}
                            className="menu-icon tf-icons  ti ti-sm ti-trash"
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {!loading && (basicList?.length === 0 || !basicList) && (
              <div className="text-center my-4 mx-auto">No Data Found</div>
            )}

            {loading && (
              <div className="text-center my-4 mx-auto">
                <Loader />
              </div>
            )}

            {basicList?.length > 0 && (
              <TableFooter
                itemsPerPage={itemsPerPage}
                handleSelectChange={handleSelectChange}
                listData={basicList}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </div>

        {/* add prospect */}
        {addShow && (
          <AddNewProspect
            show={addShow}
            onHide={() => setAddShow(false)}
            setclientId={setclientId}
          />
        )}

        {modalShow && (
          <DeleteModal
            title={"Supprimer Cette Prospect"}
            description={"Êtes-vous sûr de supprimer le Prospect ?"}
            show={modalShow}
            clientId={clientId}
            deleteData={() => handleDelete(false)}
            onHide={() => setModalShow(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Prospect;

const AssignUserModel = ({ data, setReload, customer }) => {
  const overlayPanelRef = useRef(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAdminUsers().then((res) => {
      const result = res.data.map((r) => ({
        fullName: `${r.fname} ${r.lname}`,
        fName: r.fname,
        lName: r.lname,
        email: r.email,
        role: r.role,
        _id: r._id,
      }));
      setUsers(result);
    });
  }, []);

  const handleSubmit = async (e) => {
    await updataClientUserApi(data._id, { userId: e?.value?._id || "" });
    setReload();
    overlayPanelRef.current.toggle(e);
  };

  return (
    <>
      <div
        style={{
          border: "1px solid #DBDADE",
          padding: "0 5px",
          borderRadius: "6px 0 0 6px",
          background: customer?.assignedUser ? `#0084FF52` : `#FFF`,
          display: "flex",
          alignItems: "center",
        }}
        className="assign-u"
        role="button"
        onClick={(e) => {
          e.stopPropagation();
          // toggleAssignUser(customer);
          overlayPanelRef.current.toggle(e);
        }}
      >
        {!customer?.assignedUser && <BsPersonPlus />}
        {customer?.assignedUser &&
          `${customer?.assignedUser?.fname.charAt(
            0
          )} ${customer?.assignedUser?.lname.charAt(0)}`}
      </div>
      <OverlayPanel ref={overlayPanelRef} dismissable style={{ padding: 0 }}>
        <Dropdown
          // value={selectedOption}
          options={users}
          onChange={handleSubmit}
          placeholder="Select an user"
          style={{ width: "100%" }}
          optionLabel="fullName"
          filter
        />

        {customer?.assignedUser && (
          <button className="btn btn-danger mt-2" onClick={handleSubmit}>
            <BsXLg />
            &nbsp;&nbsp; Remove
          </button>
        )}
      </OverlayPanel>
    </>
  );
};
