import moment from "moment";
import "moment/locale/fr";

const CheckListItem = ({
  loading,
  item,
  expandedItemId,
  onButtonClick,
  ontoggleExpand,
}) => {
  moment.locale("fr");
  // moment.updateLocale("fr", {
  //     months: [
  //         "Janvier",
  //         "Février",
  //         "Mars",
  //         "Avril",
  //         "Mai",
  //         "Juin",
  //         "Juillet",
  //         "Août",
  //         "Septembre",
  //         "Octobre",
  //         "Novembre",
  //         "Décembre",
  //     ],
  // });
  return (
    <>
      <div className="card mb-3" style={{ borderRadius: "0" }}>
        <div
          className="card-body d-flex justify-content-between mb-0"
          onClick={() => ontoggleExpand(item._id)}
          style={{ cursor: "pointer" }}
        >
          <div style={{ width: "25%" }}>
            <span className="jours">{item?.leftDays} Jour(s)</span>
          </div>
          <div style={{ width: "33%" }}>
            <h5 className="card-title f-title mb-0 text-start">
              {" "}
              {item?.manufacturer} {item?.model}
            </h5>
            <p className="card-text f-txt"> {item?.license_plate}</p>
          </div>
          <div className="text-end">
            <button
              className={`btn ${
                item?.reservation_status === "CHECKOUT"
                  ? "btn-warning"
                  : "btn-info"
              }`}
              onClick={() => {
                onButtonClick(item);
              }}
            >
              {item?.reservation_status === "CHECKOUT"
                ? "Check-Out"
                : "Check-in"}
            </button>
          </div>
        </div>
        {expandedItemId === item._id && (
          <div className="card-body px-3 py-0">
            <p className="card-text mb-1">
              <span
                className="badge me-2 "
                style={{ background: "#00CFE829", color: "#00CFE8" }}
                textcapitalized=""
              >
                C
              </span>
              <span>{item?.clientName}</span>
            </p>
            <p className="card-text mb-1">
              <span
                className="badge me-2"
                style={{ background: "#0084FF29", color: "#0084FF" }}
              >
                M
              </span>
              <span>{item?.Maindrivername}</span>
              {item.subscriptionname && (
                <span
                  className=" me-2 ms-2 text-white px-1"
                  style={{
                    background: "#00CFE8",
                    borderRadius: "4px",
                    padding: "1px",
                  }}
                >
                  {item?.subscriptionname}
                </span>
              )}
            </p>
            {item &&
              item.auxDrivers &&
              item.auxDrivers.length > 0 &&
              item.auxDrivers.map((aux, index) => (
                <p className="card-text mb-1" key={index}>
                  <span
                    style={{ background: "#0084FF29", color: "#0084FF" }}
                    className="badge me-2"
                  >
                    A
                  </span>
                  <span>
                    {aux?.firstname} {aux?.lastname}
                  </span>
                </p>
              ))}

            {/* <p className="card-text mb-1">
                            <span
                                style={{ background: "#0084FF29", color: "#0084FF" }}
                                className="badge me-2"
                            >
                                A
                            </span>
                            <span>{item?.Auxdrivername}</span>
                        </p> */}
            <p className="card-text mb-1">
              <p className="mb-1">{item?.monthlyprice} €</p>
              <p className="mb-0">
                {moment(item?.return_date).format("DD/MM/YYYY HH:mm:ss")}
              </p>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default CheckListItem;
