/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import {
  addDocument,
  deleteDocumentById,
  updateVehicle,
} from "../../../../services/AddVehicle";
import { getDocumentById } from "../../../../services/AddVehicle/index";
import { Dropdown } from "primereact/dropdown";
import { colorTemplate } from "../../../Common/colorTemplate";
import { Color } from "../../../Common/DropdownValue";
import upload from "../../../../assets/img/upload.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleVehicleList } from "../../../../Redux/vehicleSlice";
import { SmallLoader } from "../../../Common/Loader";
import { useParams } from "react-router-dom";
import { fetchColors } from "../../../../Redux/colorSlice";

function ColorModal(props) {
  const fileInputRef = useRef();
  const [selectedImage, setSelectedImage] = useState([]);
  const dispatch = useDispatch();
  const [isinitialImg, setinitialImg] = useState([]);
  const [isImgId, setImgId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { singlevehicleData } = useSelector((state) => state.vehicleSlice);
  const { colors } = useSelector((state) => state.colorSlice);

  const { id } = useParams();

  const singleData = async () => {
    const documentData = await getDocumentById(id);
    setinitialImg(documentData?.data);
  };

  useEffect(() => {
    dispatch(fetchColors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // multiple-imge upload Start
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const uploadImage = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files;

    if (selectedFile && selectedFile.length > 0) {
      const newImages = Array.from(selectedFile).map((file) => {
        return {
          file: file,
          source: URL.createObjectURL(file),
        };
      });
      setSelectedImage((prevImages) => [...prevImages, ...newImages]);
    }
  };
  // multiple-imge upload end

  // removeFile Start
  const removeFile = (index, e) => {
    let temImgData = selectedImage.filter((_, i) => i !== index);
    setSelectedImage(temImgData);
    e.stopPropagation();
  };
  // removeFile end

  useEffect(() => {
    singleData();
  }, [id, isImgId]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Mettre à jour les données
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ color: singlevehicleData?.General?.color || "" }}
          enableReinitialize={true}
          onSubmit={async (values) => {
            const formData = new FormData();

            selectedImage?.map((item) =>
              formData.append("document", item.file)
            );
            formData.append("general_info", id);

            const tempData = {
              ...singlevehicleData,
              General: { ...singlevehicleData?.General, color: values.color },
            };
            setLoading(true);
            await addDocument(formData);
            const data = await updateVehicle(id, tempData);

            if (data) {
              props.onHide();
              dispatch(fetchSingleVehicleList(id));
              setLoading(false);
            }
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              <div>
                <div className="col mb-4">
                  <label
                    className="form-label mb-1 d-flex justify-content-between align-items-center"
                    htmlFor="category-org"
                  >
                    Couleur
                  </label>
                  <Dropdown
                    placeholder="Blanc"
                    valueTemplate={colorTemplate(values?.color)}
                    onChange={handleChange}
                    options={colors?.map((color) => ({
                      name: color.name,
                      image: color.image,
                    }))}
                    itemTemplate={colorTemplate}
                    optionLabel="name"
                    name="color"
                    className="w-full"
                  />
                </div>
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 card-title">Documents</h5>
                  </div>
                  <div className="card-body">
                    <div
                      onClick={openFileInput}
                      className="dropzone needsclick dz-clickable"
                      id="dropzone-multi"
                    >
                      {isinitialImg?.length > 0 || selectedImage.length > 0 ? (
                        <>
                          <div className="row justify-content-center ">
                            {selectedImage.map((image, index) => (
                              <div
                                key={index}
                                className="col-md-5 border rounded dz-preview dz-processing dz-image-preview dz-success dz-complete p-0"
                              >
                                <div className="dz-details">
                                  <div className="dz-thumbnail">
                                    <img alt="066.png" src={image.source} />
                                  </div>
                                  <hr className="my-1" />
                                  <div className="d-flex justify-content-center">
                                    <div className="dz-filename text-wrap text-center">
                                      {image?.file?.name}
                                    </div>
                                    <div className="dz-size">
                                      <strong>
                                        {(
                                          image.file.size /
                                          (1024 * 1024)
                                        ).toFixed(2)}
                                      </strong>
                                      MB
                                    </div>
                                  </div>
                                </div>
                                <hr className="my-1" />
                                <div
                                  className="dz-remove cursor-pointer"
                                  onClick={(e) => {
                                    removeFile(index, e);
                                  }}
                                >
                                  Remove file
                                </div>
                              </div>
                            ))}
                            {isinitialImg?.map((image, index) => (
                              <div
                                key={index}
                                className="col-md-5 border rounded dz-preview dz-processing dz-image-preview dz-success dz-complete p-0"
                              >
                                <div className=" dz-details">
                                  <div className="dz-thumbnail">
                                    <img
                                      alt="066.png"
                                      src={`${process.env.REACT_APP_IMG_URL}${image?.document}`}
                                    />
                                  </div>
                                  <hr className="my-1" />
                                  <div className="d-flex justify-content-center">
                                    <div className="dz-filename">066.png</div>
                                    <div className="dz-size">
                                      <strong>0.1</strong>
                                      MB
                                    </div>
                                  </div>
                                </div>
                                <hr className="my-1" />
                                <div
                                  onClick={async (e) => {
                                    e.stopPropagation();
                                    await deleteDocumentById(image?._id);
                                    setImgId(image?._id);
                                  }}
                                  className="dz-remove cursor-pointer d-flex justify-content-center align-content-center"
                                >
                                  {isImgId === image?._id && (
                                    <span
                                      class="spinner-border spinner-border-sm text-secondary me-2 "
                                      role="status"
                                    ></span>
                                  )}
                                  Remove file
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="dz-message needsclick">
                            <img
                              style={{ width: "3rem" }}
                              src={upload}
                              alt=""
                            />
                            <div> Glissez et déposez vos documents</div>
                            <span className="text-muted d-block fw-normal mb-2">
                              Ou
                            </span>
                            <span className="note needsclick btn bg-label-primary d-inline">
                              Parcourir les documents
                            </span>
                          </div>
                        </>
                      )}
                      <input
                        hidden
                        multiple
                        type="file"
                        ref={fileInputRef}
                        onChange={uploadImage}
                        accept="image/*,application/pdf"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end gap-3">
                <button
                  className="btn btn-label-danger waves-effect"
                  onClick={props.onHide}
                >
                  Annuler
                </button>
                <button className="btn btn-primary" type="submit">
                  Add {isLoading && <SmallLoader />}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default ColorModal;
