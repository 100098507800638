/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewNotification from "../components/NewNotification/NewNotification";
import HistoryNotification from "../components/HistoryNotification/HistoryNotification";


const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("auth_token")) {
      navigate("/login");
    }
  }, []);
  return (
  <>
    <NewNotification />
    <HistoryNotification />
  </>
  );
};

export default Home;
