import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";

const FractionInfo = ({ formik, editMode }) => {
  const { countriesList } = useSelector((state) => state?.commonSlice);

  return (
    <div className="row comp-content-form">
      <div className="col-2">
        <label className="form-label" htmlFor="username-modern-vertical">
          Nom Légal de la Société
        </label>
        <input
          type="text"
          className={`${!editMode ? "hide-input" : "form-control"} ${
            formik?.errors?.billingInfo?.legalname ? "is-invalid" : ""
          }`}
          placeholder="Nom"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.billingInfo.legalname}
          name="billingInfo.legalname"
          disabled={!editMode}
        />
      </div>
      <div className="col-2">
        <label htmlFor="defaultSelect" class="form-label">
          Pays d'Incorporation
        </label>
        <Dropdown
          options={countriesList}
          placeholder="Sélectionner"
          filter
          className={`w-full ${!editMode ? "p-dropdown-hide" : ".p-dropdown"} ${
            formik?.errors?.billingInfo?.country && "dropdown-invalid"
          }`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.billingInfo.country}
          name="billingInfo.country"
          disabled={!editMode}
        />
      </div>
      <div className="col-2">
        <label className="form-label" htmlFor="password-modern-vertical">
          Rue
        </label>
        <div className="input-group input-group-merge">
          <input
            type="text"
            id="password-modern-vertical"
            className={`${!editMode ? "hide-input" : "form-control"} ${
              formik?.errors?.billingInfo?.street ? "is-invalid" : ""
            }`}
            placeholder="Rue"
            aria-describedby="password2-modern-vertical"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.billingInfo.street}
            name="billingInfo.street"
            disabled={!editMode}
          />
        </div>
      </div>
      <div className="col-1">
        <label
          className="form-label"
          htmlFor="confirm-password-modern-vertical"
        >
          Numéro
        </label>
        <div className="input-group input-group-merge">
          <input
            type="text"
            id="confirm-password-modern-vertical"
            className={`${!editMode ? "hide-input" : "form-control"} ${
              formik?.errors?.billingInfo?.number ? "is-invalid" : ""
            }`}
            placeholder="Numéro"
            aria-describedby="confirm-password-modern-vertical2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.billingInfo.number}
            name="billingInfo.number"
            disabled={!editMode}
          />
        </div>
      </div>
      <div className="col-2">
        <label
          className="form-label"
          htmlFor="confirm-password-modern-vertical"
        >
          Ville
        </label>
        <div className="input-group input-group-merge">
          <input
            type="text"
            id="confirm-password-modern-vertical"
            className={`${!editMode ? "hide-input" : "form-control"} ${
              formik?.errors?.billingInfo?.city ? "is-invalid" : ""
            }`}
            placeholder="Ville"
            aria-describedby="confirm-password-modern-vertical2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.billingInfo.city}
            name="billingInfo.city"
            disabled={!editMode}
          />
        </div>
      </div>

      <div className="col-1">
        <label
          className="form-label"
          htmlFor="confirm-password-modern-vertical"
        >
          Province
        </label>
        <div className="input-group input-group-merge">
          <input
            type="text"
            id="confirm-password-modern-vertical"
            className={`${!editMode ? "hide-input" : "form-control"} ${
              formik?.errors?.billingInfo?.province ? "is-invalid" : ""
            }`}
            placeholder="Province"
            aria-describedby="confirm-password-modern-vertical2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.billingInfo.province}
            name="billingInfo.province"
            disabled={!editMode}
          />
        </div>
      </div>

      <div className="col-2">
        <label
          className="form-label"
          htmlFor="confirm-password-modern-vertical"
        >
          Code Postal
        </label>
        <div className="input-group input-group-merge">
          <input
            type="number"
            id="confirm-password-modern-vertical"
            className={`${!editMode ? "hide-input" : "form-control"} ${
              formik?.errors?.billingInfo?.postal ? "is-invalid" : ""
            }`}
            placeholder="Code Postal"
            aria-describedby="confirm-password-modern-vertical2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.billingInfo.postal}
            name="billingInfo.postal"
            disabled={!editMode}
          />
        </div>
      </div>
    </div>
  );
};

export default FractionInfo;
