import React from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdCheckmark } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";
import "../../css/notification.css";
import img1 from "../.././assets/icons/circle-x.png"
import img2 from "../.././assets/icons/check.png"
import img3 from "../.././assets/icons/trash.png"

const notifications = [
  {
    bgColor: "#0084FF", 
    textColor: "#ffffff",
    clientText: "CLIENT ",
    clientName: "CLIENT NAME",
    message: "made a request to add a drive to their DRIVE account",
    time: "5:10 AM"
  },
  {
    bgColor: "#28C76F", 
    textColor: "#fff",
    clientText: "ONE",
    clientName: "Firstname Lastname",
    message: "Removed a payment info",
    time: "6:15 AM"
  },
  {
    bgColor: "rgba(40, 199, 111, 0.16)",
    textColor: "#28C76F",
    clientText: "PLUS",
    clientName: "Firstname Lastname",
    message: "Removed a payment info",
    time: "7:30 AM"
  },
  {
    bgColor: " rgba(0, 207, 232, 0.16", 
    textColor: "#00CFE8",
    clientText: "AUX",
    clientName: "Client Four",
    message: "Believing These 7 Myths About Event Keeps You From",
    time: "8:45 AM"
  },
  {
    bgColor: "rgba(168, 170, 174, 0.16)", 
    textColor: "#A8AAAE",
    clientText: "BASIC",
    clientName: "Client Five",
    message: "Asked to be a subscriber",
    time: "21 Jan"
  },
  {
    bgColor: "#EA5455", 
    textColor: "#fff",
    clientText: "TURISMO",
    clientName: "Client Five",
    message: "Modified the license plate on Ferrari Pista",
    time: "2 Feb"
  },
  {
    bgColor: "#FF9F43", 
    textColor: "#fff",
    clientText: "VEHICULE",
    clientName: "Client Five",
    message: "Inssurance is almost time for repair",
    time: "2 Feb"
  }
];

export default function NewNotification() {
  return (
    <div className="py-3 px-4">
      <div className="dv1 d-flex flex-row align-content-center justify-content-between border-clr  custom-padding">
        <div>
          <span className="text-custom-notification">New Notification</span>
        </div>
        <div className="d-flex align-items-center gap-3">
          <input className="form-check-input custom-checkbox" type="checkbox" id="smallCheckbox" />
          <img src={img3} alt="" />
          <img src={img2} alt="" />
        <img src={img1} alt="" />
        </div>
      </div>

      {notifications.map((notification, index) => (
        <div key={index} className="d-flex px-3 py-3 border-b align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center gap-3" style={{ minWidth: "150px" }}>
              <input className="form-check-input custom-checkbox" type="checkbox" id="smallCheckbox" />
              
              <div className="clinet_details"
                style={{ 
                  backgroundColor: notification.bgColor, 
                  color: notification.textColor,
                  padding: '5px 10px',
                  borderRadius: "4px", 
                }} 
              >
                {notification.clientText}
              </div>
            </div>
            <div className="d-flex align-items-center gap-4">
              <div className="clientname-font">{notification.clientName}</div>
              <div className="clientmessage-font">{notification.message}</div>
            </div>
          </div>
          <div className="d-flex gap-3 align-items-center">
         
            {index === 0 && (
              <>
                <img src={img2} alt="" />
                <img src={img1} alt="" />
              </>
            )}
            <div className="time-text">{notification.time}</div>
          </div>
        </div>
      ))}
    </div>
  );
}












// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { IoMdCheckmark } from "react-icons/io";
// import { RiDeleteBinLine } from "react-icons/ri";
// import { IoIosCloseCircleOutline } from "react-icons/io";
// import "../../css/notification.css"


// const notifications = [
//     {
//       bgColor: "#007bff", 
//       textColor: "#ffffff",
//       clientText: "CLIENT ",
//       clientName: "CLIENT NAME",
//       message: "made a request to add a drive to their DRIVE account",
//       time: "5:10 AM"
//     },
//     {
//       bgColor: "#28a745", 
//       textColor: "#fff",
//       clientText: "ONE",
//       clientName: "Client Two",
//       message: "made a change to their account settings",
//       time: "6:15 AM"
//     },
//     {
//       bgColor: "#90EE90", 
//       textColor: "#28a745",
//       clientText: "PLUS",
//       clientName: "Client Three",
//       message: "requested support for their DRIVE account",
//       time: "7:30 AM"
//     },
//     {
//       bgColor: "#D5FFFF", 
//       textColor: "#54B4D3",
//       clientText: "AUX",
//       clientName: "Client Four",
//       message: "requested a password reset",
//       time: "8:45 AM"
//     },
//     {
//       bgColor: "rgba(168, 170, 174, 0.16)", 
//       textColor: "#A8AAAE",
//       clientText: "BASIC",
//       clientName: "Client Five",
//       message: "updated their profile picture",
//       time: "9:00 AM"
//     },
//     {
//       bgColor: "#DC4C64", 
//       textColor: "#fff",
//       clientText: "TURISMO",
//       clientName: "Client Five",
//       message: "updated their profile picture",
//       time: "9:00 AM"
//     },
//     {
//       bgColor: "#E4A11B", 
//       textColor: "#fff",
//       clientText: "VEHICULE",
//       clientName: "Client Five",
//       message: "updated their profile picture",
//       time: "9:00 AM"
//     },
   
//   ];
  
// export default function NewNotification() {
//   return (
//     <div className="p-4">
//     <div>
//   <div className="dv1 d-flex flex-row align-content-center justify-content-between border custom-padding">
//     <div>
//     <span className=" text-custom-notification">New Notification</span>
//     </div>
//     <div className="d-flex  align-items-center gap-3">
//       <input className="form-check-input custom-checkbox" type="checkbox" id="smallCheckbox"/>
//       <RiDeleteBinLine size={22} />
//       <IoMdCheckmark size={22}/>
//       <IoIosCloseCircleOutline size={22} />
//     </div>
//   </div>

//   {notifications.map((notification, index) => (
//   <div key={index} className="d-flex px-3 py-3 border align-items-center justify-content-between">
//     <div className="d-flex align-items-center" >
//       <div className="d-flex align-items-center gap-3" style={{ minWidth: "150px" }}>
//       <input className="form-check-input custom-checkbox" type="checkbox" id="smallCheckbox" />
        
//         <div 
//           style={{ backgroundColor: notification.bgColor, color: notification.textColor,padding: '5px 10px',borderRadius:"4px"  }} 
        
//         >
//           {notification.clientText}
//         </div>
//       </div>
//       <div className="d-flex align-items-center  gap-4" >
//         <div className="clientname-font">{notification.clientName}</div>
//         <div className="clientname-font">{notification.message}</div>
//       </div>
//     </div>
//     <div className="d-flex gap-2 align-items-center">
//       <IoMdCheckmark size={22} />
//       <IoIosCloseCircleOutline size={22} />
//       <div className="time-text">{notification.time}</div>
//     </div>
//   </div>
// ))}

// </div>
      
//     </div>
//   );
// };

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { IoMdCheckmark } from "react-icons/io";
// import { RiDeleteBinLine } from "react-icons/ri";
// import { IoIosCloseCircleOutline } from "react-icons/io";
// import axios from "axios";

// export default function NewNotification() {
//   const [notifications, setNotifications] = useState([]);
//   const { id } = useParams();



//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         const response = await axios.post(`http://localhost:3003/api/v1/noti/listnotification/${id}`);
//         if (response.data.status) {
//           setNotifications(response.data.data);
//         } else {
//           console.log(response.data.msg); 
//         }
//       } catch (error) {
//         console.error("Error fetching notifications", error);
//       }
//     };

//     fetchNotifications();
//   }, [id]);

//   return (
//     <div className="p-4">
//       <div>
//         <div className="dv1 d-flex flex-row justify-content-between border px-3 py-2">
//           <div>
//             <span>New Notification</span>
//           </div>
//           <div className="d-flex gap-3 align-items-center">
//             <input className="form-check-input custom-checkbox" type="checkbox" id="smallCheckbox"/>
//             <RiDeleteBinLine />
//             <IoMdCheckmark />
//             <IoIosCloseCircleOutline />
//           </div>
//         </div>

//         {notifications.map((notification, index) => (
//           <div key={index} className="d-flex px-3 py-3 border align-items-center justify-content-between">
//             <div className="d-flex align-items-center" >
//               <div className="d-flex align-items-center gap-3" style={{ minWidth: "130px" }}>
//                 <input className="form-check-input custom-checkbox" type="checkbox" id="smallCheckbox" />
//                 <div 
//                   style={{ backgroundColor: getNotificationColor(notification.role), color: "#ffffff" }} 
//                   className="px-2 rounded-1"
//                 >
//                   {notification.role.join(', ')} 
//                 </div>
//               </div>
//               <div className="d-flex align-items-center  gap-4" >
//                 <div className="text-dark fw-semibold">{notification.send_customer}</div>
//                 <div>{notification.message}</div>
//               </div>
//             </div>
//             <div className="d-flex gap-2 align-items-center">
//               <IoMdCheckmark />
//               <IoIosCloseCircleOutline />
//               <div>{notification.time}</div> 
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );

 
//   function getNotificationColor(role) {
//     if (role.includes("MAIN")) return "#007bff";
//     if (role.includes("CLIENT")) return "#28a745";
//     if (role.includes("AUX")) return "#90EE90";
//     return "#D3D3D3"; 
//   }
// }
