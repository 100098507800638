import { useSelector } from "react-redux";

const VehicleCard = ({ item }) => {
  const {
    general_id: { color, manufacturer, model, declination },
    subscription,
    selected_data,
  } = item;

  const pricing = selected_data.pricing_data;

  return (
    <div className="col-md-6 mb-4" key={item._id}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            {manufacturer.name} {model.name}
          </h5>
          <p className="card-text">
            <strong>Subscription Type:</strong>{" "}
            {selected_data?.subscription_type || "N/A"} <br />
            <strong>Color:</strong> {color?.name} <br />
            <strong>Declination:</strong> {declination} <br />
          </p>
          <h6>Pricing ({subscription} Subscription):</h6>
          <ul className="list-group mb-3">
            <li className="list-group-item">
              <strong>Vehicle Price:</strong> €{pricing.vehicle_price}
            </li>
            <li className="list-group-item">
              <strong>Monthly Payment:</strong> €{pricing.monthly_payment}
            </li>
            <li className="list-group-item">
              <strong>Bail:</strong> €{pricing.bail}
            </li>
            {subscription === "Multi" ? (
              <>
                <li className="list-group-item">
                  <strong>Additional KM Cost:</strong> €
                  {pricing.additional_km_cost}/km
                </li>
                <li className="list-group-item">
                  <strong>Activation Fees:</strong> €{pricing.frais_dactivation}
                </li>
                <li className="list-group-item">
                  <strong>CPR:</strong> €{pricing.cpr}
                </li>
              </>
            ) : (
              <>
                <li className="list-group-item">
                  <strong>Additional KM Cost:</strong> €
                  {pricing.additional_km_cost}
                </li>
                <li className="list-group-item">
                  <strong>Acompte:</strong> €{pricing.acompte}
                </li>
                <li className="list-group-item">
                  <strong>Frais:</strong> €{pricing.frais}
                </li>
                <li className="list-group-item">
                  <strong>Duration:</strong> {selected_data.duration} months
                </li>
                <li className="list-group-item">
                  <strong>KM:</strong> {selected_data.km} km
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const RequestedVehicles = () => {
  const { customerData } = useSelector((state) => state?.clientsSlice);
  const list = customerData?.customer?.requested_vehicles;

  return (
    <div className="row">
      {list.map((d) => (
        <VehicleCard item={d} />
      ))}
    </div>
  );
};

export default RequestedVehicles;
