import { useEffect, useState } from "react";
import { ReactComponent as PaletteIcon } from "../../assets/icons/palette.svg";
import {
  BsPlus,
  BsX,
  BsUpload,
  BsSearch,
  BsTrash,
  BsPencil,
  BsCheck,
} from "react-icons/bs";
import { useDispatch } from "react-redux";
import {
  addColorAction,
  deleteColor,
  fetchColors,
  searchColors,
  updateColor,
} from "../../Redux/colorSlice";
import { useSelector } from "react-redux";

const Colors = () => {
  const dispatch = useDispatch();
  const { colors } = useSelector((state) => state.colorSlice);
  const [addMode, setAddMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [addForm, setAddForm] = useState({
    file: "",
    fileData: "",
    color: "",
  });
  const toggleAddColor = () => {
    setAddForm({
      file: "",
      fileData: "",
      name: "",
    });
    setAddMode(!addMode);
  };

  useEffect(() => {
    if (addMode) {
      document.getElementById("add-item-div").scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [addMode]);
  useEffect(() => {
    dispatch(fetchColors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addColor = () => {
    if (addForm.name && addForm.file) {
      const formData = new FormData();
      formData.append("file", addForm.file);
      formData.append("name", addForm.name);
      dispatch(
        addColorAction({
          data: formData,
          callBack: () => {
            toggleAddColor();
          },
        })
      );
    }
  };

  const deleteSelected = () => {
    dispatch(
      deleteColor({ data: deleteId, callBack: () => setDeleteId(null) })
    );
  };

  const updateColorD = () => {
    dispatch(updateColor({ data: editId, callBack: () => setEditId(null) }));
  };

  return (
    <div className="row colors-container">
      <div className="col-3 col-md-4 col-lg-3 col-xl-3 card">
        <div className="colors-heading">
          <PaletteIcon /> Couleurs véhicules
          <div className="colors-list-item mt-2" style={{ height: "42px" }}>
            <div className="color-info">
              <BsSearch />{" "}
              <input
                className="hide-input w-full"
                placeholder="Chercher"
                style={{
                  fontSize: "16px",
                }}
                onChange={(e) => dispatch(searchColors(e.target.value))}
              />
            </div>
          </div>
        </div>

        <div className="colors-list-container">
          <div className="colors-list">
            {colors.map((c) =>
              deleteId?._id === c._id ? (
                <div className="colors-list-item delete-item">
                  <div className="color-info">
                    Supprimer <strong>{c.name}</strong>?
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    <BsX
                      style={{ fontSize: "24px" }}
                      role="button"
                      onClick={() => setDeleteId(null)}
                      className="close-i"
                    />
                    <BsTrash
                      className="delete-i"
                      style={{ fontSize: "20px", color: "#EA5455" }}
                      role="button"
                      onClick={deleteSelected}
                    />
                  </div>
                </div>
              ) : editId?._id === c?._id ? (
                <div className="colors-list-item add-item">
                  <div className="color-info">
                    <div className="color-ico">
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}${c.image}`}
                        alt=""
                      />
                    </div>
                    <div className="hide-input">
                      <input
                        className="hide-input w-full"
                        placeholder="Add Color"
                        onChange={(e) =>
                          setEditId({
                            ...editId,
                            name: e.target.value,
                          })
                        }
                        value={editId.name}
                        autoFocus
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <BsCheck
                        style={{ fontSize: "24px" }}
                        role="button"
                        onClick={updateColorD}
                      />
                      <BsX
                        style={{ fontSize: "24px" }}
                        role="button"
                        onClick={() => setEditId(null)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="colors-list-item ">
                  <div className="color-info">
                    <div className="color-ico">
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}${c.image}`}
                        alt=""
                      />
                    </div>
                    <div className="hide-input">{c.name}</div>
                    <div className="d-flex gap-2 align-items-center">
                      <BsPencil
                        style={{ fontSize: "18px" }}
                        role="button"
                        onClick={() => setEditId(c)}
                      />
                      <BsX
                        style={{ fontSize: "24px" }}
                        role="button"
                        onClick={() => setDeleteId(c)}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
            {addMode && (
              <div className="colors-list-item add-item" id="add-item-div">
                <div className="color-info">
                  <div
                    className="color-ico"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    {!addForm.fileData && (
                      <BsUpload style={{ fontSize: "20px" }} />
                    )}
                    {addForm.fileData && <img src={addForm.fileData} alt="" />}
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            setAddForm({
                              ...addForm,
                              fileData: reader.result,
                              file: file,
                            }); // This is the base64 string of the image
                          };
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                  </div>
                  <input
                    className="hide-input w-full"
                    placeholder="Add Color"
                    onChange={(e) =>
                      setAddForm({
                        ...addForm,
                        name: e.target.value,
                      })
                    }
                    value={addForm.name}
                  />
                </div>
                <div className="d-flex gap-2">
                  <BsPlus
                    style={{ fontSize: "25px" }}
                    role="button"
                    onClick={addColor}
                  />
                  <BsX
                    style={{ fontSize: "24px" }}
                    role="button"
                    onClick={toggleAddColor}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="colors-footer">
          <button className="btn" onClick={toggleAddColor}>
            Nouvelle couleur <BsPlus style={{ fontSize: "25px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Colors;
