import { Collapse, IconButton } from "@mui/material";
import EditIcon from "../../assets/img/edit.svg";
import { useEffect, useState } from "react";
import { ReactComponent as DropArrow } from "../../assets/icons/down-drop.svg";

const ManageCompanySection = ({
  formik,
  component,
  label,
  defaultEdit,
  closeEdit,
  expandClose,
}) => {
  const [editMode, setEditMode] = useState(defaultEdit);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    setEditMode(defaultEdit);
  }, [defaultEdit]);

  useEffect(() => {
    if (closeEdit) setEditMode(false);
  }, [closeEdit]);

  return (
    <section className="company-item-card">
      <div className="comp-item-header">
        <label
          role="button"
          onClick={() => expandClose && setIsExpanded(!isExpanded)}
        >
          {label} {expandClose && <DropArrow style={{ width: "20px" }} />}
        </label>
        {!editMode && (
          <IconButton size="small" onClick={() => setEditMode(true)}>
            <img src={EditIcon} alt="Edit" width={24} height={24} />
          </IconButton>
        )}
        {editMode && (
          <div className="d-flex gap-2">
            <button
              className="btn btn-primary-light"
              onClick={() => !defaultEdit && setEditMode(false)}
              disabled={defaultEdit}
            >
              Annuler
            </button>
            <button
              className="btn btn-primary"
              onClick={async () => {
                await formik.handleSubmit();
                // setEditMode(false);
              }}
            >
              Valider
            </button>
          </div>
        )}
      </div>

      <Collapse collapsedSize={0} in={isExpanded} timeout="auto" unmountOnExit>
        {component(editMode)}
      </Collapse>
    </section>
  );
};

export default ManageCompanySection;
