import React, { useEffect, useState } from "react";
import { Table, Badge } from "react-bootstrap";
import "../../css/roles.css";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/trash.svg";
import EditUser from "./EditUser";
import UserInfo from "./UserInfo";
import Deleteuser from "./Deleteuser";
import { Box, InputBase } from "@mui/material";
import AddUser from "./AddUser";
import {
  addAdminUser,
  deleteAdminUser,
  getAdminUsers,
  updateAdminUser,
} from "../../services/Adminuser";
import { toast } from "react-toastify";

const roleColorMap = {
  "WEB DÉVELOPPEUR": "success",
  COMPTABILITÉ: "warning",
  LOGISTIQUE: "danger",
  "R&D": "light",
  SALES: "primary",
  "": "primary",
};

const rankColorMap = {
  SÉNIOR: "info",
  JUNIOR: "info",
  GÉRANT: "info",
  ADMINISTRATEUR: "dark",
  "": "info",
};

const defaultAddUserD = {
  fName: "",
  lName: "",
  email: "",
  role: "",
  roleColor: "",
  position: "",
  positionColor: "",
};

const RolesTable = () => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [editData, setEditData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [roleFilters, setRoleFilters] = useState([]);
  const [positionFilters, setPositionFilters] = useState([]);
  const [rankFilters, setRankFilters] = useState([]);
  const [addRow, setAddRow] = useState(false);
  const [reload, setReload] = useState(false);
  const [addUserd, setAddUserd] = useState(defaultAddUserD);

  const handleDelete = (user) => {
    setEditData(null);
    setDeleteData(user);
    setAddRow(false);
  };

  const handleEdit = (user) => {
    setEditData(user);
    setDeleteData(null);
    setAddRow(false);
  };

  useEffect(() => {
    console.log("Role Filters: ", roleFilters);
    console.log("Position Filters: ", positionFilters);
    const filteredData = users.filter((user) => {
      const roleMatch =
        roleFilters.length > 0 ? roleFilters.includes(user.role) : true;
      const positionMatch =
        positionFilters.length > 0
          ? positionFilters.includes(user.position)
          : true;
      const rankFilter =
        rankFilters.length > 0 ? rankFilters.includes(user.position) : true;
      return roleMatch && positionMatch && rankFilter;
    });
    setData(filteredData);
  }, [roleFilters, positionFilters, users, rankFilters]);

  useEffect(() => {
    if (!addRow) {
      setAddUserd(defaultAddUserD);
    }
  }, [addRow]);

  useEffect(() => {
    getAdminUsers().then((res) => {
      const result = res.data.map((r) => ({
        fName: r.fname,
        lName: r.lname,
        email: r.email,
        role: r.role,
        roleColor: roleColorMap[r.role],
        position: r.rank,
        positionColor: rankColorMap[r.rank],
        _id: r._id,
      }));
      setData(result);
      setUsers(result);
    });
  }, [reload]);

  const handleAddUser = async () => {
    try {
      await addAdminUser({
        firstName: addUserd.fName,
        lastName: addUserd.lName,
        email: addUserd.email,
        role: addUserd.role,
        rank: addUserd.position,
      });
      setAddRow(false);
      setReload(!reload);
      toast.success("User added successfully.");
    } catch (err) {
      toast.error("Something went wrong, Please try again.");
    }
  };

  const handleEditUser = async (updatedUserData) => {
    try {
      await updateAdminUser(updatedUserData?._id, {
        firstName: updatedUserData.fName,
        lastName: updatedUserData.lName,
        email: updatedUserData.email,
        role: updatedUserData.role,
        rank: updatedUserData.position,
      });
      setEditData(null);
      setReload(!reload);
      toast.success("User updated successfully.");
    } catch (err) {
      toast.error("Something went wrong, Please try again.");
    }
  };

  const handleDeleteUser = async (deleteData) => {
    try {
      await deleteAdminUser(deleteData?._id);
      setDeleteData(null);
      setReload(!reload);
      toast.success("User deleted successfully.");
    } catch (err) {
      toast.error("Something went wrong, Please try again.");
    }
  };

  return (
    <div className="row">
      <div className="col-lg-9">
        <Table>
          <tbody className="roles-table">
            {addRow && (
              <tr className="edit-row">
                <td>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Box display="flex" gap={1}>
                      <InputBase
                        size="small"
                        placeholder="First Name"
                        className="form-control bg-transparent w-fit"
                        sx={{ height: 35 }}
                        onChange={({ target }) =>
                          setAddUserd({ ...addUserd, fName: target.value })
                        }
                        value={addUserd.fName}
                      />
                      <InputBase
                        size="small"
                        placeholder="Last Name"
                        className="form-control bg-transparent w-fit"
                        sx={{ height: 35 }}
                        onChange={({ target }) =>
                          setAddUserd({ ...addUserd, lName: target.value })
                        }
                        value={addUserd.lName}
                      />
                    </Box>
                    <InputBase
                      size="small"
                      placeholder="Email Address"
                      className="form-control bg-transparent w-fit-2"
                      sx={{ height: 35 }}
                      onChange={({ target }) =>
                        setAddUserd({ ...addUserd, email: target.value })
                      }
                      value={addUserd.email}
                    />
                  </Box>
                </td>
                <td>
                  {!addUserd?.role &&
                  addUserd?.position !== "ADMINISTRATEUR" ? (
                    <Badge bg="primary">SELECT</Badge>
                  ) : (
                    <Badge bg={addUserd.roleColor}>{addUserd.role}</Badge>
                  )}
                  {addUserd?.position === "ADMINISTRATEUR" && (
                    <Badge bg={addUserd.positionColor}>
                      {addUserd.position}
                    </Badge>
                  )}
                </td>
                <td>
                  {!addUserd?.position ? (
                    <Badge bg="info">SELECT</Badge>
                  ) : addUserd?.position !== "ADMINISTRATEUR" ? (
                    <Badge bg={addUserd.positionColor}>
                      {addUserd.position}
                    </Badge>
                  ) : (
                    <></>
                  )}
                </td>
                <td></td>
              </tr>
            )}
            {data.map((user, index) => (
              <tr
                key={index}
                className={
                  editData?.email === user.email
                    ? "edit-row"
                    : deleteData?.email === user.email
                    ? "delete-row"
                    : ""
                }
              >
                <td>
                  <div className="role-name">
                    {user.fName} {user.lName}
                  </div>
                  <div className="role-email">{user.email}</div>
                </td>
                <td>
                  {/* Show "Administrator" in the left column if there is no other role */}
                  {user.position === "ADMINISTRATEUR" && !user.role ? (
                    <Badge bg="dark">ADMINISTRATEUR</Badge>
                  ) : (
                    <Badge bg={user.roleColor}>{user.role}</Badge>
                  )}
                </td>
                <td>
                  {/* Keep the rank column empty if "ADMINISTRATEUR" is shown on the left */}
                  {user.position === "ADMINISTRATEUR" && !user.role ? null : (
                    <Badge bg={user.positionColor}>{user.position}</Badge>
                  )}
                </td>
                <td className="d-flex gap-3 align-items-center mt-2">
                  <EditIcon
                    height={20}
                    width={20}
                    onClick={() => handleEdit(user)}
                    role="button"
                    className="edit-ico"
                  />
                  <DeleteIcon
                    height={20}
                    width={20}
                    onClick={() => handleDelete(user)}
                    role="button"
                    className="delete-ico"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="col-lg-3">
        {editData && !deleteData && !addRow && (
          <EditUser
            data={editData}
            onClose={() => setEditData(null)}
            handleEditUser={handleEditUser}
          />
        )}
        {deleteData && !editData && !addRow && (
          <Deleteuser
            data={deleteData}
            onClose={() => setDeleteData(null)}
            handleDeleteUser={handleDeleteUser}
          />
        )}
        {!editData && !deleteData && !addRow && (
          <UserInfo
            setRoleFilters={setRoleFilters}
            roleFilters={roleFilters}
            setPositionFilters={setPositionFilters}
            positionFilters={positionFilters}
            rankFilters={rankFilters}
            setRankFilters={setRankFilters}
            setAddMode={() => setAddRow(true)}
          />
        )}
        {addRow && (
          <AddUser
            onClose={() => setAddRow(false)}
            setAddUserd={setAddUserd}
            addUserd={addUserd}
            data={addUserd}
            addUser={handleAddUser}
          />
        )}
      </div>
    </div>
  );
};

export default RolesTable;
