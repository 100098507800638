import { toast } from "react-toastify";
import axiosInstanceAuthFormData from "../../apiInstances/axiosInstanceAuthFormData";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";

export const addClientApi = async (body) => {
  try {
    const response = await axiosInstanceAuthFormData.post(
      `/customer/add`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    if (
      error?.response?.data?.msg !==
      "Email already exists with a BASIC role. Do you want to upgrade it to CLIENT role?"
    ) {
      toast.error(error?.response?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log(
        "🚀 ~ file: addclientApi.js:16 ~ addClientApi ~ error:",
        error
      );
    }
    return error?.data;
  }
};

export const updataClientApi = async (id, body) => {
  try {
    const response = await axiosInstanceAuthFormData.patch(
      `/customer/${id}`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const updataClientUserApi = async (id, body) => {
  try {
    const response = await axiosInstanceAuthFormData.patch(
      `/customer/${id}/assign-user`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const createNotes = async (id, body) => {
  try {
    const response = await axiosInstanceAuthFormData.patch(
      `/customer/${id}`,
      body
    );
    return response?.data;
  } catch (error) {
    console.log("🚀 ~ createNotes ~ error:", error);
  }
};

export const clientById = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/customer/${id}`);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteClient = async (id) => {
  try {
    const response = await axiosInstanceAuth.delete(`/customer/${id}`);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const deleteClients = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(
      `/customer/delete/bulk-delete`,
      body
    );
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const deleteReservation = async (id) => {
  try {
    const response = await axiosInstanceAuth.delete(`reservation/${id}`);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getSubscriptionByCompanylist = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `/subscription/getsubbyid/${id}`
    );
    return response.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: addclientApi.js:79 ~ getSubscriptionByCompanylist ~ error:",
      error
    );
  }
};

export const getCustomer = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/customer`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const searchCustomerApi = async (char) => {
  try {
    const response = await axiosInstanceAuthFormData.post(
      `/customer/search`,
      char
    );
    return response.data;
  } catch (error) {
    console.log(error?.response?.data?.msg);
  }
};

export const carHistoryApi = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/reserv/client/${id}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const filterReservation = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(
      `/reservation/filter/reservationdata`,
      body
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const filterReservationById = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `/reservation/filter/reservationdata/${id}`
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const addDriverApi = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`customer/adddriver`, body);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return error;
  }
};

export const customerActivationApi = async (id) => {
  try {
    const response = await axiosInstanceAuth.post(`/customer/active/${id}`);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const validateClientApi = async (id) => {
  try {
    const response = await axiosInstanceAuth.post(`/customer/bevalidate/${id}`);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const uploadDocumentApi = async (body) => {
  try {
    const response = await axiosInstanceAuthFormData.post(
      `/custdocument/add`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getDocumentApi = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/custdocument/${id}`);
    return response?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: addclientApi.js:190 ~ getDocumentApi ~ error:",
      error
    );
  }
};

export const deleteDocumentAPI = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.delete(`/custdocument/${id}`, {
      data: body,
    });
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const clientvalidateAPi = async (id) => {
  try {
    const response = await axiosInstanceAuth.post(
      `/customer/clientvalidate/${id}`
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const mainDriverList = async (id) => {
  try {
    const response = await axiosInstanceAuth.post(`/customer/mainlist/${id}`);
    return response?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: addclientApi.js:230 ~ mainDriverList ~ error:",
      error
    );
  }
};

// document api
export const addUserDocument = async (body) => {
  let loadingToastId;

  try {
    // Show loading message
    loadingToastId = toast.loading("Adding document...", {
      position: toast.POSITION.TOP_RIGHT,
    });

    // Make the API call
    const response = await axiosInstanceAuth.post(`/custdocument/add`, body);

    // Update loading toast to success message
    toast.update(loadingToastId, {
      render: response?.data?.msg,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 5000,
    });

    return response?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: addclientApi.js:254 ~ addUserDocument ~ error:",
      error
    );

    // Update loading toast to error message
    if (loadingToastId) {
      toast.update(loadingToastId, {
        render: "Failed to add document",
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 5000,
      });
    } else {
      toast.error("Failed to add document", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
};

export const getUserDocument = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/custdocument/${id}`);
    return response?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: addclientApi.js:263 ~ getUserDocument ~ error:",
      error
    );
  }
};

export const updateUserDocument = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.patch(`/custdocument/${id}`, body);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: addclientApi.js:275 ~ updateUserDocument ~ error:",
      error
    );
  }
};

export const userDocumentDelete = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.delete(`custdocument/${id}`, {
      data: body,
    });
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: addclientApi.js:294 ~ userDocumentDelete ~ error:",
      error
    );
  }
};

export const pdfPageRemove = async (id, body) => {
  console.log("🚀 ~ file: addclientApi.js:335 ~ pdfPageRemove ~ body:", body);
  try {
    const response = await axiosInstanceAuth.delete(
      `custdocument/pagewise/${id}`,
      {
        data: body,
      }
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: addclientApi.js:294 ~ userDocumentDelete ~ error:",
      error
    );
  }
};

export const invoiceUpdate = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.patch(
      `invoice/update_invoice/${id}`,
      body
    );
    return response?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: addclientApi.js:294 ~ userDocumentDelete ~ error:",
      error
    );
  }
};
