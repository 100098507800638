import React from "react";
import {
  mainAndClientRoleClass,
  roleClasses,
  subscriptionStatusClass,
} from "../../Common/roles";

const RoleAndsubscriptionname = ({
  roles,
  subscription_name,
  beclient,
  contactFilled,
}) => {
  if (
    roles?.includes("MAIN") &&
    roles?.includes("CLIENT") &&
    subscription_name
  ) {
    return (
      <>
        {roles?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item !== "MAIN" && (
                <span className={`${mainAndClientRoleClass[item]}`}>
                  {item?.charAt(0).toUpperCase()}
                </span>
              )}
            </React.Fragment>
          );
        })}

        <span className={`ms-1 ${subscriptionStatusClass[subscription_name]}`}>
          {subscription_name}
        </span>
      </>
    );
  } else if (roles?.includes("MAIN") && subscription_name) {
    return (
      <span className={`${subscriptionStatusClass[subscription_name]}`}>
        {subscription_name}
      </span>
    );
  } else if (roles?.includes("CLIENT")) {
    return (
      <>
        {roles.map((role, i) => (
          <span key={i} className={`fs-13 ${roleClasses[role]}`}>
            {role}
          </span>
        ))}
      </>
    );
  } else {
    return (
      <>
        {roles.map((role, i) => (
          <span key={i} className={`fs-13 ${roleClasses[role]}`}>
            {role}
          </span>
        ))}
        {beclient === "true" && (
          <span className="bg-success rounded px-2 c-badge">
            <i
              className="menu-icon text-white tf-icons ti ti-checkup-list m-0 fs-5"
              type="button"
            />
          </span>
        )}
        {beclient === "false" && contactFilled && (
          <span className="bg-warning rounded px-2 c-badge">
            <i
              className="menu-icon text-white tf-icons ti ti-file m-0 fs-5"
              type="button"
            />
          </span>
        )}
      </>
    );
  }
};
export default RoleAndsubscriptionname;
