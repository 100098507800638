/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { Dropdown } from "primereact/dropdown";
import {
  deleteClient,
  getSubscriptionByCompanylist,
} from "../services/client/addclientApi";
import { ReactComponent as PlusIcon } from "../assets/img/icons/fleet/plus.svg";
import { ReactComponent as FilterIcon } from "../assets/img/icons/fleet/filter.svg";
import { ReactComponent as SettingIcon } from "../assets/img/icons/fleet/settings.svg";
import { ReactComponent as DotsVertIcon } from "../assets/img/icons/fleet/dots-vertical.svg";
import { ReactComponent as UserPlusIcon } from "../assets/icons/user-plus.svg";
import { ReactComponent as ReportSearchIcon } from "../assets/icons/report-search.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  alphabeticalClient,
  fetchAllUserList,
  fetchcompanyDropdown,
  searchClientUserList,
} from "../Redux/commonSlice";
import { Loader } from "../components/Common/Loader";
import {
  BsCheck,
  BsChevronLeft,
  BsChevronRight,
  BsChevronUp,
  BsFunnel,
  BsTrash,
  BsX,
  BsXLg,
} from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../components/Common/DeleteModal";
import AddClient from "../components/Client/AddClient";
import { pagination } from "../components/Common/Pagination";
import TableFooter from "../components/Common/TableFooter";
import {
  mainAndClientRoleClass,
  roleClasses,
  statusClass,
  subscriptionStatusClass,
} from "../components/Common/roles";

const Client = () => {
  const { permissions } = useSelector((state) => state.userSlice);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addShow, setAddShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [clientId, setclientId] = useState(null);
  const [isSubscriptionValue, setSubscriptionValue] = useState([]);
  const [itemsPerPage, setitemsPerPage] = useState("25");
  const [currentPage, setCurrentPage] = useState(0);
  const [initialValue, setInitialValue] = useState({
    search: "",
    company: "",
    subscription: "",
  });
  const [showFilters, setShowFilters] = useState(false);
  const [filterOpts, setFilterOpts] = useState([]);
  const [filterKey, setFilterKey] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [reload, setReload] = useState(false);

  const { companyDropdown, clientList, tempClientList, loading } = useSelector(
    (state) => state?.commonSlice
  );

  // pagination start
  const clientListData = pagination(clientList, currentPage, itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  // pagination end

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async (id) => {
    const subscriptionValue = await getSubscriptionByCompanylist(id);

    if (subscriptionValue !== undefined) {
      setSubscriptionValue(subscriptionValue?.data);
    } else {
      setSubscriptionValue([]);
    }
  };
  // subscriptionDropdownValue end

  const aTozSorting = (value) => {
    const tempData = tempClientList.map((item) => ({
      ...item,
      member: item?.comp_id?.name,
    }));
    const data = tempData
      ?.slice()
      .sort((a, b) => a?.[value]?.localeCompare(b?.[value]));
    dispatch(alphabeticalClient(data));
  };

  const zToaSorting = (value) => {
    const tempData = tempClientList.map((item) => ({
      ...item,
      member: item?.comp_id?.name,
    }));
    const data = tempData
      ?.slice()
      .sort((a, b) => b?.[value]?.localeCompare(a?.[value]));
    dispatch(alphabeticalClient(data));
  };

  const handleEditClick = (id) => {
    navigate(`/client/profile/${id}`);
  };

  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };

  const handleDelete = async (id) => {
    const form = new FormData();
    form.append("role", "CLIENT");
    await deleteClient(id);
    dispatch(searchClientUserList(form));
    setModalShow(false);
  };

  const addMainDriverCouting = (data) => {
    const counting = data?.map((item) => item?.MAIN);
    return `${counting?.length > 1 ? "2" : "0"}+`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentPage(0);
    setInitialValue((prev) => {
      const updatedFilters = { ...prev, [name]: value };
      return updatedFilters;
    });
  };

  useEffect(() => {
    dispatch(fetchcompanyDropdown());
  }, []);

  // filter data search
  useEffect(() => {
    const form = new FormData();
    if (initialValue?.search) form.append("searchname", initialValue?.search);
    if (initialValue?.company?._id)
      form.append("comp_id", initialValue?.company?._id);
    if (initialValue?.subscription?._id)
      form.append("sub_id", initialValue?.subscription?._id);
    form.append("role", "CLIENT");
    const debounceTimeout = setTimeout(() => {
      dispatch(searchClientUserList(form));
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [initialValue, reload]);

  const filters = [
    { key: "company", label: "Société" },
    { key: "subscription", label: "Abonnement" },
  ];

  const toggleFilters = () => {
    setShowFilters(!showFilters);
    setFilterKey("");
    setFilterOpts([]);
    // setProspectSearch(false);
  };

  const toggleFilterOpts = (key) => {
    const opts = {
      company: companyDropdown,
      subscription: isSubscriptionValue,
    };
    toggleFilters();
    setFilterKey(key);
    setFilterOpts(opts[key]);
  };

  const toggleDeleteConfirmation = () =>
    setDeleteConfirmation(!deleteConfirmation);

  const deleteSelectedRows = async () => {
    try {
      for (const row of selectedRows) {
        await handleDelete(row);
      }
      setReload(!reload);
      toggleDeleteConfirmation();
      setSelectedRows([]);
    } catch {}
  };

  return (
    <div className="container-fluid flex-grow-1 container-p-y client">
      {/* <BreadCrumbs title={"Financier"} subTitle={"Clients"} /> */}
      <div className="card">
        {showFilters && (
          <div className="d-flex gap-3 p-2">
            {filters.map((filt) => (
              <button
                className="btn fleet-new-filt"
                onClick={() => toggleFilterOpts(filt.key)}
              >
                {filt.label} <BsChevronRight />
              </button>
            ))}
          </div>
        )}

        {filterOpts?.length > 0 && (
          <div className="d-flex gap-3 align-items-center  p-2 ">
            <div className="d-flex gap-3 fleet-new-filt-opt-container">
              <button className="btn fleet-new-filt-opt">
                {filters.find((r) => r.key === filterKey)?.label}
              </button>
              {filterOpts.map((filt) => {
                const optVal =
                  typeof filt === "string" ? filt : filt?.name || filt?.label;
                return (
                  <button
                    className={`btn fleet-new-filt-opt ${
                      (typeof initialValue[filterKey] === "string"
                        ? initialValue[filterKey]
                        : initialValue[filterKey]?.name ||
                          initialValue[filterKey]?.label) === optVal
                        ? `fleet-new-filt-opt-active`
                        : ``
                    }`}
                    onClick={() =>
                      handleChange({
                        target: {
                          name: filterKey,
                          value: filt,
                        },
                      })
                    }
                  >
                    {optVal}
                  </button>
                );
              })}
            </div>
            <button
              className="btn fleet-new-filt-opt-clear"
              onClick={toggleFilters}
            >
              <BsChevronLeft />
            </button>
          </div>
        )}
        <div className="d-flex align-items-center white-bg-card-filt">
          <input
            placeholder="Rechercher..."
            className="form-control me-2"
            style={{ width: "200px" }}
            type="text"
            name="search"
            onChange={handleChange}
            value={initialValue?.search}
          />
          <button className="btn p-2 plus-ic" onClick={() => setAddShow(true)}>
            <PlusIcon />
          </button>

          <button
            data-type="filter"
            className={`btn p-2 ${showFilters ? `active-action` : ``}`}
            onClick={toggleFilters}
          >
            <BsFunnel style={{ fontSize: "20px" }} />
          </button>
          <button data-type="setting" className="btn p-2">
            <SettingIcon />
          </button>
          <button data-type="filter" className="btn p-2">
            <DotsVertIcon />
          </button>
          <div className="d-flex gap-2">
            {Object.values(initialValue).length > 0 &&
              Object.entries(initialValue).map(
                (k) =>
                  k[1] && (
                    <div
                      className="selected-filt"
                      role="button"
                      onClick={() => {
                        setInitialValue({
                          ...setInitialValue,
                          [k[0]]: "",
                        });
                      }}
                    >
                      {typeof k[1] === "string"
                        ? k[1]
                        : k[1]?.name || k[1]?.label}{" "}
                      <BsXLg />
                    </div>
                  )
              )}

            {selectedRows.length > 0 && !deleteConfirmation && (
              <button
                className="btn btn-primary  p-2"
                onClick={toggleDeleteConfirmation}
              >
                <BsTrash />
                &nbsp;&nbsp; Supprimer
              </button>
            )}
            {selectedRows.length > 0 && deleteConfirmation && (
              <div className="d-flex gap-2  p-2">
                <button className="btn btn-danger" onClick={deleteSelectedRows}>
                  <BsCheck />
                  &nbsp;&nbsp; Valider
                </button>
                <button
                  className="btn btn-default"
                  onClick={toggleDeleteConfirmation}
                >
                  <BsX />
                  &nbsp;&nbsp; Annuler
                </button>
              </div>
            )}
          </div>
        </div>

        {/* table start  */}
        <div className="new-table mt-3">
          <div className=" table-responsive">
            <table className="table  table-hover">
              <thead className="border-top">
                <tr>
                  <th>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={(e) =>
                        setSelectedRows(
                          !e.target.checked
                            ? []
                            : clientListData.map((r) => r._id)
                        )
                      }
                      checked={selectedRows.length === clientListData.length}
                    />
                  </th>
                  <th># </th>
                  <th>
                    <span className="position-relative top-4">STATUS</span>
                    <div className="d-inline-block item-center">
                      <span
                        className="d-flex flex-column ps-1"
                        style={{ opacity: "0" }}
                      >
                        <BsChevronUp fontSize={12} />
                        <BsChevronDown fontSize={12} />
                      </span>
                    </div>
                  </th>

                  <th>
                    <span className="position-relative top-4">NOM</span>
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1">
                        <BsChevronUp
                          fontSize={12}
                          onClick={() => aTozSorting("firstname")}
                        />
                        <BsChevronDown
                          fontSize={12}
                          onClick={() => zToaSorting("firstname")}
                        />
                      </span>
                    </div>
                  </th>
                  <th>
                    <span className="position-relative top-4">
                      Type de client
                    </span>
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1">
                        <BsChevronUp
                          fontSize={12}
                          onClick={() => aTozSorting("cust_type")}
                        />
                        <BsChevronDown
                          fontSize={12}
                          onClick={() => zToaSorting("cust_type")}
                        />
                      </span>
                    </div>
                  </th>
                  <th>
                    <span className="position-relative top-4"> E-MAIL</span>
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1">
                        <BsChevronUp
                          fontSize={12}
                          onClick={() => aTozSorting("email")}
                        />
                        <BsChevronDown
                          fontSize={12}
                          onClick={() => zToaSorting("email")}
                        />
                      </span>
                    </div>
                  </th>

                  <th>
                    <span className="position-relative top-4">MEMBRE</span>
                    <div className="d-inline-block item-center">
                      <span className="d-flex flex-column ps-1">
                        <BsChevronUp
                          fontSize={12}
                          onClick={() => aTozSorting("member")}
                        />
                        <BsChevronDown
                          fontSize={12}
                          onClick={() => zToaSorting("member")}
                        />
                      </span>
                    </div>
                  </th>
                  <th>ACTIONS </th>
                </tr>
              </thead>
              <tbody>
                {clientListData?.length > 0 &&
                  clientListData?.map((customer) => {
                    return (
                      <tr
                        className="cursor-pointer"
                        key={customer?.index}
                        onClick={() => {
                          handleEditClick(customer._id);
                        }}
                      >
                        <td onClick={(e) => e.stopPropagation()}>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={selectedRows.includes(customer._id)}
                            onChange={(e) => {
                              setSelectedRows(
                                !e.target.checked
                                  ? selectedRows.filter(
                                      (r) => r !== customer._id
                                    )
                                  : [...selectedRows, customer._id]
                              );
                            }}
                          />
                        </td>
                        <td>{customer?.index}</td>
                        <td>
                          <div className="text-nowrap d-flex gap-2">
                            {customer?.role?.length > 1 ? (
                              customer?.role?.map((item) => {
                                return (
                                  <>
                                    {item !== "MAIN" && (
                                      <span
                                        className={`${mainAndClientRoleClass[item]}`}
                                      >
                                        {item?.charAt(0).toUpperCase()}
                                      </span>
                                    )}
                                  </>
                                );
                              })
                            ) : (
                              <div className="d-inline-block">
                                <span
                                  className={`me-1 ${
                                    roleClasses[customer?.role?.[0]]
                                  }`}
                                >
                                  {customer?.role?.[0]}
                                </span>
                              </div>
                            )}

                            {customer?.sub_id?.subscription_name && (
                              <>
                                {customer?.connectedCustomers?.length > 1 ? (
                                  <span className="btn btn-label-primary waves-effect p-1">
                                    {addMainDriverCouting(
                                      customer?.connectedCustomers
                                    )}
                                  </span>
                                ) : (
                                  <span
                                    className={
                                      subscriptionStatusClass[
                                        customer?.sub_id?.subscription_name
                                      ]
                                    }
                                  >
                                    {customer?.sub_id?.subscription_name}
                                  </span>
                                )}

                                {customer?.connectedCustomers?.length > 0 &&
                                  customer?.connectedCustomers?.map((item) => (
                                    <>
                                      {item?.sub_id?.subscription_name && (
                                        <span
                                          className={`ms-1 ${
                                            statusClass[customer?.role?.[0]]
                                          }`}
                                        >
                                          {item?.sub_id?.subscription_name}
                                        </span>
                                      )}
                                    </>
                                  ))}
                              </>
                            )}
                          </div>
                        </td>
                        <td className="text-nowrap cursor-pointer">
                          {customer?.cust_type === "Entreprise"
                            ? customer?.business
                            : `${customer?.firstname} ${customer?.lastname}`}
                        </td>
                        <td className="text-nowrap">{customer?.cust_type}</td>
                        <td>{customer?.email}</td>
                        <td className="text-nowrap">
                          {customer?.comp_id?.name}
                        </td>

                        <td>
                          <i
                            onClick={() => {
                              handleEditClick(customer._id);
                            }}
                            className="menu-icon tf-icons ti ti-edit"
                            type="button"
                          />
                          <i
                            onClick={(e) => {
                              e.stopPropagation();
                              setclientId(customer?._id);
                              setModalShow(true);
                            }}
                            className="menu-icon tf-icons  ti ti-sm ti-trash"
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {clientListData.length > 0 && (
          <TableFooter
            itemsPerPage={itemsPerPage}
            handleSelectChange={handleSelectChange}
            listData={clientList}
            handlePageChange={handlePageChange}
          />
        )}

        {/* add client start  */}
        <AddClient
          show={addShow}
          onHide={() => setAddShow(false)}
          setclientId={setclientId}
        />
        {/* add client end  */}

        {/* modal start */}
        <DeleteModal
          title={"Supprimer Cette Client"}
          description={
            "Etes-vous sur de vouloir supprimer ce client et tout les conducteurs liés?"
          }
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteData={() => handleDelete(false)}
        />
        {/* modal end */}

        {!loading && (clientList?.length === 0 || !clientList) && (
          <div className="text-center my-4 mx-auto">No Data Found</div>
        )}

        {loading && (
          <div className="card-body">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Client;
