import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { CiEraser } from "react-icons/ci";
import SignatureCanvas from "react-signature-canvas";
import {
  addVehicleDeliveryInfo,
  deliveryDriverUpdate,
} from "../../../../services/Deliveries";
import { checkStatusUpdate } from "../../../../services/check";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDeliveryNew,
  setLocationModal,
  setModalVerification,
} from "../../../../Redux/deliverySlice";

const Signature = ({
  show,
  onHide,
  selectedResv,
  value,
  newKmValue,
  selectAuxIds,
}) => {
  const sigCanvas = useRef({});
  const [signatureURL, setSignatureURL] = useState(null);
  const navigate = useNavigate();
  let dispatch = useDispatch();

  const [isClientPresent, setIsClientPresent] = useState(false);
  let ststus =
    selectedResv && selectedResv.reservation_status
      ? selectedResv.reservation_status
      : null;

  const toggleClientPresence = () => {
    setIsClientPresent(!isClientPresent);
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignatureURL(null);
  };

  const saveSignature = () => {
    const signature = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setSignatureURL(signature);
    console.log("Signature captured:", signature);

    saveStatus(signature);
    dispatch(setLocationModal(false));
  };

  const saveStatus = async (sign) => {
    let type =
      ststus &&
      (ststus === "ADDRESSVALID" ||
        ststus === "DELIVERYOUT" ||
        ststus === "ADDRESSNOTVALID")
        ? "OUT"
        : "IN";

    const payload = {
      reservation_id: selectedResv?._id,
      general_id: selectedResv?.generalId,
      kilometer: parseInt(newKmValue),
      fuel_level: parseInt(value),
      delivery_type: type,
      is_client_present_yn: isClientPresent,
      signature: sign,
    };

    // Logging each field to confirm values
    console.log("Payload being sent to addVehicleDeliveryInfo:");
    console.log("Reservation ID:", payload.reservation_id);
    console.log("General ID:", payload.general_id);
    console.log("Kilometer:", payload.kilometer);
    console.log("Fuel Level:", payload.fuel_level);
    console.log("Delivery Type:", payload.delivery_type);
    console.log("Is Client Present:", payload.is_client_present_yn);
    console.log("Signature:", payload.signature);

    // Check for missing fields
    if (
      !payload.reservation_id ||
      !payload.general_id ||
      isNaN(payload.kilometer) ||
      isNaN(payload.fuel_level) ||
      !payload.signature
    ) {
      console.error("Error: One or more required fields are missing.");
      return;
    }

    try {
      let res = await addVehicleDeliveryInfo(payload);
      console.log("Response from addVehicleDeliveryInfo:", res);
      if (res?.status) {
        const statusPayload = {
          reservationId: selectedResv._id,
          reservation_status:
            ststus === "DELIVERYIN" ? "CHECKIN" : "DELIVERYIN",
        };

        if (ststus !== "DELIVERYIN" && selectAuxIds && selectAuxIds.length) {
          const auxDriverAdd = await deliveryDriverUpdate({
            reservationId: selectedResv._id,
            reservation_status: "DELIVERYOUT",
            linked_driver_details: selectAuxIds,
          });
          console.log("Aux driver update response:", auxDriverAdd);
        }

        const checkStatus = await checkStatusUpdate(statusPayload);
        console.log("Response from checkStatusUpdate:", checkStatus);

        if (checkStatus) {
          dispatch(setModalVerification(false));
          dispatch(fetchDeliveryNew());
          onHide();
        }
      } else {
        console.error("Error in addVehicleDeliveryInfo:", res?.msg);
      }
    } catch (error) {
      console.error("Exception in saveStatus:", error);
    }
  };

  return (
    <Modal show={show} fullscreen onHide={onHide}>
      <Modal.Header className="border-0 bg-primary text-white signaturerHeader">
        <IoClose size={25} onClick={onHide} />
        <div className="w-100 fs-5 text-center">
          SORTIE (LIVRAISON) - Client Name
        </div>
      </Modal.Header>
      <Modal.Body>
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{ className: "w-100 signatureWindow" }}
        />
        <div className="d-flex justify-content-end">
          <CiEraser
            size={40}
            className="text-primary rounded-5 border p-2 border-primary btn"
            onClick={clearSignature}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 p-0">
        <Button
          variant="primary"
          className="w-100 fs-4 m-0 validateSign-Button-container"
        >
          <div
            className="validateSign-toggle-container"
            onClick={toggleClientPresence}
          >
            <div
              className={`toggle-button ${
                isClientPresent ? "present" : "absent"
              }`}
            >
              <div
                className={`toggle-circle ${
                  isClientPresent ? "present" : "absent"
                }`}
              ></div>
            </div>
            <span className="toggle-text ms-1">
              {isClientPresent ? "Client Present" : "Client Non Present"}
            </span>
          </div>

          <span
            className="validateSign-ValidBtn text-center w-100"
            onClick={saveSignature}
          >
            Valider
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Signature;
