import "./css/dropdown.css";
import "./css/client.css";
import "./css/setting.css";
import "./assets/css/demo.css";
import "./css/log-in.css";
import "./css/nav-pills.css";
import "./css/document.css";
import "./assets/vendor/css/pages/app-academy-details.css";
import "./assets/vendor/css/pages/app-academy.css";
import "./assets/vendor/css/pages/app-calendar.css";
import "./assets/vendor/css/pages/app-chat.css";
import "./assets/vendor/css/pages/app-ecommerce.css";
import "./assets/vendor/css/pages/app-email.css";
import "./assets/vendor/css/pages/app-invoice.css";
import "./assets/vendor/css/pages/app-kanban.css";
import "./assets/vendor/css/pages/app-logistics-dashboard.css";
import "./assets/vendor/css/pages/app-logistics-fleet.css";
import "./assets/vendor/css/pages/cards-advance.css";
import "./assets/vendor/css/pages/page-account-settings.css";
import "./assets/vendor/css/pages/page-auth.css";
import "./assets/vendor/css/pages/page-faq.css";
import "./assets/vendor/css/pages/page-icons.css";
import "./assets/vendor/css/pages/page-misc.css";
import "./assets/vendor/css/pages/page-pricing.css";
import "./assets/vendor/css/pages/page-profile.css";
import "./assets/vendor/css/pages/page-user-view.css";
import "./assets/vendor/css/pages/ui-carousel.css";
import "./assets/vendor/fonts/flag-icons.css";
import "./assets/vendor/fonts/fontawesome.css";
import "./assets/vendor/fonts/tabler-icons.css";
import "./assets/vendor/css/rtl/core.css";
import "./assets/vendor/css/rtl/theme-default.css";
import "./assets/vendor/libs/dropzone/dropzone.css";
import "./assets/vendor/libs/spinkit/spinkit.css";
import "./css/dark.scss";
import "./css/colors.css";
