/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import uploadIcon from "../../../../assets/icons/upload.svg";
import {
  addVehiDocument,
  deleteVehiDocument,
  getVehiDocument,
  updateVehiDocument,
} from "./../../../../services/AddVehicle/index";
import { FileUploader } from "react-drag-drop-files";
import { Field, Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import DeleteModal from "../../../Common/DeleteModal";
import FeeltDoumentPdf from "../Components/Document/FeeltDoumentPdf";
import { formatDate } from "../../../Common/FormatDate";
import { Status } from "./../../../Common/DropdownValue";

const FleetDocument = () => {
  const { id } = useParams();
  const [isDocuments, setIsDocuments] = useState([]);
  const [isDocumentsId, setIsDocumentsId] = useState("");
  const [isActiveDocument, setActiveDocument] = useState({});
  const [modalShow, setModalShow] = useState("");
  const [isVehiDocumentId, setVehiDocumentId] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));

  // img upload
  const handleUploadImg = async (file, id) => {
    const form = new FormData();
    form.append("general_info", id);
    form.append("firstname", userInfo?.fname);
    form.append("lastname", userInfo?.lname);
    form.append("name", file?.name);
    form.append("document", file);
    const data = await addVehiDocument(form);

    if (data?.status) {
      setActiveDocument(data?.documents?.[0]);
      if (isDocuments?.length > 0) {
        setIsDocuments((prev) => [...prev, { ...data?.documents?.[0] }]);
      } else {
        setIsDocuments([data?.documents?.[0]]);
      }
    }
  };

  // getVehiDocument
  const getImage = async (id) => {
    const uploadedImg = await getVehiDocument(id);
    setVehiDocumentId(uploadedImg?.data?._id);
    const data = uploadedImg?.data?.documents.flatMap((item) => item);
    setIsDocuments(data);
    return data;
  };

  //  delete document
  const handleDelete = async () => {
    const body = { doc_id: isActiveDocument?._id };
    await deleteVehiDocument(isVehiDocumentId, body);
    getImage(id);
    setModalShow(false);
    setActiveDocument({});
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const docData = await getImage(id);
        if (docData) {
          setActiveDocument(docData[0]);
        }
      }
    };

    fetchData();
  }, [id]);

  const statusChange = async (e, item) => {
    const status = e.target.checked ? 1 : 0;
    const form = new FormData();
    form.append("status", status);
    form.append("doc_id", item?._id);
    await updateVehiDocument(id, form);
    getImage(id);
    setActiveDocument(item);
    setIsDocumentsId("");
  };

  return (
    <div className="row documents">
      <div className="col-3">
        {isDocuments?.length > 0 &&
          isDocuments?.map((item) => (
            <Formik
              key={item?._id}
              initialValues={{
                name: item?.name?.split(".")[0] || "",
                fileType: item?.name?.split(".")[1] || "",
                status: item?.status || false,
              }}
              enableReinitialize={true}
              onSubmit={async (values) => {
                const form = new FormData();
                form.append("name", values?.name + "." + values?.fileType);
                form.append("doc_id", item?._id);
                await updateVehiDocument(id, form);
                setIsDocumentsId("");
              }}
            >
              {({ values, handleChange }) => (
                <Form onClick={() => setActiveDocument(item)}>
                  <div
                    className={`card cursor-pointer border-left-3 mb-2 border-right-3 ${
                      item?._id === isActiveDocument?._id && "doc-active"
                    }`}
                  >
                    <div className="row align-items-center px-3 py-2 ">
                      <div className="col-9">
                        {isDocumentsId === item?._id ? (
                          <div className="d-flex align-items-center">
                            <Field
                              readOnly={isDocumentsId !== item?._id}
                              name="name"
                              className={`cursor-pointer flex-grow-0 ${
                                isDocumentsId === item?._id
                                  ? "form-control mb-1 "
                                  : "hide-input"
                              }`}
                              placeholder=""
                            />
                            .<div>{values?.fileType}</div>
                          </div>
                        ) : (
                          <div className="file-name">
                            {values?.name}
                            {values?.fileType && "." + values?.fileType}
                          </div>
                        )}

                        <div className="d-flex fs-7">
                          {(item?.firstname || item?.lastname) && (
                            <span className="me-2 file-name">
                              {item?.firstname} {item?.lastname}
                            </span>
                          )}
                          <span className="file-name">
                            {formatDate(item?.uploadedAt)}
                          </span>
                        </div>
                      </div>

                      <div className=" col-3">
                        <div className="d-flex gap-2 justify-content-end align-items-center">
                          <div>
                            {isDocumentsId === item?._id ? (
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light px-2 py-1"
                              >
                                Save
                              </button>
                            ) : (
                              <span
                                onClick={() => setIsDocumentsId(item?._id)}
                                className="tf-icons ti-xs ti ti-edit d-block mb-2"
                              ></span>
                            )}
                            <span
                              onClick={() => setModalShow(true)}
                              className="tf-icons ti-xs ti ti-trash d-block text-center"
                            ></span>
                          </div>
                          <div>
                            <Field
                              type="checkbox"
                              name="status"
                              className="cursor-pointer"
                              checked={values.status}
                              onChange={(e) => {
                                handleChange(e);
                                statusChange(e, item);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ))}

        {/* upload file  */}
        <div className="position-relative">
          <FileUploader
            label={"Ajouter un document"}
            handleChange={(file) => handleUploadImg(file, id)}
            name="file"
            types={["pdf", "JPG", "PNG", "GIF", "webp"]}
            customMessage={"Uploaded Successfully"}
            className="file-name"
          />
          <div className="p-2 rounded drag-upload-icon">
            <img src={uploadIcon} alt="upload-icons" />
          </div>
        </div>
      </div>

      {/* img and pdf show  */}
      <div className="col-9">
        <div className="card">
          <div className="card-body">
            {isActiveDocument?.url?.includes("pdf") ? (
              <FeeltDoumentPdf
                isActiveDocument={isActiveDocument}
                setActiveDocument={setActiveDocument}
                getImage={getImage}
                handleDocDelete={handleDelete}
              />
            ) : isActiveDocument?.url ? (
              <img
                src={`${process.env.REACT_APP_IMG_URL}${isActiveDocument?.url}`}
                alt=""
                className="img-fluid"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <DeleteModal
        title={"Supprimer ce document"}
        description={"Êtes-vous sûr de supprimer le document ?"}
        show={modalShow}
        deleteData={() => handleDelete(false)}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default FleetDocument;
