import { configureStore } from "@reduxjs/toolkit";
import commonSlice from "./commonSlice";
import vehicleSlice from "./vehicleSlice";
import invoiceSlice from "./invoiceSlice";
import calanderSlice from "./calanderSlice";
import clientsSlice from "./clientsSlice";
import checkSlice from "./checkSlice";
import deliverySlice from "./deliverySlice";
import profileInviceSlice from "./profileInviceSlice";
import userSlice from "./userSlice";
import colorSlice from "./colorSlice";

const store = configureStore({
  reducer: {
    clientsSlice: clientsSlice,
    commonSlice: commonSlice,
    vehicleSlice: vehicleSlice,
    invoiceSlice: invoiceSlice,
    calanderSlice: calanderSlice,
    checkSlice: checkSlice,
    deliverySlice: deliverySlice,
    profileInviceSlice: profileInviceSlice,
    userSlice: userSlice,
    colorSlice: colorSlice,
  },
});

export default store;
