import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../Common/Loader";
import LocationCheck from "../DeliveryVerification/LocationCheck";
import { getReservationById } from "../../../services/Calendar/reservation";
import axiosInstanceAuth from "../../../apiInstances/axiosInstanceAuth";
import { toast } from "react-toastify";
import {
  fetchDeliveryNew,
  setLocationModal,
  setModalVerification,
  setisFromAnnotatePage,
  setselectedResv,
} from "../../../Redux/deliverySlice";
import DeliveryListItem from "./DeliveryListItem";
import ValidateModalWindow from "../DeliveryVerification/ValidateModalWindow";

const DeliveryOutList = () => {
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.userSlice);

  // const [show, setShow] = useState(false);
  let [stepDetail, setStepDetail] = useState(null);

  const [expandedItemId, setExpandedItemId] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  const [address, setAddress] = useState("Address not found");
  // let [selectedResv, setSelectedResv] = useState(null);
  // const { loading } = useSelector((state) => state?.deliverySlice);

  const { deliveryOutListData, loading, show, showLocatiom, selectedResv } =
    useSelector((state) => state?.deliverySlice);

  const handleCloseModal = () => {
    // setShowModal(false);
    dispatch(setLocationModal(false));
  };

  const handleLocationCheck = (
    e,
    address,
    item,
    manufacturer,
    model,
    dontopenAddress
  ) => {
    e.stopPropagation();
    if (!dontopenAddress) {
      // setShow(true);
      dispatch(setModalVerification(true));
      dispatch(setselectedResv(item));
      // setShowModal(true);
      dispatch(setLocationModal(true));

      setAddress(address);
      return;
    }
    setAddress(address);
    dispatch(setselectedResv(item));
    // setShowModal(true);
    dispatch(setLocationModal(true));
  };

  const handleCloseValidateModal = () => {
    // setShow(false);
    dispatch(setModalVerification(false));
    // setShowModal(false);
    dispatch(setisFromAnnotatePage(false));
    dispatch(setLocationModal(false));
    // setShowModal(false)
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchDeliveryNew());
    };

    fetchData();
  }, [dispatch]);

  const toggleExpand = (id) => {
    setExpandedItemId(expandedItemId === id ? null : id);
  };

  // When no list permission assigned
  if (!permissions.includes("LIVRAISONS.LIST")) return <></>;

  return (
    <>
      <div className="client delieveryContainer px-2">
        <div className="table-responsive text-nowrap">
          {deliveryOutListData &&
            deliveryOutListData.map((item) => (
              <DeliveryListItem
                item={item}
                key={item._id}
                toggleExpand={toggleExpand}
                expandedItemId={expandedItemId}
                handleLocationCheck={handleLocationCheck}
              />
            ))}

          {loading && (
            <div className="mt-4">
              <Loader />
            </div>
          )}

          <LocationCheck
            showModal={showLocatiom}
            // setShowModal={setShowModal}
            address={address}
            setAddress={setAddress}
            selectedResv={selectedResv}
          />

          {show && (
            <ValidateModalWindow
              handleCloseModal={handleCloseModal}
              stepDetail={stepDetail}
              selectedResv={selectedResv}
              address={address}
              show={show}
              handleCloseValidateModal={handleCloseValidateModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DeliveryOutList;
