/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerData, loader } from "../../Redux/clientsSlice";
import BreadCrumbs from "../Common/BreadCrumbs";
import CustomerActivationModale from "../Common/CustomertActivationModale";
import ClientDetails from "./Components/ClientDetails";
import ConnectedCustomers from "./Components/ConnectedCustomers";
import EnterpriseDetails from "./Components/EnterpriseDetails";
import PrivateDetails from "./Components/PrivateDetails";
import AddDriverModal from "./Modals/AddDriverModal";
import ClientNavbar from "./layout/ClientNavbar";
import { useParams } from "react-router-dom";
import { Loader } from "../Common/Loader";

const ClientInfo = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isModelShow, setModelShow] = useState(false);
  const [isEdit, setEdit] = useState(true);
  const [isLoding, setLoding] = useState(false);
  const [modulEdit, setModuleEdit] = useState(false);
  const { customerData, loading } = useSelector((state) => state?.clientsSlice);

  useEffect(() => {
    dispatch(loader(true));
    dispatch(fetchCustomerData(id));
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          {/* <BreadCrumbs title={"Customer"} subTitle={"Client Profile"} /> */}
          {!loading ? (
            <div className="row">
              <div className="col-xl-4 col-xxl-3">
                <div className="card mb-2">
                  <div className="card-body p-3">
                    <div className="d-flex justify-content-between align-content-center">
                      <h6 className="card-text my-auto">
                        Accounts Information
                      </h6>
                      {customerData?.customer?.role?.length > 1 && (
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={(e) => {
                            setModuleEdit(true);
                          }}
                        >
                          <i className="ti ti-plus me-sm-1 ti-xs"></i> Add
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {customerData?.customer?.role.length === 1 &&
                  customerData?.customer?.cust_type === "Entreprise" && (
                    <ClientDetails
                      customerData={customerData}
                      singleClientValue={customerData}
                    />
                  )}

                <ConnectedCustomers customerData={customerData} id={id} />

                {customerData?.customer?.cust_type === "Entreprise" ? (
                  <EnterpriseDetails
                    customerData={customerData}
                    setLoding={setLoding}
                    id={id}
                    setEdit={setEdit}
                    isEdit={isEdit}
                    isLoding={isLoding}
                  />
                ) : customerData?.customer.role?.length === 1 ? (
                  <PrivateDetails
                    customerData={customerData}
                    setLoding={setLoding}
                    id={id}
                    setEdit={setEdit}
                    isEdit={isEdit}
                    isLoding={isLoding}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-xl-8 col-xxl-9">
                {/*Navbar start*/}
                <ClientNavbar />
              </div>

              {modulEdit && (
                <AddDriverModal
                  show={modulEdit}
                  onHide={() => setModuleEdit(false)}
                  title={"Supprimer Cette Société"}
                  clientId={id}
                />
              )}

              {isModelShow && (
                <CustomerActivationModale
                  show={isModelShow}
                  onHide={() => setModelShow(false)}
                  title={"are you sure you want ot send active link"}
                  id={id}
                />
              )}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "75vh" }}
            >
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ClientInfo;
