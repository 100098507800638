import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "bootstrap-daterangepicker";
import moment from "moment";

const DaterangePicker = ({ onDateChange, className }) => {
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const pickerRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      $(function () {
        $('input[name="datefilter"]').daterangepicker({
          autoUpdateInput: false,
          locale: {
            cancelLabel: "Clear",
          },
        });

        $('input[name="datefilter"]').on(
          "apply.daterangepicker",
          function (ev, picker) {
            const start = picker.startDate.format("MM/DD/YYYY");
            const end = picker.endDate.format("MM/DD/YYYY");
            $(this).val(start + " - " + end);

            // Call onDateChange with start and end dates
            if (onDateChange) {
              onDateChange({
                start: picker.startDate.format("MM/DD/YYYY"),
                end: picker.endDate.format("MM/DD/YYYY"),
              });
            }
          }
        );

        $('input[name="datefilter"]').on(
          "cancel.daterangepicker",
          function (ev, picker) {
            $(this).val("");

            // Call onDateChange with nulls when cleared
            if (onDateChange) {
              onDateChange({ start: null, end: null });
            }
          }
        );
      });
    }, 1500);

    // Cleanup on component unmount
    return () => {
      $(pickerRef.current).daterangepicker("destroy");
    };
  }, [onDateChange]);

  return (
    <div>
      <input
        type="text"
        ref={pickerRef}
        style={{ width: "100%", padding: "10px", fontSize: "16px" }}
        placeholder="Select date range"
        name="datefilter"
        className={className}
      />
    </div>
  );
};

export default DaterangePicker;
