import { Box, Grid } from "@mui/material";
import MultiParams from "../Components/Drive/MultiParams";
import SingleParams from "../Components/Drive/SingleParams";
import SingleTerification from "../Components/Drive/SingleTarification";
import VisualSupport from "../Components/Visibilite/VisualSupport";
import Caractristics from "../Components/Drive/Caractristics";

const FleetDrive = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12}>
          <MultiParams />
        </Grid>
        <Grid item sm={12} md={12}>
          <SingleParams />
        </Grid>
        {/* <Grid item sm={12} md={12}>
          <SingleTerification />
        </Grid> */}
        <Grid item sm={12} md={12}>
          <Caractristics />
        </Grid>
        <Grid item sm={12} md={12}>
          <VisualSupport />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FleetDrive;
