import { Badge } from "react-bootstrap";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";

const Deleteuser = ({ data, onClose, handleDeleteUser }) => {
  return (
    <div
      className="d-flex flex-column gap-3 p-0 edit-role"
      style={{ background: "transparent !important" }}
    >
      <div
        style={{
          background: "#FFF",
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          padding: "20px",
        }}
        className="section-role"
      >
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="success">WEB DÉVELOPPEUR</Badge>
          </span>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="warning">COMPTABILITÉ</Badge>
          </span>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="danger">LOGISTIQUE</Badge>
          </span>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="light">R&D</Badge>
          </span>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="primary">SALES</Badge>
          </span>
        </div>
      </div>
      <div
        style={{
          background: "#FFF",
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          padding: "20px",
        }}
        className="section-role"
      >
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="info">SÉNIOR</Badge>
          </span>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="info">JUNIOR</Badge>
          </span>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="info">GÉRANT</Badge>
          </span>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="dark">ADMINISTRATEUR</Badge>
          </span>
        </div>
      </div>

      <div className="d-flex gap-2 actions-role">
        <button
          className="btn btn-danger w-full d-flex gap-2"
          onClick={() => handleDeleteUser(data)}
        >
          <CheckIcon /> VALIDER
        </button>
        <button className="btn border w-full d-flex gap-2" onClick={onClose}>
          <XIcon />
          ANNULER
        </button>
      </div>
    </div>
  );
};

export default Deleteuser;
