import { toast } from "react-toastify";
import axiosInstanceAuth from "../apiInstances/axiosInstanceAuth";

export const getVisibiliteeCars = async () => {
  try {
    const response = await axiosInstanceAuth.get(`visibilitee/cars`);
    toast.success(response?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkOutAdd", error?.response?.data?.msg);
  }
};

export const updatePositions = async (body) => {
  try {
    const response = await axiosInstanceAuth.put(
      `visibilitee/cars/sequence`,
      body
    );
    toast.success(response?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkOutAdd", error?.response?.data?.msg);
  }
};

export const createColor = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(
      `visibilitee/colors/create`,
      body
    );
    toast.success(response?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkOutAdd", error?.response?.data?.msg);
  }
};

export const getColors = async () => {
  try {
    const response = await axiosInstanceAuth.get(`visibilitee/colors/list`);
    toast.success(response?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkOutAdd", error?.response?.data?.msg);
  }
};

export const deleteColorById = async (body) => {
  try {
    const response = await axiosInstanceAuth.delete(
      `visibilitee/colors/remove/${body._id}`
    );
    toast.success(response?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkOutAdd", error?.response?.data?.msg);
  }
};

export const updateColorById = async (body) => {
  try {
    const response = await axiosInstanceAuth.patch(
      `visibilitee/colors/update/${body._id}`,
      body
    );
    toast.success(response?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.data;
  } catch (error) {
    console.log("checkOutAdd", error?.response?.data?.msg);
  }
};
