/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Historical from "../Components/ClientProfile/Historical";
import Future from "../Components/ClientProfile/Future";
import { formatDate } from "../../Common/FormatDate";
import { pagination } from "../../Common/Pagination";
import { searchCarHistory } from "../../../Redux/clientsSlice";

const ClientProfile = () => {
  const inputRef = useRef();
  const dispatch = useDispatch();

  const [isSearchValue, setSearchValue] = useState("");
  const [isTab, setTab] = useState("Future");
  const [currentPage, setCurrentPage] = useState(0);
  const { futureReservationsList, historicalReservationsList } = useSelector(
    (state) => state?.clientsSlice
  );
  const { customerData } = useSelector((state) => state?.clientsSlice);

  const itemsPerPage = 20; // Change this value based on your preference

  // pagination start

  const clienFuturetListData = pagination(
    futureReservationsList,
    currentPage,
    itemsPerPage
  );

  const clienHistoricalListData = pagination(
    historicalReservationsList,
    currentPage,
    itemsPerPage
  );

  const tab = ["Future", "Historique"];
  return (
    <>
      <div className="card mb-4 " style={{ borderRadius: "0" }}>
        {/* Navbar Start  */}
        <div className="card-header">
          <div className="d-flex align-items-center gap-3">
            <div className="col-md">
              <div className="d-flex justify-content-between">
                <ul className="nav nav-pills flex-column flex-sm-row clients-d-tabs">
                  {tab?.map((item) => (
                    <li
                      onClick={() => setTab(item)}
                      key={item}
                      className={`me-3 cursor-pointer ${
                        isTab === item && "sub-menu-active"
                      }`}
                    >
                      <div className="nav-link">{item}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
              <input
                value={isSearchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  dispatch(searchCarHistory(e.target.value));
                }}
                className="dark-input form-control"
                ref={inputRef}
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        {/* Navbar End  */}
        {isTab === "Historique" && (
          <Historical listData={clienHistoricalListData} />
        )}
        {isTab === "Future" && <Future listData={clienFuturetListData} />}
      </div>

      <div className="col-xl-12 mb-4 mb-xl-0 ">
        <div className="card">
          <div className="d-flex justify-content-between ">
            <div className="d-flex list-details">
              <i className="ti ti-list-details my-auto"></i>
              <h5 className="card-header">Activity Timeline</h5>
            </div>
            <i className="ti ti-dots-vertical ti-sm lh-1 my-auto mx-5"></i>
          </div>
          <div className="card-body pb-0">
            <ul className="timeline mb-0">
              {customerData?.customer?.activityTimeline?.map((item, index) => (
                <li className="timeline-item timeline-item-transparent border-transparent">
                  <span className="timeline-point timeline-point-secondary" />
                  <div className="timeline-event">
                    <div className="timeline-header mb-sm-0 mb-3">
                      <h6 className="mb-0">{item?.activityType}</h6>
                      <span className="text-muted">
                        {formatDate(item?.date)}
                      </span>
                    </div>
                    <p>{item?.details}</p>
                  </div>
                </li>
              ))}
            </ul>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientProfile;
