import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdCheckmark } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoChevronBackOutline } from "react-icons/io5";
import { MdNavigateNext } from "react-icons/md";
import img1 from "./../../../src/assets/icons/chevron-left.png"
import img2 from "./../../../src/assets/icons/chevron-right.png"

const notifications = [
  {
    bgColor: "#0084FF", 
    textColor: "#ffffff",
    clientText: "CLIENT ",
    clientName: "CLIENT NAME",
    message: "made a request to add a drive to their DRIVE account",
    time: "5:10 AM"
  },
  {
    bgColor: "#28C76F", 
    textColor: "#fff",
    clientText: "ONE",
    clientName: "Firstname Lastname",
    message: "Removed a payment info",
    time: "6:15 AM"
  },
  {
    bgColor: "rgba(40, 199, 111, 0.16)",
    textColor: "#28C76F",
    clientText: "PLUS",
    clientName: "Firstname Lastname",
    message: "Removed a payment info",
    time: "7:30 AM"
  },
  {
    bgColor: " rgba(0, 207, 232, 0.16", 
    textColor: "#00CFE8",
    clientText: "AUX",
    clientName: "Client Four",
    message: "Believing These 7 Myths About Event Keeps You From",
    time: "8:45 AM"
  },
  {
    bgColor: "rgba(168, 170, 174, 0.16)", 
    textColor: "#A8AAAE",
    clientText: "BASIC",
    clientName: "Client Five",
    message: "Asked to be a subscriber",
    time: "21 Jan"
  },
  {
    bgColor: "#EA5455", 
    textColor: "#fff",
    clientText: "TURISMO",
    clientName: "Client Five",
    message: "Modified the license plate on Ferrari Pista",
    time: "2 Feb"
  },
  {
    bgColor: "#FF9F43", 
    textColor: "#fff",
    clientText: "VEHICULE",
    clientName: "Client Five",
    message: "Inssurance is almost time for repair",
    time: "2 Feb"
  }
  ];

export default function HistoryNotification() {
  return (
    <div className="py-3 px-4">
    <div>
  <div className="dv1 d-flex flex-row align-content-center justify-content-between border custom-padding">
    <div>
      <span className="text-custom-notification">History Notification</span>
    </div>
    <div className="d-flex gap-3 align-items-center">
      <div className="not-message">
        1-10 of 448
      </div>
      <div className="d-flex gap-3">
      <img src={img1} alt="" />
      <img src={img2} alt="" />
        
      </div>
    </div>
  </div>

  {notifications.map((notification, index) => (
  <div key={index} className="d-flex px-3 py-3 border-b align-items-center justify-content-between">
    <div className="d-flex align-items-center" >
      <div className="d-flex align-items-center gap-3" style={{ minWidth: "150px" }}>
        <input className="form-check-input custom-checkbox" type="checkbox" id="smallCheckbox" />
        <div 
        className="clinet_details"
          style={{ backgroundColor: notification.bgColor, color: notification.textColor , padding: '5px 10px',
            borderRadius: "4px"  }} 
        >
          {notification.clientText}
        </div>
      </div>
      <div className="d-flex align-items-center  gap-4" >
        <div  className="clientname-font">{notification.clientName}</div>
        <div className="clientmessage-font">{notification.message}</div>
      </div>
    </div>
    <div className="d-flex gap-2 align-items-center">
      <div className="time-text">{notification.time}</div>
    </div>
  </div>
))}

</div>
      
    </div>
  )
}
