import { Badge } from "react-bootstrap";
import { ReactComponent as Right } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import {
  getRolePermissions,
  handleRolePermissions,
} from "../../services/RolePermission";
import { toast } from "react-toastify";

const roleColorMap = {
  "WEB DÉVELOPPEUR": "success",
  COMPTABILITÉ: "warning",
  LOGISTIQUE: "danger",
  "R&D": "light",
  SALES: "primary",
  "": "primary",
};

export const sectionsArr = [
  {
    key: "CALENDRIER",
    label: "CALENDRIER",
    subSections: [
      {
        key: "CALENDRIER.LIST",
        label: "List",
      },
      {
        key: "CALENDRIER.ADD",
        label: "Add Reservations",
      },
    ],
  },
  {
    key: "ABONNES",
    label: "ABONNES",
    subSections: [
      {
        key: "ABONNES.LIST",
        label: "List",
      },
      {
        key: "ABONNES.PROFILE",
        label: "Profile",
      },

      {
        key: "ABONNES.DOCUMENTS",
        label: "Documents",
      },
      {
        key: "ABONNES.INVOICES",
        label: "Invoices",
      },
      {
        key: "ABONNES.DAMMAGES",
        label: "Dammages",
      },
      {
        key: "ABONNES.DELETE",
        label: "Delete",
      },
    ],
  },
  {
    key: "SCRUM",
    label: "SCRUM",
  },
  {
    key: "FLOTTE",
    label: "FLOTTE",
    subSections: [
      { key: "FLOTTE.LIST", label: "List" },
      { key: "FLOTTE.GENERAL", label: "General" },
      { key: "FLOTTE.DRIVE", label: "Drive" },
      { key: "FLOTTE.TCO", label: "TCO" },
      { key: "FLOTTE.DOCUMENT", label: "Document" },
      { key: "FLOTTE.DOMMAGES", label: "Dommages" },
    ],
  },
  {
    key: "CHECKS",
    label: "CHECKS",
    subSections: [
      {
        key: "CHECKS.LIST",
        label: "List",
      },
    ],
  },
  {
    key: "LIVRAISONS",
    label: "LIVRAISONS",
    subSections: [
      {
        key: "LIVRAISONS.LIST",
        label: "List",
      },
    ],
  },
  {
    key: "CLIENTS",
    label: "CLIENTS",
  },
  {
    key: "FACTURES",
    label: "FACTURES",
    subSections: [
      {
        key: "FACTURES.LIST",
        label: "List",
      },
    ],
  },
  {
    key: "ANALYSES",
    label: "ANALYSES",
  },
  {
    key: "PROSPECT",
    label: "PROSPECT",
    subSections: [
      {
        key: "PROSPECT.LIST",
        label: "List",
      },
      {
        key: "PROSPECT.ADD",
        label: "Add",
      },
    ],
  },
  {
    key: "NEWSLETTER",
    label: "NEWSLETTER",
  },
  {
    key: "TEMPLATES",
    label: "TEMPLATES",
  },
];

const position = [
  {
    label: "SÉNIOR",
    color: "info",
  },
  {
    label: "JUNIOR",
    color: "info",
  },
  {
    label: "GÉRANT",
    color: "info",
  },
  {
    label: "ADMINISTRATEUR",
    color: "dark",
  },
];

const UserInfo = ({
  data,
  onClose,
  setRoleFilters,
  roleFilters,
  setAddMode,
  setRankFilters,
  rankFilters,
}) => {
  const [roles, setRoles] = useState([]);
  const [reload, setReload] = useState(false);
  const [expandedSection, setExpandedSection] = useState("");
  const [expandedSectionData, setExpandedSectionData] = useState(null);

  useEffect(() => {
    getRolePermissions().then((res) => {
      const result = res.data.map((r) => ({
        label: r.roleName,
        sections: r.sections,
        id: r._id,
        color: roleColorMap[r.roleName],
      }));
      setRoles(result);
    });
  }, [reload]);

  const handleExpand = (s) => {
    setExpandedSection((prev) => (prev === s ? "" : s));
  };

  const filterList = (r) => {
    setRoleFilters((prev) =>
      roleFilters.includes(r) ? prev.filter((s) => s !== r) : [...prev, r]
    );
  };

  const filterListByRank = (r) => {
    setRankFilters((prev) =>
      rankFilters.includes(r) ? prev.filter((s) => s !== r) : [...prev, r]
    );
  };

  const handleSavePermissions = async () => {
    try {
      await handleRolePermissions({
        rolePermission: expandedSectionData.label,
        sections: expandedSectionData.sections,
      });
      setExpandedSection("");
      setReload(!reload);
      toast.success("Permission updated successfully.");
    } catch (err) {
      toast.error("Something went wrong, Please try again.");
    }
  };

  useEffect(() => {
    if (!expandedSection) setExpandedSectionData(null);
  }, [expandedSection]);

  const renderCheckboxes = (category) =>
    expandedSection === category && (
      <Stack ml={3}>
        {sectionsArr.map((section) => (
          <div key={section.key}>
            <Checkbox
              checked={expandedSectionData?.sections?.includes(section.key)}
              onChange={() =>
                setExpandedSectionData((prev) => {
                  const updatedSections = prev.sections.includes(section.key)
                    ? prev.sections.filter((s) => s !== section.key)
                    : [...prev.sections, section.key];
                  return { ...prev, sections: updatedSections };
                })
              }
            />
            {section.label}
            {section.subSections &&
              expandedSectionData?.sections?.includes(section.key) && (
                <Stack ml={3}>
                  {section.subSections.map((subSection) => (
                    <div key={subSection.key}>
                      <Checkbox
                        checked={expandedSectionData?.sections?.includes(
                          subSection.key
                        )}
                        onChange={() =>
                          setExpandedSectionData((prev) => {
                            const updatedSubSections = prev.sections.includes(
                              subSection.key
                            )
                              ? prev.sections.filter(
                                  (s) => s !== subSection.key
                                )
                              : [...prev.sections, subSection.key];
                            return { ...prev, sections: updatedSubSections };
                          })
                        }
                      />
                      {subSection.label}
                    </div>
                  ))}
                </Stack>
              )}
          </div>
        ))}
        <div className="d-flex gap-2">
          <button
            className="btn btn-primary d-flex gap-2 btn-sm"
            onClick={handleSavePermissions}
          >
            <CheckIcon /> VALIDER
          </button>
          <button
            className="btn border d-flex gap-2 btn-sm"
            onClick={() => setExpandedSection("")}
          >
            <XIcon /> ANNULER
          </button>
        </div>
      </Stack>
    );

  return (
    <div className="d-flex flex-column gap-3 p-0 edit-role">
      <div
        style={{ background: "#FFF", padding: "20px" }}
        className="section-role"
      >
        {roles.map((l) => (
          <div key={l.id}>
            <div
              className={`d-flex gap-3 align-items-center ${
                roleFilters.includes(l.label) ? `role-selected` : ``
              } py-2`}
            >
              <Right
                role="button"
                onClick={() => {
                  handleExpand(l.label);
                  setExpandedSectionData(l);
                }}
              />
              <span role="button" onClick={() => filterList(l.label)}>
                <Badge bg={l.color}>{l.label}</Badge>
              </span>
            </div>
            {renderCheckboxes(l.label)}
          </div>
        ))}
      </div>
      <div
        style={{
          background: "#FFF",
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          padding: "20px",
        }}
        className="section-role"
      >
        {position.map((r) => (
          <div
            className={`d-flex gap-3 align-items-center ${
              rankFilters.includes(r.label) ? `role-selected` : ``
            } py-2`}
            role="button"
            onClick={() => filterListByRank(r.label)}
          >
            <span>
              <Badge bg={r.color}>{r.label}</Badge>
            </span>
          </div>
        ))}

        {/* <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="info">JUNIOR</Badge>
          </span>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="info">GÉRANT</Badge>
          </span>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <span>
            <Badge bg="dark">ADMINISTRATEUR</Badge>
          </span>
        </div> */}
      </div>
      <div className="d-flex gap-2">
        <button
          className="btn border w-full d-flex gap-2"
          onClick={() => setAddMode(true)}
        >
          <i className="fas fa-plus"></i>
          UTILISATEUR
        </button>
      </div>
    </div>
  );
};

export default UserInfo;
