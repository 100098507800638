import { useState } from "react";
import { ReactComponent as DropArrow } from "../../../../../../assets/icons/down-drop.svg";
import { BsPlus, BsXLg } from "react-icons/bs";

const Badges = ({ formik, isEdit }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const badges = formik?.values?.badges || [];
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const toggleInput = () => setShowInput(!showInput);

  return (
    <div>
      <div
        className="d-flex gap-1 align-items-center mb-2"
        role="button"
        onClick={toggleCollapse}
      >
        <div className="drive-section-head ">Badge en tête</div>
        <DropArrow
          height={18}
          style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        />
      </div>
      {isOpen && (
        <div className="collapsible-content">
          <div className="d-flex gap-1 flex-wrap">
            {isEdit && (
              <div className="drive-values-item no-border">
                {badges.join(", ")}
              </div>
            )}
            {!isEdit &&
              badges.map((badge) => (
                <div className="drive-values-item gap-2">
                  {badge}{" "}
                  {!isEdit && (
                    <BsXLg
                      role="button"
                      onClick={() =>
                        formik.setFieldValue("badges", [
                          ...badges.filter((b) => b !== badge),
                        ])
                      }
                    />
                  )}
                </div>
              ))}

            {!isEdit && (
              <div className="d-flex gap-1">
                {showInput && (
                  <input
                    className="drive-values-item plain-input"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        formik.setFieldValue("badges", [
                          ...badges,
                          e.target.value,
                        ]);
                        toggleInput();
                      }
                    }}
                  />
                )}
                <div
                  className="drive-values-item"
                  role="button"
                  onClick={toggleInput}
                >
                  {showInput ? (
                    <BsXLg style={{ fontSize: "15px" }} />
                  ) : (
                    <BsPlus style={{ fontSize: "15px" }} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Badges;
