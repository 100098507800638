import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";

export const addAdminUser = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`/admin-user`, body);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw new Error(error);
  }
};

export const getAdminUsers = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/admin-user`);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw new Error(error);
  }
};

export const updateAdminUser = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.patch(`/admin-user/${id}`, body);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw new Error(error);
  }
};

export const deleteAdminUser = async (id) => {
  try {
    const response = await axiosInstanceAuth.delete(`/admin-user/${id}`);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw new Error(error);
  }
};
