import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";

export const saveUpdatePricing = async (body) => {
  try {
    const response = await axiosInstanceAuth.post("/pricing", body);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const fetchPricing = async (body) => {
  try {
    const response = await axiosInstanceAuth.post("/pricing/fetch", body);
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw new Error(error);
  }
};
