/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  clientvalidateAPi,
  getSubscriptionByCompanylist,
  updataClientApi,
} from "../../../services/client/addclientApi";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { fetchAllUserList } from "../../../Redux/commonSlice";
import { useNavigate } from "react-router-dom";
import { userDocumentVerification } from "../../../Redux/clientsSlice";
import { toast } from "react-toastify";

function ValidateProspectModal(props) {
  const { allDocumentVerify } = useSelector((state) => state.clientsSlice);
  const { onHide, id, comp_id, demand, customer } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubscriptionValue, setSubscriptionValue] = useState([]);

  const formik = useFormik({
    initialValues: {
      subscription: "",
    },

    onSubmit: async (values) => {
      const formData = new FormData();
      if (demand?.subscription === "Multi") {
        formData.append("sub_id", values?.subscription?._id);
      }
      formData.append("role", "CLIENT");
      formData.append("beclient", "false");
      if (allDocumentVerify) {
        const data = await updataClientApi(id, formData);
        await clientvalidateAPi(id);
        if (data) {
          onHide();
          dispatch(fetchAllUserList());
          formik.resetForm();
          navigate("/prospect");
        }
      } else {
        onHide();
        toast.info("Please verify the documents", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate(`/prospect/document/${id}`);
      }
    },

    validate: (values) => {
      const errors = {};
      if (!values?.subscription && demand.subscription === "Multi") {
        errors.subscription = "This field is required.";
      }
      return errors;
    },
  });

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async (id) => {
    const subscriptionValue = await getSubscriptionByCompanylist(id);

    if (subscriptionValue !== undefined) {
      setSubscriptionValue(subscriptionValue?.data);
    } else {
      setSubscriptionValue([]);
    }
  };
  // subscriptionDropdownValue end

  useEffect(() => {
    subscriptionDropdownValue(comp_id);
    dispatch(userDocumentVerification(id));
  }, [comp_id, id]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Sélectionnez le forfait
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {demand?.subscription === "Multi" ? (
            <div>
              <label htmlFor="Abonnement" className="mb-1">
                Abonnement
              </label>
              <div className="col-12 col-md-3 col-lg-2 d-none d-lg-inline">
                <Dropdown
                  value={formik.values.subscription}
                  onChange={formik.handleChange}
                  options={isSubscriptionValue}
                  optionLabel="subscription_name"
                  placeholder="Abonnement"
                  className={`w-full ${
                    formik.errors.subscription ? "dropdown-invalid" : ""
                  }`}
                  name="subscription"
                  {...(formik.values.subscription && { showClear: true })}
                />
              </div>

              {formik.errors.subscription && (
                <div className="invalid-feedback">
                  {formik.errors.subscription}
                </div>
              )}
            </div>
          ) : (
            <p>{`Êtes-vous sûr d’activer l’abonnement Single pour ${customer.firstname} ${customer.lastname}`}</p>
          )}
          {/* <p>Êtes-vous sûr de créer ce pilote principal ?</p> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={props.onHide}
          >
            Annuler
          </button>
          {/* <button className="btn btn-danger" onClick={() =>}> */}
          <button className="btn btn-success" type="submit">
            validate
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
export default ValidateProspectModal;
