import React from "react";
import { useSelector } from "react-redux";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ReactComponent as DriveIcon } from "../../../../assets/icons/drive.svg";

const FleetNavbar = ({ headerImage = "" }) => {
  const { permissions } = useSelector((state) => state.userSlice);

  const { singlevehicleData } = useSelector((state) => state.vehicleSlice);
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const tabNavigate = (item) => {
    navigate(item);
  };
  return (
    <ul className="nav nav-pills mb-3 nav-fill fleet-navs" role="tablist">
      {permissions.includes("FLOTTE.GENERAL") && (
        <li classname="pe-3">
          <NavLink
            to={`general/${id}`}
            className={`nav-link ${pathname.includes("general") && "active"}`}
          >
            {/* <i className="tf-icons ti ti-home ti-xs me-1" /> General */}
            <div>
              <div className="d-flex justify-content-center align-items-center gap-1">
                {headerImage && (
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}${headerImage}`}
                    alt=""
                    width={51}
                    height={29}
                    className="align-self-end"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                )}
                <div>
                  <div style={{ fontSize: "15px", lineHeight: "15px" }}>
                    {singlevehicleData?.General?.manufacturer?.name}{" "}
                    {singlevehicleData?.General?.model?.name}{" "}
                    {singlevehicleData?.General?.declination}
                  </div>
                  <small style={{ lineHeight: "5px" }}>
                    {singlevehicleData?.GeneralInfo?.license_plate}
                  </small>
                </div>
              </div>
            </div>
          </NavLink>
        </li>
      )}
      {/* <li classname="pe-3">
        <NavLink
          to={`info/${id}`}
          className={`nav-link ${pathname.includes("info") && "active"}`}
        >
          <i className="tf-icons ti ti-info-circle ti-xs me-1" /> Centre D’info
        </NavLink>
      </li> */}
      {permissions.includes("FLOTTE.DRIVE") && (
        <li classname="pe-3">
          <NavLink
            to={`drive/${id}`}
            className={`nav-link ${
              pathname.includes("drive") ? "active" : "drive-inactive"
            }`}
          >
            <DriveIcon className={`me-1`} /> DRIVE
          </NavLink>
        </li>
      )}
      {/* <li classname="pe-3">
        <NavLink
          to={`visibilite/${id}`}
          className={`nav-link ${pathname.includes("visibilite") && "active"}`}
        >
          <i className="tf-icons ti ti-layout-navbar ti-xs me-1" />
          Visibilité
        </NavLink>
      </li> */}
      {permissions.includes("FLOTTE.TCO") && (
        <li classname="pe-3">
          <Link className="nav-link">
            <i className="tf-icons ti ti-currency-euro ti-xs me-1" /> TCO
          </Link>
        </li>
      )}
      {permissions.includes("FLOTTE.DOCUMENT") && (
        <li classname="pe-3">
          <NavLink
            to={`document/${id}`}
            type="button"
            className={`nav-link ${pathname.includes("document") && "active"}`}
          >
            <i className="tf-icons ti ti-books ti-xs me-1" /> Documents
          </NavLink>
        </li>
      )}
      {permissions.includes("FLOTTE.DOMMAGES") && (
        <li classname="pe-3">
          <button type="button" className="nav-link">
            <i className="tf-icons ti ti-clipboard-check ti-xs me-1" />
            Dommages
          </button>
        </li>
      )}
    </ul>
  );
};

export default FleetNavbar;
