import { useState } from "react";
import { BsXLg } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";

const CompanyGeneralInfo = ({ formik, editMode }) => {
  const [newWeb, setNewWeb] = useState("");

  return (
    // <form onSubmit={formik.handleSubmit}>
    <div className="row comp-content-form">
      <div className="col-2">
        <label className="form-label" htmlFor="username-modern-vertical">
          Nom
        </label>
        <input
          type="text"
          className={`${!editMode ? "hide-input" : "form-control"} ${
            formik.errors.name ? "is-invalid" : ""
          }`}
          placeholder="Nom"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.compnayDetails.name}
          name="compnayDetails.name"
          disabled={!editMode}
        />
        {formik.errors.name && (
          <div className="invalid-feedback">{formik.errors.name}</div>
        )}
      </div>
      <div className="col-2">
        <label className="form-label" htmlFor="email-modern-vertical">
          Numéro de Téléphone
        </label>

        <PhoneInput
          country={"Luxembourg"}
          enableSearch={true}
          className={`${!editMode ? "hide-input" : "form-control"}`}
          placeholder="+352 369 852 472"
          value={formik.values.compnayDetails.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="compnayDetails.phone"
          disabled={!editMode}
        />
      </div>
      <div className="col-3">
        <label className="form-label" htmlFor="username-modern-vertical">
          Email de la Société
        </label>
        <input
          type="email"
          className={`${!editMode ? "hide-input" : "form-control"} ${
            formik.errors.email ? "is-invalid" : ""
          }`}
          placeholder="Email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values?.compnayDetails?.email}
          name="compnayDetails.email"
          disabled={!editMode}
        />
        {formik.errors.email && (
          <div className="invalid-feedback">{formik.errors.email}</div>
        )}
      </div>
      <div className="col-3">
        <label
          className="form-label"
          htmlFor="confirm-password-modern-vertical"
        >
          Numéro de Registre National
        </label>
        <div className="input-group input-group-merge">
          <input
            type="text"
            id="confirm-password-modern-vertical"
            className={`${!editMode ? "hide-input" : "form-control"}`}
            placeholder="Register number"
            aria-describedby="confirm-password-modern-vertical2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.compnayDetails.regno}
            name="compnayDetails.regno"
            disabled={!editMode}
          />
        </div>
      </div>
      <div className="col-2">
        <label
          className="form-label"
          htmlFor="confirm-password-modern-vertical"
        >
          Numéro de TVA
        </label>
        <div className="input-group input-group-merge">
          <input
            id="confirm-password-modern-vertical"
            className={`${!editMode ? "hide-input" : "form-control"}`}
            placeholder="XX0000"
            aria-describedby="confirm-password-modern-vertical2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.compnayDetails.vatno}
            name="compnayDetails.vatno"
            disabled={!editMode}
          />
        </div>
      </div>
      <div className="col-12 mt-3">
        <label
          className="form-label d-flex gap-2 align-items-center mb-2"
          htmlFor="confirm-password-modern-vertical"
        >
          Sites Webs :{" "}
          <div className="d-flex gap-3">
            {formik.values.compnayDetails.website?.split(",")?.map(
              (r) =>
                r && (
                  <div
                    style={{
                      marginRight: "15px",
                      padding: "5px",
                      whiteSpace: "nowrap",
                    }}
                    className={`drive-values-item plain-input gap-2 ${
                      editMode ? "" : "no-border"
                    }`}
                  >
                    {r}

                    {editMode && (
                      <BsXLg
                        onClick={() => {
                          formik.setFieldValue(
                            "compnayDetails.website",
                            formik.values.compnayDetails.website?.replace(r, "")
                          );
                        }}
                      />
                    )}
                  </div>
                )
            )}
          </div>
        </label>
        {editMode && (
          <div className="row">
            <div className="input-group input-group-merge col-6">
              <input
                type="text"
                id="confirm-password-modern-vertical"
                className="form-control"
                placeholder="www.example.com"
                aria-describedby="confirm-password-modern-vertical2"
                value={newWeb}
                onChange={({ target }) => setNewWeb(target.value)}
                //   onChange={formik.handleChange}
                //   onBlur={formik.handleBlur}
                //   value={formik.values.compnayDetails.website}
                //   name="compnayDetails.website"
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  const vals = formik?.values?.compnayDetails?.website;

                  const updatedWebsites = vals
                    ? [...vals.split(","), newWeb].join(",") // Split, add new item, and join back as a string
                    : newWeb;

                  if (newWeb) {
                    formik.setFieldValue(
                      "compnayDetails.website",
                      updatedWebsites
                    );
                    setNewWeb("");
                  }
                }}
              >
                + Ajouter
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
    // </form>
  );
};

export default CompanyGeneralInfo;
