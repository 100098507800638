/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import BreadCrumbs from "../Common/BreadCrumbs";
import ProspectDetails from "./Components/ProspectDetails";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../Common/FormatDate";
import { fetchCustomerData, loader } from "../../Redux/clientsSlice";
import { useEffect } from "react";
import { Loader } from "../Common/Loader";
import ProspectAfterValidateDetails from "./Components/ProspectAfterValidateDetails";
import ProspectCompanyDetails from "./Components/ProspectCompanyDetails";
import ProspectNav from "./Layout/ProspectNav";

const ProspectInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { customerData, loading } = useSelector((state) => state?.clientsSlice);

  useEffect(() => {
    dispatch(loader(true));
    dispatch(fetchCustomerData(id));
  }, []);

  return (
    <div className="content-wrapper">
      <div className="container-fluid flex-grow-1 container-p-y">
        {/* <BreadCrumbs title={"Pages"} subTitle={"Prospect Profile"} /> */}
        {!loading ? (
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-4">
              {/* Prospect Details start */}
              {customerData?.customer?.beclient === "true" ? (
                <ProspectAfterValidateDetails />
              ) : (
                <ProspectDetails />
              )}

              {/* prospect with Entreprise  */}
              {customerData?.customer?.company && <ProspectCompanyDetails />}

              {/* time line  */}
              {customerData?.customer?.activityTimeline?.length > 0 && (
                <div className="card">
                  <div className="d-flex justify-content-between ">
                    <div className="d-flex list-details">
                      <i className="ti ti-list-details my-auto"></i>
                      <h5 className="card-header">Activity Timeline</h5>
                    </div>
                    <i className="ti ti-dots-vertical ti-sm lh-1 my-auto mx-5"></i>
                  </div>
                  <div className="card-body pb-0">
                    <ul className="timeline mb-0">
                      {customerData?.customer?.activityTimeline?.map(
                        (item, index) => (
                          <li
                            key={index}
                            className="timeline-item timeline-item-transparent border-transparent"
                          >
                            <span className="timeline-point timeline-point-secondary" />
                            <div className="timeline-event">
                              <div className="timeline-header mb-sm-0 mb-3">
                                <h6 className="mb-0">{item?.activityType}</h6>
                                <span className="text-muted">
                                  {formatDate(item?.date)}
                                </span>
                              </div>
                              <p>{item?.details}</p>
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <ProspectNav />
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "75vh" }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
export default ProspectInfo;
