/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import upload from "../../../../../assets/img/upload.png";
import {
  addGeneralDocument,
  deleteImage,
  singleGeneralDocument,
  updateImageIndex,
} from "../../../../../services/AddVehicle";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Collapse, Paper, Typography } from "@mui/material";
import { ReactComponent as DropArrow } from "../../../../../assets/icons/down-drop.svg";

const DraggableImage = ({
  image,
  section,
  deleteImg,
  id,
  index,
  moveImage,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "IMAGE",
    item: { type: "IMAGE", index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "IMAGE",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
      const hoverMiddleY = hoverHeight / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const halfHoverHeight = hoverHeight / 2;

      // Check if the mouse position is within the 50% threshold
      if (
        hoverClientY < hoverMiddleY - halfHoverHeight ||
        hoverClientY > hoverMiddleY + halfHoverHeight
      ) {
        return;
      }

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Perform the drop action
      moveImage(dragIndex, hoverIndex);

      // Update the item index
      item.index = hoverIndex;
    },
  });

  const ref = useRef(null);
  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={`border rounded dz-preview dz-processing dz-image-preview dz-success dz-complete p-0 mx-auto d-block ${
        isDragging ? "dragging" : ""
      }`}
      style={{ width: "8.25rem" }}
    >
      <div className="">
        {image?.source ? (
          <div className="dz-thumbnail" style={{ width: "7rem" }}>
            <img
              className="img-fluid"
              alt={image?.file?.name}
              src={image?.source}
            />
          </div>
        ) : (
          <div className="dz-thumbnail" style={{ width: "7rem" }}>
            <img
              className="img-fluid"
              alt={image?.file?.name}
              src={`${process.env.REACT_APP_IMG_URL}/${image}`}
            />
          </div>
        )}
      </div>
      <div
        onClick={() => deleteImg(id, section, !image?.source && image)}
        className="dz-remove cursor-pointer d-flex justify-content-center align-content-center"
      >
        Remove file
      </div>
    </div>
  );
};

const VisualSupport = () => {
  // const uploadLimit = 10;
  const { id } = useParams();
  const [isDocuments, setDocuments] = useState({});
  const [isRefresh, setRefresh] = useState(false);
  const [uploadedImages, setUploadedImages] = useState({
    Header: [],
    Photoshoot: [],
    Interieur: [],
    Exterieur: [],
  });
  const [hideSection, setHideSection] = useState(true);

  const fileInputsRef = useRef({
    Header: null,
    Photoshoot: null,
    Interieur: null,
    Exterieur: null,
  });

  const controllerRef = useRef(null);

  const fetchGeneralDocument = async () => {
    const documents = await singleGeneralDocument(id);
    setUploadedImages((prev) => ({
      ...prev,
      Header:
        documents?.data?.[0]?.Header === null
          ? []
          : documents?.data?.[0]?.Header === undefined
          ? []
          : [documents?.data?.[0]?.Header],
      Photoshoot: documents?.data?.[0]?.Photoshoot || [],
      Interieur: documents?.data?.[0]?.["360_interieur"] || [],
      Exterieur: documents?.data?.[0]?.["360_exterieur"] || [],
    }));
    setDocuments(documents?.data);
  };

  const handleImageUpload = async (e, section) => {
    const files = e.target.files;
    console.log(files?.[0], "files");
    const updatedImages = [...uploadedImages[section]];

    Array.from(files).forEach((file) => {
      // if (updatedImages.length < uploadLimit) {
      updatedImages.push({
        file: file,
        source: URL.createObjectURL(file),
      });
      // } else {
      //   // Handle error for exceeding maximum number of images
      //   alert(`You can upload a maximum of ${uploadLimit} images.`);
      // }
    });

    // Update state with the valid images
    setUploadedImages((prevImages) =>
      section === "Header"
        ? {
            ...prevImages,
            Header: {
              file: files?.[0],
              source: URL.createObjectURL(files?.[0]),
            },
          }
        : {
            ...prevImages,
            [section]: updatedImages,
          }
    );
    const uploadedImageData = { [section]: updatedImages };
    addDocument(uploadedImageData);
  };

  const handleUploadButtonClick = (section) => {
    fileInputsRef.current[section].click();
  };

  // const sections = ["Header", "Photoshoot", "Interieur", "Exterieur"];
  const sections = ["Photoshoot", "Interieur", "Exterieur"];

  const addDocument = async (uploadedImageData) => {
    const controller = new AbortController();
    controllerRef.current = controller;
    const signal = controller.signal;
    const formData = new FormData();
    formData.append(
      "Header",
      (uploadedImageData?.Header ?? []).length > 0 &&
        uploadedImageData?.Header[uploadedImageData?.Header.length - 1]?.file
    );

    uploadedImageData?.Photoshoot?.map((item) =>
      formData.append("Photoshoot", item.file)
    );
    uploadedImageData?.Interieur?.map((item) =>
      formData.append("Interieur360", item.file)
    );
    uploadedImageData?.Exterieur?.map((item) =>
      formData.append("Exterieur360", item.file)
    );
    formData.append("general_info", id);
    if (uploadedImageData) {
      await addGeneralDocument(formData, signal)
        .then((res) => {
          console.log("uploadedImages res", res);
          if (res?.data) {
            setRefresh(true);
            fetchGeneralDocument();
          }
        })
        .catch((err) => {
          console.log("uploadedImages err", err);
        });
    }
  };

  const deleteImg = async (id, key, path) => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      console.log("Download aborted");
    }
    setRefresh(false);
    console.log(path, "image?.source");
    const parts = path ? path?.split("/") : "";
    const filename = parts[parts.length - 1];
    const newKey =
      key === "Interieur"
        ? "360_interieur"
        : key === "Exterieur"
        ? "360_exterieur"
        : key;
    const data = await deleteImage(id, newKey, filename);
    if (data) {
      setRefresh(true);
    }
  };

  const moveImage = async (dragIndex, hoverIndex, section) => {
    const draggedImage = uploadedImages[section][dragIndex];
    const updatedImages = [...uploadedImages[section]];

    updatedImages[dragIndex] = updatedImages[hoverIndex];
    updatedImages[hoverIndex] = draggedImage;
    const data = updatedImages.map((item, index) => ({
      imageId: item?._id,
      newPosition: index,
    }));
    const tempData = {
      general_info: isDocuments?.[0]?.general_info,
      arrayName:
        section === "Interieur"
          ? "360_interieur"
          : section === "Exterieur"
          ? "360_exterieur"
          : section,
      updates: data,
    };

    setUploadedImages((prevImages) => ({
      ...prevImages,
      [section]: updatedImages,
    }));
    const resData = await updateImageIndex(tempData);

    if (resData) {
      await singleGeneralDocument(resData.data.general_info);
    }
  };

  useEffect(() => {
    fetchGeneralDocument();
    return () => {
      // Cleanup: Abort any ongoing request when component unmounts
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [id, isRefresh]);

  return (
    <Paper sx={{ boxShadow: "0px 4px 18px 0px #4B465C1A", p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          color="#4B465C"
          fontSize={18}
          fontWeight={500}
          role="button"
          onClick={() => setHideSection(!hideSection)}
        >
          Support visuel &nbsp;
          <DropArrow height={18} />
        </Typography>
      </Box>
      <Collapse collapsedSize={0} in={hideSection} timeout="auto" unmountOnExit>
        <Box sx={{ width: "100%", height: "100%", py: 3 }}>
          <div className="row">
            {sections.map((section, index) => (
              <div key={index} className="col-3 mx-auto">
                <h6 className="text-center text-nowrap">
                  {section === "Interieur"
                    ? "360 Interieur"
                    : section === "Exterieur"
                    ? "360 Exterieur"
                    : section}
                </h6>
                <div
                  className="dropzone needsclick dz-clickable"
                  onClick={() => handleUploadButtonClick(section)}
                >
                  <div className="d-flex justify-content-center">
                    <img src={upload} alt="Upload" className="img-fluid" />
                  </div>
                  <input
                    ref={(el) => (fileInputsRef.current[section] = el)}
                    type="file"
                    hidden
                    onChange={(e) => handleImageUpload(e, section)}
                    multiple={section !== "Header"} // Allow multiple file selection for non-Header sections
                  />
                </div>
                <div className="row">
                  {Array.isArray(uploadedImages[section]) &&
                    uploadedImages[section].map((image, index) => (
                      <DraggableImage
                        key={index}
                        // image={image.imagePath}
                        image={
                          typeof image === "object" && image.imagePath
                            ? image.imagePath
                            : image
                        }
                        section={section}
                        deleteImg={deleteImg}
                        id={id}
                        index={index}
                        moveImage={(dragIndex, hoverIndex) =>
                          moveImage(dragIndex, hoverIndex, section)
                        }
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default VisualSupport;
