/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoice, searchInvoiceList } from "../Redux/invoiceSlice";
import { Loader } from "../components/Common/Loader";
import { pagination } from "../components/Common/Pagination";
import { Dropdown } from "primereact/dropdown";
import ReactPaginate from "react-paginate";
import { options } from "../components/Common/DropdownValue";
import SendInvoice from "../components/Invoice/ReviewInvoice/SendInvoice";

const Invoice = () => {
  const { permissions } = useSelector((state) => state.userSlice);

  const dispatch = useDispatch();
  const [initialValue, setinitialValue] = useState({
    search: "",
    status: "",
  });
  const { invoiceData, loading } = useSelector((state) => state?.invoiceSlice);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState("25");
  const [invoiceShow, setInvoiceShow] = useState(false);

  useEffect(() => {
    dispatch(fetchInvoice());
  }, []);

  // pagination start
  const clientListData = pagination(invoiceData, currentPage, itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSelectChange = (e) => {
    setitemsPerPage(e.value);
  };
  // pagination end

  const handleChange = (e) => {
    const { name, value } = e.target;
    setinitialValue((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    dispatch(searchInvoiceList(initialValue));
  }, [initialValue]);

  // When no list permission assigned
  if (!permissions.includes("FACTURES.LIST")) return <></>;

  return (
    <div className="content-wrapper">
      <div className="container-fluid flex-grow-1 container-p-y">
        {/* page-titel start */}
        <BreadCrumbs title={"Finances"} subTitle={" Factures"} />
        {/* page-titel end */}

        <div className="card" style={{ borderRadius: "0" }}>
          <div className="card-header border-bottom">
            <div className="row">
              <div className="col-6">
                <input
                  value={initialValue?.search}
                  onChange={handleChange}
                  type="search"
                  className="form-control "
                  id="SearchItems"
                  placeholder="Rechercher.."
                  name="search"
                />
              </div>
              <div className="col d-flex justify-content-md-end">
                <NavLink to={"/add-invoice"}>
                  <button
                    className="btn add-new btn-primary waves-effect waves-light"
                    value=""
                    type="button"
                  >
                    <i className="ti ti-plus me-md-1" />
                    <span className="d-md-inline-block d-none">
                      Nouvelle Facture
                    </span>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="card-datatable table-responsive">
            <table className="invoice-list-table table">
              <thead>
                <tr>
                  <th>#ID</th>
                  <th>SOCIÉTÉ</th>
                  <th>CLIENT</th>
                  <th>TOTAL</th>
                  <th>DATE DE D'ÉMISSION</th>
                  <th>MONTANT</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {clientListData &&
                  clientListData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <th>
                          <NavLink
                            className="text-primary"
                            to={`/review-invoice/${item._id}`}
                          >
                            {item.invoice_id}
                          </NavLink>
                        </th>
                        <th className="text-nowrap">{item.company?.name}</th>
                        <th>
                          <div className="d-flex justify-content-start align-items-center">
                            <div className="avatar-wrapper">
                              <div className="avatar me-2">
                                <span className="avatar-initial rounded-circle bg-label-info">
                                  {item?.customer?.firstname?.charAt(0)}
                                  {item?.customer?.lastname?.charAt(0)}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <NavLink
                                className="text-body text-truncate"
                                to={`/review-invoice/${item?._id}`}
                              >
                                <span className="fw-medium">
                                  {item.customer?.firstname}{" "}
                                  {item.customer?.lastname}
                                </span>
                              </NavLink>
                              <small className="text-truncate text-muted">
                                {item.customer?.email}
                              </small>
                            </div>
                          </div>
                        </th>
                        {/* <th>€{item?.total_pay}</th> */}
                        <th>
                          {item.status === 0 ? (
                            `€${item?.total_pay}`
                          ) : (
                            <div className={`badge ${"bg-success"}`}>paid</div>
                          )}
                        </th>
                        <th>
                          {new Date(item?.billing_date).toLocaleDateString(
                            "en-US",
                            { month: "long", day: "numeric", year: "numeric" }
                          )}
                        </th>
                        <th>
                          {item.status === 0 ? (
                            <div
                              className={`badge ${
                                item.status === 0 &&
                                new Date(item.due_date) < new Date()
                                  ? "bg-danger"
                                  : item.status === 0
                                  ? "bg-secondary"
                                  : "bg-success"
                              }`}
                            >
                              Amount of money to be paid
                            </div>
                          ) : (
                            <div className={`badge ${"bg-success"}`}>
                              Total To Pay Amount
                            </div>
                          )}
                        </th>
                        <th>
                          <div className="d-flex align-items-center">
                            <a
                              className="text-body"
                              href="#!"
                              onClick={() => {
                                setInvoiceShow(true);
                              }}
                            >
                              <i className="ti ti-mail mx-2 ti-sm" />
                            </a>
                            <NavLink
                              className="text-body"
                              to={`/review-invoice/${item._id}`}
                            >
                              <i className="ti ti-eye mx-2 ti-sm" />
                            </NavLink>
                            <div className="dropdown">
                              <button
                                className="btn dropdown-toggle hide-arrow text-body p-0"
                                //data-bs-toggle="dropdown"
                                value=""
                                type="button"
                              >
                                <i className="ti ti-dots-vertical ti-sm" />
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a className="dropdown-item" href="#!">
                                    Télécharger
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    is="dmx-link"
                                    href="/invoice/edit/2024011"
                                  >
                                    Modifier
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#!">
                                    Dupliquer
                                  </a>
                                </li>
                                <li>
                                  <hr className="dropdown-divider" />
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item delete-record text-danger"
                                    href="#!"
                                  >
                                    Supprimer
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {loading && (
            <duv className="card-body">
              <Loader />
            </duv>
          )}
          {!loading && invoiceData?.length === 0 && (
            <div className="text-center my-3">No Data Found</div>
          )}

          <div className="d-flex justify-content-between">
            {clientListData.length > 0 && (
              <div className="my-auto mx-4">
                <Dropdown
                  value={itemsPerPage}
                  onChange={handleSelectChange}
                  options={options}
                  placeholder="Select an option"
                  className=""
                />
              </div>
            )}
            {invoiceData.length > 0 && (
              <ReactPaginate
                breakLabel="..."
                pageCount={Math.ceil(invoiceData?.length / itemsPerPage)}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        </div>
      </div>
      <SendInvoice
        invoiceShow={invoiceShow}
        handleClose={() => setInvoiceShow(false)}
      />
    </div>
  );
};

export default Invoice;
