import { Badge } from "react-bootstrap";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { useState } from "react";

const roles = [
  {
    label: "WEB DÉVELOPPEUR",
    color: "success",
  },
  {
    label: "COMPTABILITÉ",
    color: "warning",
  },
  {
    label: "LOGISTIQUE",
    color: "danger",
  },
  {
    label: "R&D",
    color: "light",
  },
  {
    label: "SALES",
    color: "primary",
  },
];

const position = [
  {
    label: "SÉNIOR",
    color: "info",
  },
  {
    label: "JUNIOR",
    color: "info",
  },
  {
    label: "GÉRANT",
    color: "info",
  },
  {
    label: "ADMINISTRATEUR",
    color: "dark",
  },
];

const EditUser = ({ data, onClose, handleEditUser }) => {
  const [localEditData, setLocalEditData] = useState(data);
  return (
    <div
      className="d-flex flex-column gap-3 p-0 edit-role"
      style={{ background: "transparent !important" }}
    >
      <div
        style={{
          background: "#FFF",
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          padding: "20px",
        }}
        className="section-role"
      >
        {roles.map((r) => (
          <div className="d-flex gap-3 align-items-center">
            <input
              type="radio"
              checked={localEditData?.role === r.label}
              onChange={() =>
                setLocalEditData({
                  ...localEditData,
                  role: r.label,
                  roleColor: r.color,
                })
              }
              disabled={localEditData.position === "ADMINISTRATEUR"}
            />
            <span>
              <Badge bg={r.color}>{r.label}</Badge>
            </span>
          </div>
        ))}
      </div>
      <div
        style={{
          background: "#FFF",
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          padding: "20px",
        }}
        className="section-role"
      >
        {position.map((p) => (
          <div className="d-flex gap-3 align-items-center">
            <input
              type="radio"
              checked={localEditData?.position === p.label}
              onChange={() =>
                setLocalEditData({
                  ...localEditData,
                  position: p.label,
                  positionColor: p.color,
                })
              }
            />
            <span>
              <Badge bg={p.color}>{p.label}</Badge>
            </span>
          </div>
        ))}
      </div>

      <div className="d-flex gap-2 actions-role">
        <button
          className="btn btn-primary w-full d-flex gap-2"
          onClick={() => handleEditUser(localEditData)}
        >
          <CheckIcon /> VALIDER
        </button>
        <button className="btn border w-full d-flex gap-2" onClick={onClose}>
          <XIcon />
          ANNULER
        </button>
      </div>
    </div>
  );
};

export default EditUser;
