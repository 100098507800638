import { Dropdown } from "primereact/dropdown";
import CustomFileUploader from "./CustomFileUploader";
import uploadIcon from "../../../../assets/icons/upload.svg";
import { useState } from "react";
import { Button } from "react-bootstrap";

const documentArray = [
  {
    value: "Carte d’identité",
    key: "Carte d’identité",
    required: true,
    bothside: true,
  },
  {
    value: "Permis de conduire",
    key: "Permis de conduire",
    required: true,
    bothside: true,
  },
  {
    value: "Justificatif de domicile",
    key: "Justificatif de domicile",
    required: false,
  },
  { value: `RIB`, key: "RIB", required: false },
  { value: `Kbis`, key: "Kbis", required: false },
  {
    value: `Bilan`,
    key: "Bilan",
    required: false,
  },
  {
    value: `RBE`,
    key: "RBE",
    required: false,
  },
  {
    value: `UBO (be)`,
    key: "UBO (be)",
    required: false,
  },
];
const DocumentDropDown = ({ userId, handleUploadImg }) => {
  const [selectedType, setSelectedType] = useState("");
  const [choosenFiles, setChoosenFiles] = useState([]);
  const isBackAndFrontRequired = documentArray.find(
    (r) => r.key === selectedType
  )?.bothside;

  const submitDocuments = async () => {
    await handleUploadImg(choosenFiles);
    setSelectedType("");
    setChoosenFiles([]);
  };

  return (
    <>
      <Dropdown
        className="car-item mb-2"
        options={documentArray}
        optionLabel="value"
        key="key"
        placeholder="Select Document Type"
        value={selectedType}
        onChange={(e) => {
          setSelectedType(e.value);
          setChoosenFiles([]);
        }}
      />

      {selectedType && (
        <div className="position-relative mb-2">
          <CustomFileUploader
            label={choosenFiles?.[0]?.file?.name || "Ajouter un document"}
            handleChange={(file) =>
              setChoosenFiles((prev) => [
                ...prev,
                { file, user: userId, key: selectedType },
              ])
            }
            name="file"
            types={["pdf", "JPG", "PNG", "GIF", "webp"]}
            customMessage={"Uploaded Successfully"}
          />
          <div className="p-2 rounded drag-upload-icon">
            <img src={uploadIcon} alt="upload-icons" />
          </div>
        </div>
      )}

      {isBackAndFrontRequired && (
        <div className="position-relative mb-2">
          <CustomFileUploader
            label={choosenFiles?.[1]?.file?.name || "Ajouter un document"}
            handleChange={(file) =>
              setChoosenFiles((prev) => [
                ...prev,
                { file, user: userId, key: selectedType },
              ])
            }
            name="file2"
            types={["pdf", "JPG", "PNG", "GIF", "webp"]}
            customMessage={"Uploaded Successfully"}
          />
          <div className="p-2 rounded drag-upload-icon">
            <img src={uploadIcon} alt="upload-icons" />
          </div>
        </div>
      )}

      {choosenFiles.length > 0 && (
        <Button onClick={submitDocuments}>Submit</Button>
      )}
    </>
  );
};

export default DocumentDropDown;
