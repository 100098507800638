import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import axiosInstanceAuthFormData from "./../../apiInstances/axiosInstanceAuthFormData";
// Manufacturer Start
export const addManufacturer = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`/manufacturers/add`, body);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getManufacturerDropdownValue = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/manufacturers`);
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};
// Manufacturer end

// Modal Start
export const addModal = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`model/add`, body);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getModalDropdownValue = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/model/${id}`);
    return response?.data;
  } catch (error) {
    console.log("getModalDropdownValue ~ error:", error);
  }
};

export const getModalALLDropdownValue = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/model`);
    return response?.data;
  } catch (error) {
    console.log("getModalALLDropdownValue ~ error:", error);
  }
};
// Modal end

// Fule Start
export const getFuleDropdownValue = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/fueltype`);
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};
// Fule end

// Vehicle start
export const addVehicle = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`vehicle/add`, body);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log("error", error.response.data.msg);
    toast.warn(error.response.data.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const addDocument = async (body) => {
  try {
    const response = await axiosInstanceAuthFormData.post(`document/add`, body);
    return response?.data;
  } catch (error) {
    console.log(error?.response?.data?.msg);
  }
};

// Vehicle end

// Vehicle List Start
export const getVehicle = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/vehicle`);

    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const getVehicleWithTypes = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/vehicle/list/types`);

    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const searchVehicle = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`/vehicle/data/search`, body);
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const addVehicleColor = async (id, body) => {
  try {
    const response = await axiosInstanceAuthFormData.patch(
      `document/${id}`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log("addVehicleColor ~ error:", error);
  }
};

export const addVisibilite = async (body) => {
  try {
    const response = await axiosInstanceAuthFormData.post(
      `vehicle/addvisibilite`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log("addVisibiliteInfo ~ error:", error);
  }
};
export const addOptionlist = async (body) => {
  try {
    const response = await axiosInstanceAuthFormData.post(
      `vehicle/addoptionlist`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log("addVisibiliteInfo ~ error:", error);
  }
};

export const singleVisibiliteInfo = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`vehicle/visibilite/${id}`);
    return response?.data;
  } catch (error) {
    console.log("singleVisibiliteInfo ~ error:", error);
  }
};

export const getVehicleById = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/vehicle/${id}`);
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const getDocumentById = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/document/${id}`);
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const vehicleCategoryDropdown = async () => {
  try {
    const response = await axiosInstanceAuth.get(
      `/vehicle/category/categorylist`
    );
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};
export const financetypeDropdown = async () => {
  try {
    const response = await axiosInstanceAuth.get(`vehicle/finance/financetype`);
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const deleteDocumentById = async (id) => {
  try {
    const response = await axiosInstanceAuth.delete(`/document/${id}`);
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const deleteDocumentGerenalId = async (id) => {
  try {
    const response = await axiosInstanceAuth.delete(`document/gerenal/${id}`);
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const updateVehicle = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.patch(`/vehicle/${id}`, body);
    toast.success("updated successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const deleteVehiclet = async (id) => {
  try {
    const response = await axiosInstanceAuth.delete(`/vehicle/${id}`);
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const addGeneralDocument = async (body, signal) => {
  try {
    const response = await axiosInstanceAuthFormData.post(
      `general/upload`,
      body,
      {
        signal: signal,
      }
    );
    return response?.data;
  } catch (error) {
    console.log("addGeneralDocument ~ error:", error);
  }
};

export const singleGeneralDocument = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`general/${id}`);
    return response?.data;
  } catch (error) {
    console.log("singleGeneralDocument ~ error:", error);
  }
};

export const removeGeneralDocument = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`general/removeimg`, body);
    return response?.data;
  } catch (error) {
    console.log("removeGeneralDocument ~ error:", error);
  }
};

export const deleteImage = async (id, key, imageName) => {
  try {
    const response = await axiosInstanceAuth.post(
      `general/${id}/${key}/${imageName}`
    );
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};
// Vehicle List end

export const deleteModel = async (id) => {
  try {
    const response = await axiosInstanceAuth.delete(`model/delete/${id}`);
    toast.success("model deleted successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const editModel = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.post(`/model/update/${id}`, body);
    toast.success("model updated successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const deleteManufacturer = async (id) => {
  try {
    const response = await axiosInstanceAuth.delete(
      `/manufacturers/delete/${id}`
    );
    toast.success("manufacturer deleted successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const editManufacturer = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.post(
      `/manufacturers/update/${id}`,
      body
    );
    toast.success("manufacturerupdated successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    console.log("getManufacturerDropdownValue ~ error:", error);
  }
};

export const updateImageIndex = async (body) => {
  try {
    const response = await axiosInstanceAuth.post(`/general/position`, body);
    return response?.data;
  } catch (error) {
    console.log("updateImageIndex ~ error:", error);
  }
};

export const addVehiDocument = async (body) => {
  const loadingToastId = toast.loading("Uploading...", {
    position: toast.POSITION.TOP_RIGHT,
  });

  try {
    const response = await axiosInstanceAuthFormData.post(
      `vehidocument/add`,
      body
    );
    toast.update(loadingToastId, {
      render: response?.data?.msg,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 5000,
    });
    return response?.data;
  } catch (error) {
    toast.update(loadingToastId, {
      render: error?.response?.data?.msg || "Upload failed",
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 5000,
    });
  }
};

export const updateVehiDocument = async (id, body) => {
  try {
    const response = await axiosInstanceAuthFormData.patch(
      `/vehidocument/${id}`,
      body
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getVehiDocument = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(`/vehidocument/${id}`);
    return response?.data;
  } catch (error) {
    console.log("getVehiDocument ~ error:", error);
  }
};

export const deleteVehiDocument = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.delete(`/vehidocument/${id}`, {
      data: body,
    });
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const vehiclePdgPageDelete = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.delete(
      `/vehidocument/pagewise/${id}`,
      {
        data: body,
      }
    );
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
