import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { ReactComponent as DropArrow } from "../../../../../assets/icons/down-drop.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, useFormik } from "formik";
import EditIcon from "../../../../../assets/img/edit.svg";
import { useOutletContext, useParams } from "react-router-dom";
import { fetchSingleVisibilite } from "../../../../../Redux/vehicleSlice";
import { ReactComponent as DownArr } from "../../../../../assets/icons/down-arr.svg";
import { Dropdown } from "primereact/dropdown";
import {
  addOptionlist,
  addVisibilite,
} from "../../../../../services/AddVehicle";
import { toast } from "react-toastify";

const Caractristics = () => {
  const { selectedLanguage } = useOutletContext(); // Access the props passed from parent
  console.log(selectedLanguage);
  const [hideSection, setHideSection] = useState(true);
  const { singleVisibiliteData } = useSelector((state) => state.vehicleSlice);
  const [isEdit, setEdit] = useState(true);
  const [checked, setLChecked] = useState(
    selectedLanguage.toUpperCase() === "EN" ? true : false
  );

  useEffect(() => {
    setLChecked(selectedLanguage.toUpperCase() === "EN" ? true : false);
  }, [selectedLanguage]);
  // const handleChecked = () => {
  //   setLChecked((prev) => !prev);
  // };
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSingleVisibilite(id));
  }, [id]);

  const formik = useFormik({
    initialValues: {
      One_Of_One: "",
      Electrique: "",
      Engine: "",
      Consumption: "",
      V_Max: "",
      RangeData: "",
      Option_Interior: "",
      Option_Exterieur: "",
      Option_Confort: "",
      description: "",
    },
    onSubmit: async (values) => {
      try {
        const tempData = {
          visibiliteData: {
            general_info: id,
            ...values,
            Electrique: values.Electrique ? "Yes" : "No",
            One_Of_One: values.One_Of_One ? "Yes" : "No",
          },
        };
        await addVisibilite(tempData);

        const tempData2 = {
          ...singleVisibiliteData,
          optionListData: {
            general_info: id,
            ...values,
            lang: checked ? "EN" : "FR",
          },
        };
        await addOptionlist(tempData2);
        dispatch(fetchSingleVisibilite(id));
        setEdit(true);
      } catch (err) {
        toast.error(err?.response?.data?.message || "Something went wrong");
      }
    },
  });

  useEffect(() => {
    const lang = checked ? "EN" : "FR";
    if (singleVisibiliteData?.visibiliteData) {
      formik.setFieldValue(
        "One_Of_One",
        singleVisibiliteData?.visibiliteData?.One_Of_One === "Yes"
          ? true
          : false
      );
      formik.setFieldValue(
        "Electrique",
        singleVisibiliteData?.visibiliteData?.Electrique === "Yes"
          ? true
          : false
      );
      formik.setFieldValue(
        "Engine",
        singleVisibiliteData?.visibiliteData?.Engine || ""
      );
      formik.setFieldValue(
        "Consumption",
        singleVisibiliteData?.visibiliteData?.Consumption || ""
      );
      formik.setFieldValue(
        "V_Max",
        singleVisibiliteData?.visibiliteData?.V_Max || ""
      );
      formik.setFieldValue(
        "RangeData",
        singleVisibiliteData?.visibiliteData?.RangeData || ""
      );
    }
    if (singleVisibiliteData?.optionListData) {
      formik.setFieldValue(
        "Option_Interior",
        singleVisibiliteData?.optionListData?.Option_Interior[lang] || ""
      );
      formik.setFieldValue(
        "description",
        singleVisibiliteData?.optionListData?.description[lang] || ""
      );
      formik.setFieldValue(
        "Option_Confort",
        singleVisibiliteData?.optionListData?.Option_Confort[lang] || ""
      );
      formik.setFieldValue(
        "Option_Exterieur",
        singleVisibiliteData?.optionListData?.Option_Exterieur[lang] || ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleVisibiliteData]);

  useEffect(() => {
    const lang = checked ? "EN" : "FR";
    if (singleVisibiliteData?.optionListData) {
      formik.setFieldValue(
        "Option_Interior",
        singleVisibiliteData?.optionListData?.Option_Interior[lang] || ""
      );
      formik.setFieldValue(
        "description",
        singleVisibiliteData?.optionListData?.description[lang] || ""
      );
      formik.setFieldValue(
        "Option_Confort",
        singleVisibiliteData?.optionListData?.Option_Confort[lang] || ""
      );
      formik.setFieldValue(
        "Option_Exterieur",
        singleVisibiliteData?.optionListData?.Option_Exterieur[lang] || ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  console.log(formik.values);
  return (
    <Paper sx={{ boxShadow: "0px 4px 18px 0px #4B465C1A", p: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            color="#4B465C"
            fontSize={18}
            fontWeight={500}
            role="button"
            onClick={() => setHideSection(!hideSection)}
          >
            Caractéristiques &nbsp;
            <DropArrow height={18} />
          </Typography>

          <Box>
            {/* <label className="switch switch-lg" style={{ marginRight: "5rem" }}>
              <input
                readOnly
                checked={checked}
                type="checkbox"
                className="switch-input"
                onChange={() => handleChecked()}
                name="lang"
                disabled={!isEdit}
              />
              <span className="switch-toggle-slider">
                <span className="switch-on">
                  <div className="en-btn">En</div>
                </span>
                <span className="switch-off">
                  <div className="fr-btn">Fr</div>
                </span>
              </span>
            </label> */}
            {isEdit ? (
              <IconButton size="small" onClick={() => setEdit(false)}>
                <img src={EditIcon} alt="Edit" width={24} height={24} />
              </IconButton>
            ) : (
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                Save
              </button>
            )}
          </Box>
        </Box>
        <Collapse
          collapsedSize={0}
          in={hideSection}
          timeout="auto"
          unmountOnExit
        >
          <Box sx={{ width: "100%", height: "100%", py: 3 }}>
            <div className="row">
              <div className="col-2">
                <h6 className="drive-section-head mb-2">Motorisation</h6>
                <InputBase
                  readOnly={isEdit}
                  name="Engine"
                  value={formik.values.Engine}
                  placeholder="Engine"
                  className={`drive-values-item ${
                    isEdit ? "hide-input no-border" : "form-control"
                  }`}
                />
              </div>
              <div className="col-2">
                <h6 className="drive-section-head mb-2">Consommation</h6>
                <InputBase
                  readOnly={isEdit}
                  name="Consumption"
                  value={formik.values.Consumption}
                  placeholder="Consumption"
                  className={`drive-values-item ${
                    isEdit ? "hide-input no-border" : "form-control"
                  }`}
                />
              </div>
              <div className="col-2">
                <h6 className="drive-section-head mb-2">Vitesse Max</h6>
                <InputBase
                  readOnly={isEdit}
                  name="V_Max"
                  value={formik.values.V_Max}
                  placeholder="V-Max"
                  className={`drive-values-item ${
                    isEdit ? "hide-input no-border" : "form-control"
                  }`}
                />
              </div>
              <div className="col-2">
                <h6 className="drive-section-head mb-2">0-100</h6>
                <InputBase
                  readOnly={isEdit}
                  name="RangeData"
                  value={formik.values.RangeData}
                  placeholder="0-100"
                  className={`drive-values-item ${
                    isEdit ? "hide-input no-border" : "form-control"
                  }`}
                />
              </div>
              <div className="col-2">
                <FormControlLabel
                  label="One Of One"
                  control={
                    <Checkbox
                      checked={formik.values?.One_Of_One === true}
                      name="One_Of_One"
                      onChange={formik.handleChange}
                    />
                  }
                  disabled={isEdit}
                  labelPlacement="start"
                />
                <FormControlLabel
                  label="Electrique"
                  control={
                    <Checkbox
                      checked={formik.values?.Electrique === true}
                      name="Electrique"
                      onChange={formik.handleChange}
                    />
                  }
                  disabled={isEdit}
                  labelPlacement="start"
                />
              </div>
            </div>
            {/* <div className="row">
            <div className="col-md-6">
              <div className="mb-4">
                <h6>One Of One</h6>
                <Dropdown
                  disabled={isEdit}
                  value={formik.values?.One_Of_One}
                  onChange={formik.handleChange}
                  options={["Yes", "No"]}
                  name="One_Of_One"
                  placeholder="Yes / No"
                  className={`w-full ${
                    isEdit ? "p-dropdown-hide" : ".p-dropdown"
                  }`}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row mb-4">
                <div className="mb-4">
                  <h6>Electrique</h6>
                  <Dropdown
                    disabled={isEdit}
                    value={formik.values?.Electrique}
                    onChange={formik.handleChange}
                    options={["Yes", "No"]}
                    name="Electrique"
                    placeholder="Yes / No"
                    className={`w-full ${
                      isEdit ? "p-dropdown-hide" : ".p-dropdown"
                    }`}
                  />
                </div>
              </div>
            </div>
          </div> */}
            <div className="mt-3">
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<DownArr />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ padding: 0 }}
                >
                  <h6>Description</h6>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <textarea
                    rows={3}
                    readOnly={isEdit}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    name="description"
                    placeholder="Description"
                    className={`mb-4 ${
                      isEdit ? "hide-input text-lighter " : "form-control"
                    }`}
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<DownArr />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ padding: 0 }}
                >
                  <h6>Options intérieures</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <textarea
                    rows={3}
                    readOnly={isEdit}
                    value={formik.values.Option_Interior}
                    onChange={formik.handleChange}
                    name="Option_Interior"
                    placeholder="Option Interior"
                    className={`mb-4 ${
                      isEdit ? "hide-input text-lighter " : "form-control"
                    }`}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<DownArr />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ padding: 0 }}
                >
                  <h6>Options extérieurs</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <textarea
                    rows={3}
                    readOnly={isEdit}
                    value={formik.values.Option_Exterieur}
                    onChange={formik.handleChange}
                    name="Option_Exterieur"
                    placeholder="Option Interior"
                    className={`mb-4 ${
                      isEdit ? "hide-input text-lighter" : "form-control"
                    }`}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<DownArr />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ padding: 0 }}
                >
                  <h6>Options confort</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <textarea
                    rows={3}
                    readOnly={isEdit}
                    value={formik.values.Option_Confort}
                    onChange={formik.handleChange}
                    name="Option_Confort"
                    placeholder="Option Interior"
                    className={`mb-4 ${
                      isEdit ? "hide-input text-lighter" : "form-control"
                    }`}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </Box>
        </Collapse>
      </form>
    </Paper>
  );
};

export default Caractristics;
