import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";

export const saveUpdateMultiSetting = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.post(
      `/vehicle/multi-setting/${id}`,
      body
    );
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const fetchMultiSetting = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `/vehicle/multi-setting/${id}`
    );
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const saveUpdateSingleSetting = async (id, body) => {
  try {
    const response = await axiosInstanceAuth.post(
      `/vehicle/single-setting/${id}`,
      body
    );
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const fetchSingleSetting = async (id) => {
  try {
    const response = await axiosInstanceAuth.get(
      `/vehicle/single-setting/${id}`
    );
    toast.success(response?.data?.msg, { position: toast.POSITION.TOP_RIGHT });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
