import React, { useState } from "react";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import {
  addCompanyApi,
  companyDetailsDelete,
  companyDetailsUpdata,
} from "../../services/Company/addCompanyApi";
import { useOutletContext } from "react-router-dom";
import DeleteModal from "../Common/DeleteModal";
import { useSelector } from "react-redux";
import CompanyGeneralInfo from "./GeneralInfo";
import ManageCompanySection from "./ManageCompanySection";
import FractionInfo from "./FractionInfo";
import BancairesInfo from "./BancairesInfo";
import AbonementPlus from "./AbonementPlus";

const AddCompany = () => {
  const allData = useOutletContext();
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoding, setloding] = useState(false);
  const [isCompanyDetails, , isCompanyDeleteBtn, , setFetchCompanylist] =
    allData;
  const { countriesList } = useSelector((state) => state?.commonSlice);
  const allProps = useOutletContext();
  const getSubscription = allProps[3]?.subscriptions;
  const isActiveCompany = allProps[8];

  const isInitialValues = {
    compnayDetails: {
      name: isCompanyDetails?.company?.name || "",
      email: isCompanyDetails?.company?.email || "",
      phone: isCompanyDetails?.company?.phone || "",
      website: isCompanyDetails?.company?.website || "",
      regno: isCompanyDetails?.company?.regno || "",
      vate_rate: isCompanyDetails?.company?.vate_rate || "",
      vatno: isCompanyDetails?.company?.vatno || "",
    },
    billingInfo: {
      legalname: isCompanyDetails?.billing?.legalname || "",
      country: isCompanyDetails?.billing?.country || "",
      street: isCompanyDetails?.billing?.street || "",
      city: isCompanyDetails?.billing?.city || "",
      number: isCompanyDetails?.billing?.number || "",
      province: isCompanyDetails?.billing?.province || "",
      postal: isCompanyDetails?.billing?.postal || "",
    },
    bankingInfo: {
      bankname: isCompanyDetails?.banking?.bankname || "",
      iban: isCompanyDetails?.banking?.iban || "",
      bic: isCompanyDetails?.banking?.bic || "",
      currency: isCompanyDetails?.banking?.currency || "",
      symbol: isCompanyDetails?.banking?.symbol || "",
    },
  };

  const formik = useFormik({
    initialValues: isInitialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (isCompanyDetails) {
        setloding(true);
        await companyDetailsUpdata(isCompanyDetails?.banking?.compid, values);
        setFetchCompanylist(isCompanyDetails?.banking?.compid); //callCompanyList
        setloding(false);
      } else {
        setloding(true);
        const data = await addCompanyApi(values);
        console.log(data);
        setFetchCompanylist(values?.compnayDetails?.name);
        setloding(false);
        formik.resetForm();
      }
    },

    validateOnChange: false,
    validate: (values) => {
      const errors = {};
      // Add validation rules here
      if (!values.compnayDetails?.name.trim()) {
        errors.name = "This field is required.";
      }
      if (!values.compnayDetails?.email?.trim()) {
        errors.email = "This field is required.";
      }
      // Add validation for other fields

      return errors;
    },
  });

  const deleteData = async (id) => {
    await companyDetailsDelete(id);
    setFetchCompanylist(id);
    setModalShow(false);
  };

  return (
    <>
      <div
        id="account-details-modern-vertical "
        className={`content dstepper-block  active d-flex flex-column gap-4`}
      >
        <ManageCompanySection
          label="Info Générale"
          component={(editMode) => (
            <CompanyGeneralInfo
              formik={formik}
              editMode={!isActiveCompany ? true : editMode}
            />
          )}
          formik={formik}
          defaultEdit={!isActiveCompany ? true : false}
          closeEdit={isLoding}
        />
        {isActiveCompany && (
          <>
            <ManageCompanySection
              label="Informations de Facturation"
              component={(editMode) => (
                <FractionInfo
                  formik={formik}
                  editMode={!isActiveCompany ? true : editMode}
                />
              )}
              formik={formik}
              defaultEdit={!isActiveCompany ? true : false}
              closeEdit={isLoding}
            />
            <ManageCompanySection
              label="Informations Bancaires"
              component={(editMode) => (
                <BancairesInfo
                  formik={formik}
                  editMode={!isActiveCompany ? true : editMode}
                />
              )}
              formik={formik}
              defaultEdit={!isActiveCompany ? true : false}
              closeEdit
            />
            <AbonementPlus
              sub={
                (getSubscription &&
                  getSubscription?.filter((r) => r.sub_name === "Plus")?.[0]) ||
                null
              }
              forS="Plus"
              compId={isCompanyDetails?.banking?.compid}
            />
            <AbonementPlus
              sub={
                (getSubscription &&
                  getSubscription?.filter((r) => r.sub_name === "One")?.[0]) ||
                null
              }
              forS="One"
              compId={isCompanyDetails?.banking?.compid}
            />
          </>
        )}

        {/* <section className="card mb-4">
          <div className="card-header">
            <h4 className="text-center text-primary fw-normal">
              Ajouter une Société
            </h4>
            <h5 className="card-title m-0">Général</h5>
          </div>
          <div className="card-body row g-3">
            <div className="col-sm-6">
              <label className="form-label" htmlFor="username-modern-vertical">
                Nom de la Société
              </label>
              <input
                type="text"
                className={`form-control ${
                  formik.errors.name ? "is-invalid" : ""
                }`}
                placeholder="Nom"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.compnayDetails.name}
                name="compnayDetails.name"
              />
              {formik.errors.name && (
                <div className="invalid-feedback">{formik.errors.name}</div>
              )}
            </div>
            <div className="col-sm-6">
              <label className="form-label" htmlFor="email-modern-vertical">
                Numéro de Téléphone
              </label>
              <input
                type="number"
                id="email-modern-vertical"
                className="form-control"
                placeholder="Num"
                aria-label="john.doe"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.compnayDetails.phone}
                name="compnayDetails.phone"
              />
            </div>
            <div className="col-sm-6">
              <label className="form-label" htmlFor="username-modern-vertical">
                Email de la Société
              </label>
              <input
                type="email"
                className={`form-control ${
                  formik.errors.email ? "is-invalid" : ""
                }`}
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.compnayDetails?.email}
                name="compnayDetails.email"
              />
              {formik.errors.email && (
                <div className="invalid-feedback">{formik.errors.email}</div>
              )}
            </div>
            <div className="col-sm-6 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Site Web
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="text"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="www.example.com"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.compnayDetails.website}
                  name="compnayDetails.website"
                />
              </div>
            </div>
            <div className="col-sm-4 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Numéro de Registre National
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="text"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="Register number"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.compnayDetails.regno}
                  name="compnayDetails.regno"
                />
              </div>
            </div>
            <div className="col-sm-4 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Numéro de TVA
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="number"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="XX0000"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.compnayDetails.vatno}
                  name="compnayDetails.vatno"
                />
              </div>
            </div>
            <div className="col-sm-4 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Taux de TVA(%)
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="number"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="x%"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.compnayDetails.vate_rate}
                  name="compnayDetails.vate_rate"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="card mb-4" style={{ borderRadius: "0" }}>
          <div className="card-header">
            <h5 className="mb-0 card-title m-0">Informations de Facturation</h5>
          </div>
          <div className="card-body row g-3">
            <div className="col-sm-6">
              <label className="form-label" htmlFor="username-modern-vertical">
                Nom Légal de la Société
              </label>
              <input
                type="text"
                id="username-modern-vertical"
                className="form-control"
                placeholder="Nom"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.billingInfo.legalname}
                name="billingInfo.legalname"
              />
            </div>
            <div className="col-sm-6">
              <div class="mb-3">
                <label htmlFor="defaultSelect" class="form-label">
                  Pays d'Incorporation
                </label>
                <Dropdown
                  options={countriesList}
                  placeholder="Sélectionner"
                  filter
                  className="w-full"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.billingInfo.country}
                  name="billingInfo.country"
                />
              </div>
            </div>

            <div className="col-sm-6 form-password-toggle">
              <label className="form-label" htmlFor="password-modern-vertical">
                Rue
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="text"
                  id="password-modern-vertical"
                  className="form-control"
                  placeholder="Rue"
                  aria-describedby="password2-modern-vertical"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.billingInfo.street}
                  name="billingInfo.street"
                />
              </div>
            </div>
            <div className="col-sm-6 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Numéro
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="text"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="Numéro"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.billingInfo.number}
                  name="billingInfo.number"
                />
              </div>
            </div>
            <div className="col-sm-4 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Ville
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="text"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="Ville"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.billingInfo.city}
                  name="billingInfo.city"
                />
              </div>
            </div>
            <div className="col-sm-4 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Province
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="text"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="Province"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.billingInfo.province}
                  name="billingInfo.province"
                />
              </div>
            </div>
            <div className="col-sm-4 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Code Postal
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="number"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="Code Postal"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.billingInfo.postal}
                  name="billingInfo.postal"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="card mb-4">
          <div className="card-header">
            <h5 className="card-title m-0">Informations Bancaires</h5>
          </div>
          <div className="card-body row g-3">
            <div className="col-sm-6">
              <label className="form-label" htmlFor="username-modern-vertical">
                Nom de la Banque
              </label>
              <input
                type="text"
                id="username-modern-vertical"
                className="form-control"
                placeholder="Nom"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bankingInfo.bankname}
                name="bankingInfo.bankname"
              />
            </div>
            <div className="col-sm-6">
              <label className="form-label" htmlFor="username-modern-vertical">
                IBAN
              </label>
              <input
                type="text"
                id="username-modern-vertical"
                className="form-control"
                placeholder="IBAN"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bankingInfo.iban}
                name="bankingInfo.iban"
              />
            </div>

            <div className="col-sm-4 form-password-toggle">
              <label className="form-label" htmlFor="password-modern-vertical">
                BIC
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="text"
                  id="password-modern-vertical"
                  className="form-control"
                  placeholder="BIC"
                  aria-describedby="password2-modern-vertical"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bankingInfo.bic}
                  name="bankingInfo.bic"
                />
              </div>
            </div>
            <div className="col-sm-4 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Devise
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="text"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="Devise"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bankingInfo.currency}
                  name="bankingInfo.currency"
                />
              </div>
            </div>
            <div className="col-sm-4 form-password-toggle">
              <label
                className="form-label"
                htmlFor="confirm-password-modern-vertical"
              >
                Symbol
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="text"
                  id="confirm-password-modern-vertical"
                  className="form-control"
                  placeholder="€"
                  aria-describedby="confirm-password-modern-vertical2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bankingInfo.symbol}
                  name="bankingInfo.symbol"
                />
              </div>
            </div>
          </div>
        </section> */}

        {/* <div className="d-flex justify-content-end gap-2 mt-3">
          <button className="btn bg-label-warning">Cancel</button>
          {isCompanyDeleteBtn && (
            <div
              className="btn bg-label-danger"
              onClick={() => setModalShow(true)}
            >
              Supprimer
            </div>
          )}
          <button
            className="dt-button add-new btn btn-primary waves-effect waves-light"
            type="submit"
          >
            <div className="d-flex align-items-center">
              {isLoding && (
                <span
                  class="spinner-border text-white me-2"
                  role="status"
                ></span>
              )}
              {isCompanyDeleteBtn ? "mise à jour" : "Ajouter"}
            </div>
          </button>
        </div> */}
      </div>

      {/* Modal start */}
      <DeleteModal
        title={"Supprimer Cette Société"}
        description={"Êtes-vous sûr de supprimer le Société ?"}
        show={modalShow}
        onHide={() => setModalShow(false)}
        deleteData={() => deleteData(isCompanyDetails?.banking?.compid)}
      />
      {/* Modal end */}
    </>
  );
};

export default AddCompany;
