const BancairesInfo = ({ formik, editMode }) => {
  return (
    <div>
      <div className="row comp-content-form">
        <div className="col-3">
          <label className="form-label" htmlFor="username-modern-vertical">
            Nom de la Banque
          </label>
          <input
            type="text"
            id="username-modern-vertical"
            placeholder="Nom"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.bankingInfo.bankname}
            name="bankingInfo.bankname"
            className={`${!editMode ? "hide-input" : "form-control"} ${
              formik?.errors?.bankingInfo?.bankname ? "is-invalid" : ""
            }`}
            disabled={!editMode}
          />
        </div>
        <div className="col-3">
          <label className="form-label" htmlFor="username-modern-vertical">
            IBAN
          </label>
          <input
            type="text"
            id="username-modern-vertical"
            placeholder="IBAN"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.bankingInfo.iban}
            name="bankingInfo.iban"
            className={`${!editMode ? "hide-input" : "form-control"} ${
              formik?.errors?.bankingInfo?.iban ? "is-invalid" : ""
            }`}
            disabled={!editMode}
          />
        </div>
        <div className="col-3">
          <label className="form-label" htmlFor="password-modern-vertical">
            BIC
          </label>
          <div className="input-group input-group-merge">
            <input
              type="text"
              id="password-modern-vertical"
              placeholder="BIC"
              aria-describedby="password2-modern-vertical"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bankingInfo.bic}
              name="bankingInfo.bic"
              className={`${!editMode ? "hide-input" : "form-control"} ${
                formik?.errors?.bankingInfo?.bic ? "is-invalid" : ""
              }`}
              disabled={!editMode}
            />
          </div>
        </div>
        <div className="col-3">
          <label
            className="form-label"
            htmlFor="confirm-password-modern-vertical"
          >
            Devise
          </label>
          <div className="input-group input-group-merge">
            <input
              type="text"
              id="confirm-password-modern-vertical"
              placeholder="Devise"
              aria-describedby="confirm-password-modern-vertical2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bankingInfo.currency}
              name="bankingInfo.currency"
              className={`${!editMode ? "hide-input" : "form-control"} ${
                formik?.errors?.bankingInfo?.currency ? "is-invalid" : ""
              }`}
              disabled={!editMode}
            />
          </div>
        </div>
        {/* <div className="col-2">
          <label
            className="form-label"
            htmlFor="confirm-password-modern-vertical"
          >
            Symbol
          </label>
          <div className="input-group input-group-merge">
            <input
              type="text"
              id="confirm-password-modern-vertical"
              placeholder="€"
              aria-describedby="confirm-password-modern-vertical2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bankingInfo.symbol}
              name="bankingInfo.symbol"
              className={`${!editMode ? "hide-input" : "form-control"} ${
                formik?.errors?.bankingInfo?.symbol ? "is-invalid" : ""
              }`}
              disabled={!editMode}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BancairesInfo;
