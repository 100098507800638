import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/CalendarStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchVehicleList } from "../Redux/vehicleSlice";
import { reservationList } from "../Redux/calanderSlice";
import {
  FaSearch,
  FaFilter,
  FaCalendar,
  FaChevronRight,
  FaTrash,
} from "react-icons/fa";
import AddReservation from "../components/Calendar/AddReservation";
import { MdDragIndicator } from "react-icons/md";
import {
  deleteReservationById,
  updateResrvation,
} from "../services/Calendar/reservation";
import { ReactComponent as CarIcon } from "../assets/icons/car-blue.svg";
import ReservationDetailBg from "../assets/img/reservation-details.png";
import ReservationDetailBgDark from "../assets/img/reservation-details-dark.png";
import CarFrame from "../assets/img/car-frame.png";
import CarFrameDark from "../assets/img/car-frame-dark.png";

import { IoInformationCircleOutline } from "react-icons/io5";
import { CiCalendarDate } from "react-icons/ci";

import { LiaCarSideSolid } from "react-icons/lia";
import { FiUsers } from "react-icons/fi";
import { PiHashStraightFill } from "react-icons/pi";
import { IoMdCalendar } from "react-icons/io";
import { IoTrashOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

import moment from "moment";

import "moment/locale/fr";

import { FaRegUser } from "react-icons/fa6";

moment.locale("fr");
moment.updateLocale("fr", {
  months: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
});

const getDaysOfYear = (year) => {
  const days = [];
  for (let month = 0; month < 12; month++) {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    for (let day = 1; day <= daysInMonth; day++) {
      days.push(new Date(Date.UTC(year, month, day))); // Use UTC to avoid timezone issues
    }
  }
  return days;
};

const getWeeksOfYear = (year) => {
  const weeks = [];
  const firstDayOfYear = new Date(Date.UTC(year, 0, 1));
  const lastDayOfYear = new Date(Date.UTC(year, 11, 31));

  let currentDate = new Date(firstDayOfYear);

  // Adjust to the nearest Sunday (week start)
  if (currentDate.getUTCDay() !== 0) {
    currentDate.setUTCDate(currentDate.getUTCDate() - currentDate.getUTCDay());
  }

  while (currentDate <= lastDayOfYear) {
    const startOfWeek = new Date(currentDate);
    currentDate.setUTCDate(currentDate.getUTCDate() + 6); // Move to Saturday
    const endOfWeek = new Date(currentDate);

    weeks.push({ start: startOfWeek, end: endOfWeek });

    // Move to the next week
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  return weeks;
};
const getMonthsOfYear = (year) => {
  const months = [];
  for (let month = 0; month < 12; month++) {
    const start = new Date(Date.UTC(year, month, 1));
    const end = new Date(Date.UTC(year, month + 1, 0));
    months.push({ start, end });
  }
  return months;
};

export const categorie = [
  "Cat 01",
  "Cat 02",
  "Cat 03",
  "Cat 04",
  "Cat 05",
  "Cat 06",
];

const carTypes = [
  "Coupé sport",
  "Berline",
  "SUV",
  "Éléctrique",
  "Hybride",
  "Cabriolet",
  "Hatchback",
  "4X4",
  "Break",
  "Roadster",
];

const filterOpts = {
  type: carTypes,
};
// Function to get days in a range of years using Moment.js
const getDaysInRange = (startYear, endYear) => {
  const days = [];
  for (let year = startYear; year <= endYear; year++) {
    for (let month = 0; month < 12; month++) {
      const daysInMonth = moment.utc([year, month]).daysInMonth(); // Get the number of days in the month
      for (let day = 1; day <= daysInMonth; day++) {
        // Only push valid dates (no invalid dates like 31st for months with <31 days)
        const date = moment.utc([year, month, day]);
        if (date.isValid()) {
          days.push(date); // Push only valid dates
        }
      }
    }
  }
  return days;
};

// Function to get weeks in a range of years using Moment.js
const getWeeksInRange = (startYear, endYear) => {
  const weeks = [];
  for (let year = startYear; year <= endYear; year++) {
    const firstDayOfYear = moment.utc([year, 0, 1]); // Start of the year
    const lastDayOfYear = moment.utc([year, 11, 31]); // End of the year

    let currentDate = firstDayOfYear.clone(); // Clone to avoid modifying the original moment

    // Adjust to the nearest Sunday (week start)
    if (currentDate.day() !== 0) {
      currentDate = currentDate.day(0); // Set to the previous Sunday
    }

    while (currentDate.isBefore(lastDayOfYear, "day")) {
      const startOfWeek = currentDate.clone();
      const endOfWeek = currentDate.clone().add(6, "days"); // Move to Saturday

      weeks.push({ start: startOfWeek, end: endOfWeek });

      // Move to the next week
      currentDate = currentDate.add(1, "week");
    }
  }
  return weeks;
};

// Function to generate month overlays in a range of years using Moment.js
const generateMonthOverlays = (startYear, endYear) => {
  const months = [];
  for (let year = startYear; year <= endYear; year++) {
    for (let month = 0; month < 12; month++) {
      const start = moment.utc([year, month, 1]); // Start of the month
      const end = start.clone().endOf("month"); // End of the month using Moment.js

      months.push({
        start,
        end,
        name: start.format("MMMM YYYY"), // Format the month name (e.g., 'January 2024')
      });
    }
  }
  return months;
};

const CalendarGrid = () => {
  const dispatch = useDispatch();
  const [viewType, setViewType] = useState("day");
  const [id, setId] = useState(null);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("Nouvelle réservation");
  const [activeFilter, setActiveFilter] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({
    type: [],
    client: [],
    category: [],
    status: [],
    company: [],
  });
  const [isSingleData, setSingleData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [vehicleDataLoc, setVehicleDataLoc] = useState([]);
  const [reservationDataLoc, setReservationDataLoc] = useState([]);
  const [currentDateXPosition, setCurrentDateXPosition] = useState(0);
  const [detailsContainerPosition, setDetailsContainerPosition] = useState({
    x: 0,
    y: 0,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [hoveredReservation, setHoveredReservation] = useState(null);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  const { vehicleList } = useSelector((state) => state?.vehicleSlice);
  const { reservationData } = useSelector((state) => state?.calanderSlice);

  const currentYear = moment.utc().year(); // Get the current year in UTC
  const startYear = currentYear - 1; // One year before the current year
  const endYear = currentYear + 1; // One year after the current year

  const daysOfYear = getDaysInRange(startYear, endYear);
  const weeksOfYear = getWeeksInRange(startYear, endYear);
  const monthsOfYear = generateMonthOverlays(startYear, endYear);

  const [theme, setTheme] = React.useState(localStorage.getItem("app-theme"));
  console.log(theme);
  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log("event");
      setTheme(localStorage.getItem("app-theme"));
    };

    window.addEventListener("localStorageUpdate", handleStorageChange);

    return () => {
      window.removeEventListener("localStorageUpdate", handleStorageChange);
    };
  }, []);

  const cellWidth = 80; // width of each cell in pixels

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Open the modal
  const handleCanceld = () => {
    setIsModalOpen(true);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleConfirmDelete = async () => {
    await deleteReservationById(id);
    reservationList((prevReservations) =>
      prevReservations.filter((reservation) => reservation.id !== id)
    );
    setReload(!reload);
    setIsModalOpen(false);
    // onHide();
    setId(null);
    // alert("Reservation deleted!"); // Replace this with your actual delete logic
  };

  const resetFilters = () => {
    setAppliedFilters({
      type: [],
      client: [],
      category: [],
      status: [],
      company: [],
    });
  };
  useEffect(() => {
    let finalVehicleD = vehicleList.filter((v) => {
      const typeMatch =
        appliedFilters.type.length > 0
          ? appliedFilters.type.includes(v.vi_type)
          : true;
      const categoryMatch =
        appliedFilters.category.length > 0
          ? appliedFilters.category.includes(v.vehicle_category)
          : true;

      const searchLower = searchTerm.trim().toLowerCase();
      const searchByText = searchLower
        ? `${v.manufacturer} ${v.model}`.toLowerCase() === searchLower ||
          v.manufacturer.toLowerCase() === searchLower ||
          v.model.toLowerCase() === searchLower ||
          v.license_plate.toLowerCase() === searchLower
        : true;

      return typeMatch && categoryMatch && searchByText;
    });
    let finalReservationData = reservationData.filter((v) => {
      const statusMatch =
        appliedFilters.status.length > 0
          ? appliedFilters.status.includes(v.status)
          : true;
      const clientMatch =
        appliedFilters.client.length > 0
          ? appliedFilters.client.includes(v.client_type)
          : true;
      const companyMatch =
        appliedFilters.company.length > 0
          ? appliedFilters.company.includes(v?.company_id?.name)
          : true;
      return statusMatch && clientMatch && companyMatch;
    });
    setVehicleDataLoc(
      finalVehicleD.sort((a, b) =>
        `${a.manufacturer} ${a.model} ${a.declination[0]}`.localeCompare(
          `${b.manufacturer} ${b.model} ${a.declination[0]}`
        )
      )
    );
    setReservationDataLoc(finalReservationData);
  }, [vehicleList, reservationData, appliedFilters, searchTerm]);

  // Function to get the ISO week number using Moment.js
  function getWeekNumber(date) {
    // Use Moment.js to get the ISO week number
    return moment.utc(date).isoWeek();
  }

  const onScrollComplete = (element) => {
    // Your callback logic goes here
    console.log("Scrolling is complete!");
    const xPosition = element.getBoundingClientRect().x;
    console.log("Current position:", xPosition);
    const overlayEle = document.getElementById("curr-d-over");
    if (overlayEle) {
      overlayEle.style.left = `${xPosition}px`; // Set the left position for the blue vertical line
    }
  };

  const smoothScrollWithCallback = (element, callback) => {
    // Scroll the element into view with smooth behavior
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });

    const checkIfScrolled = () => {
      // Check if the element has been fully scrolled into view
      const rect = element.getBoundingClientRect();
      if (
        rect.top >= 0 &&
        rect.top <= window.innerHeight &&
        rect.left >= 0 &&
        rect.left <= window.innerWidth
      ) {
        // Element is in view, trigger callback and stop checking
        // callback(element);
      } else {
        // Continue checking if not yet in view
        requestAnimationFrame(checkIfScrolled);
      }
    };

    // Start checking after initiating the scroll
    requestAnimationFrame(checkIfScrolled);
  };

  const focusCurrent = () => {
    const today = moment(); // Get today's date using moment
    let targetId;

    // Construct target ID based on view type
    if (viewType === "day") {
      targetId = today.format("YYYY-MM-DD"); // Format date as 'YYYY-MM-DD' for day view
    } else if (viewType === "week") {
      // Get the current week number and year
      const currentWeek = moment().week(); // Week number
      const currentYear = moment().year(); // Year
      targetId = `week-${currentWeek}-${currentYear}`;
    } else if (viewType === "month") {
      targetId = `month-${today.month() + 1}-${today.year()}`; // month is 0-based in moment
    }

    // Find the target element and scroll to it
    if (targetId) {
      console.log(targetId, "targetId");
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        // targetElement.scrollIntoView({
        //   behavior: "smooth",
        //   block: "center", // Scroll vertically to the center (optional)
        //   inline: "center", // Scroll horizontally to the center
        // });

        // Add a class for the current date
        targetElement.classList.add("todays-date");

        // Create a callback function
        // const onScrollComplete = () => {
        //   const xPosition = targetElement.getBoundingClientRect().x;
        //   console.log("Current position:", xPosition);
        //   const overlayEle = document.getElementById("curr-d-over");
        //   if (overlayEle) {
        //     overlayEle.style.left = `${xPosition}px`; // Set the left position for the blue vertical line
        //   }
        //   // Remove the event listener to prevent multiple triggers
        //   targetElement.removeEventListener("scroll", onScrollComplete);
        // };

        // targetElement.addEventListener("scroll", onScrollComplete, {
        //   once: true,
        // });

        // // Optionally, use requestAnimationFrame for smooth DOM manipulation timing
        // requestAnimationFrame(() => {
        //   // If needed, you can update state or perform actions with xPosition here
        //   if (targetElement) {
        //     // setCurrentDateXPosition(0);
        //     // setTimeout(() => {
        //     const xPosition = targetElement.getBoundingClientRect().x;
        //     console.log("Current position:", xPosition);
        //     //   // const xPosition = targetElement.getBoundingClientRect().x;
        //     //   setCurrentDateXPosition(xPosition);
        //     // }, 2000);
        //     const overlayEle = document.getElementById("curr-d-over");
        //     if (overlayEle) {
        //       overlayEle.style.left = `${xPosition}px`; // Set the left position for the blue vertical line
        //     }
        //   }
        // });

        smoothScrollWithCallback(targetElement, onScrollComplete);
      }
    }
  };

  useEffect(() => {
    focusCurrent();
  }, [viewType]); // Re-run when viewType changes

  useEffect(() => {}, [viewType]);
  useEffect(() => {
    dispatch(fetchVehicleList(appliedFilters)); // Fetch vehicle list from Redux
    dispatch(reservationList()); // Fetch reservation data from backend API
  }, [dispatch, reload]);

  const handleCarImageClick = (general_id) => {
    navigate(`/fleet/general/${general_id}`); // Redirect to the car's page using the car ID
  };

  const generateOverlays = () => {
    const overlays = [];

    if (viewType === "day") {
      // Generate month overlays for the day view
      monthsOfYear.forEach((month) => {
        const startOfMonth = moment(month.start);
        const endOfMonth = moment(month.end);

        // Find the start and end indexes for the current month in the days array
        const startIndex = daysOfYear.findIndex((day) =>
          moment(day).isSame(startOfMonth, "day")
        );
        const endIndex = daysOfYear.findIndex((day) =>
          moment(day).isSame(endOfMonth, "day")
        );

        // Calculate overlay width and left position in px
        const overlayWidth = (endIndex - startIndex + 1) * cellWidth; // Pixel width
        const overlayLeft = startIndex * cellWidth; // Pixel left position

        // Add overlay to the array
        overlays.push(
          <div
            key={month.start.toISOString()}
            className="month-overlay"
            style={{
              position: "absolute",
              left: `${overlayLeft}px`,
              width: `${overlayWidth}px`,
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "bold",
              zIndex: 99,
              height: "30px",
              lineHeight: "30px",
              marginTop: "60px",
            }}
          >
            {startOfMonth.format("MMMM YYYY")}
          </div>
        );
      });
    } else if (viewType === "week") {
      // Generate week overlays for the week view
      monthsOfYear.forEach((month) => {
        const startOfMonth = moment(month.start);
        const endOfMonth = moment(month.end);

        // Find the first week that starts within the month
        const firstWeekIndex = weeksOfYear.findIndex(
          (week) =>
            moment(week.start).isSameOrAfter(startOfMonth, "week") &&
            moment(week.end).isSameOrBefore(endOfMonth, "week")
        );

        // Find the last week that ends within the month
        const lastWeekIndex = weeksOfYear.findIndex(
          (week) =>
            moment(week.end).isSameOrBefore(endOfMonth, "week") &&
            moment(week.start).isSameOrAfter(startOfMonth, "week")
        );

        // If no valid weeks are found (e.g., month does not fully contain any week)
        if (firstWeekIndex === -1 || lastWeekIndex === -1) return;

        // Calculate the overlay width in pixels and left position
        const overlayWidth = (lastWeekIndex - firstWeekIndex + 1) * cellWidth; // Width in pixels
        const overlayLeft = firstWeekIndex * cellWidth; // Left position in pixels

        // Add overlay to the array
        overlays.push(
          <div
            key={month.start.toISOString()}
            className="month-overlay"
            style={{
              position: "absolute",
              left: `${overlayLeft}px`,
              width: `${overlayWidth}px`,
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "bold",
              zIndex: 99,
              height: "30px",
              lineHeight: "30px",
              marginTop: "60px",
            }}
          >
            {startOfMonth.format("MMMM YYYY")}
          </div>
        );
      });
    }

    return overlays;
  };

  const getHeaderLabels = () => {
    if (viewType === "day") {
      return daysOfYear.map((day, index) => (
        <div
          key={index}
          className="header-cell"
          id={`${moment(day).format("YYYY-MM-DD")}`} // Use the start date year
          style={{
            whiteSpace: "nowrap",
            width: `${cellWidth}px`, // Fixed width for each cell
            textAlign: "center", // Center align the text
          }}
        >
          <div style={{ fontWeight: "700", color: "#121D22" }} className="d-1">
            {moment(day).format("D")}
          </div>{" "}
          {/* Darker date */}
          <div
            style={{ fontSize: "400", marginTop: "4px", color: "#9D9CA3" }}
            className="d-2"
          >
            {moment(day).format("ddd")}
          </div>{" "}
          {/* Lighter day */}
        </div>
      ));
    } else if (viewType === "week") {
      // Set locale to French
      moment.locale("fr");

      return weeksOfYear.map((week, index) => {
        // Get the start date of the week
        const startOfWeek = moment(week.start);

        // Calculate the week number for the year
        const weekNumberInYear = startOfWeek.week(); // ISO week number (1–53)

        return (
          <div
            key={index}
            className="header-cell"
            id={`week-${weekNumberInYear}-${moment(week.start).format("YYYY")}`} // Week number within the year
            style={{
              whiteSpace: "nowrap",
              width: `${cellWidth}px`, // Fixed width for each cell
              textAlign: "center", // Center align text
            }}
          >
            <span
              style={{
                fontWeight: "400",
                color: "#7A7C7E",
                marginRight: "1px",
              }}
              className="d-1"
            >
              Sem.
            </span>{" "}
            {/* Light color for "Sem." */}
            <span
              style={{ fontWeight: "700", color: "#000000" }}
              className="d-2"
            >
              {weekNumberInYear}
            </span>{" "}
            {/* Dark color for the week number */}
          </div>
        );
      });
    } else if (viewType === "month") {
      return monthsOfYear.map((month, index) => (
        <div
          key={index}
          className="header-cell"
          id={`month-${moment(month.start).month() + 1}-${moment(
            month.start
          ).format("YYYY")}`} // Use the month start date year
          style={{
            whiteSpace: "nowrap",
            width: `${cellWidth}px`, // Fixed width for each cell
            textAlign: "center", // Center align text
          }}
        >
          <span
            style={{ fontWeight: "400", color: "#000000", marginRight: "4px" }}
            className="d-1"
          >
            {moment(month.start).format("MMM")}
          </span>
          <span
            style={{ fontWeight: "400", color: "#9D9CA3", fontSize: "smaller" }}
            className="d-2"
          >
            {moment(month.start).format("YYYY")}
          </span>
        </div>
      ));
    }
  };

  const getCellIndexForReservation = (resv) => {
    const { start_date, return_date } = resv;

    // Parse start and end dates using moment.js
    const startDate = moment(start_date);
    const endDate = moment(return_date);

    let startIdx = -1;
    let endIdx = -1;

    if (viewType === "day") {
      // Find indices for daily view
      startIdx = daysOfYear.findIndex((day) =>
        moment(day).isSame(startDate, "day")
      );
      endIdx = daysOfYear.findIndex((day) =>
        moment(day).isSame(endDate, "day")
      );
    } else if (viewType === "week") {
      // Find indices for weekly view
      startIdx = weeksOfYear.findIndex((week) =>
        startDate.isBetween(week.start, week.end, "day", "[]")
      );
      endIdx = weeksOfYear.findIndex((week) =>
        endDate.isBetween(week.start, week.end, "day", "[]")
      );

      // Handle cases where the reservation spans multiple weeks
      if (startDate.isBefore(weeksOfYear[0].start)) {
        startIdx = 0; // If reservation starts before the first week
      }
      if (endDate.isAfter(weeksOfYear[weeksOfYear.length - 1].end)) {
        endIdx = weeksOfYear.length - 1; // If reservation ends after the last week
      }
    } else if (viewType === "month") {
      // Find indices for monthly view
      startIdx = monthsOfYear.findIndex((month) =>
        startDate.isBetween(month.start, month.end, "day", "[]")
      );
      endIdx = monthsOfYear.findIndex((month) =>
        endDate.isBetween(month.start, month.end, "day", "[]")
      );
    } else {
      console.error(
        "Invalid view type. Supported values are 'day', 'week', and 'month'."
      );
      return { startIdx: -1, endIdx: -1 };
    }

    // Additional error handling for indices
    if (startIdx === -1) {
      console.error("Start date does not match any range in the current view.");
    }
    if (endIdx === -1) {
      console.error("End date does not match any range in the current view.");
    }

    // console.log({ startIdx, endIdx });
    return { startIdx, endIdx };
  };

  const handleDrag = async (
    direction,
    event,
    reservation,
    reservationWidthPx,
    reservationLeftPx,
    startIdx,
    endIdx
  ) => {
    console.log(reservation);
    event.preventDefault();
    const originalX = event.clientX;
    let updatedWidth = reservationWidthPx;
    let updatedLeft = reservationLeftPx;

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - originalX;
      const deltaCells = Math.round(deltaX / cellWidth) * cellWidth; // Snap to cell width increments

      if (direction === "left") {
        updatedWidth = reservationWidthPx - deltaCells;
        updatedLeft = reservationLeftPx + deltaCells;
      } else if (direction === "right") {
        updatedWidth = reservationWidthPx + deltaCells;
      }

      // Ensure valid dimensions
      if (updatedWidth >= cellWidth) {
        const bar = document.getElementById(`reservation-${reservation._id}`);
        if (bar) {
          bar.style.width = `${updatedWidth}px`;
          bar.style.left = `${updatedLeft}px`;
        }
      }
    };

    const onMouseUp = async () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);

      // Calculate the new start and end cell indices
      const newStartIdx =
        direction === "left" ? Math.round(updatedLeft / cellWidth) : startIdx;
      const newEndIdx =
        direction === "right"
          ? Math.round(updatedWidth / cellWidth + startIdx - 1)
          : endIdx;

      const newStartDate = daysOfYear[newStartIdx];
      const newEndDate = daysOfYear[newEndIdx];

      // API call to update the reservation
      if (newStartDate && newEndDate) {
        try {
          await updateResrvation(reservation._id, {
            ...reservation,
            start_date: newStartDate.toISOString(),
            return_date: newEndDate.toISOString(),
          });
          dispatch(reservationList());
          console.log(
            `Updated reservation ${reservation._id} to ${newStartDate} - ${newEndDate}`
          );
        } catch (error) {
          console.error("Failed to update reservation:", error);
        }
      }
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const getGridRows = () => {
    const cells =
      viewType === "day"
        ? daysOfYear
        : viewType === "week"
        ? weeksOfYear
        : monthsOfYear;

    return vehicleDataLoc.map((car, rowIndex) => (
      <div key={rowIndex} className="grid-row">
        {viewType === "day" &&
          daysOfYear.map((day, index) => (
            <div
              key={index}
              className="grid-cell"
              // id={`cell-${moment(day).format("YYYY-MM-DD")}`} // Use the start date year
              style={{
                background:
                  moment().format("YYYY-MM-DD") ===
                  moment(day).format("YYYY-MM-DD")
                    ? "#5CAFFF26"
                    : "",
              }}
            ></div>
          ))}
        {viewType === "week" &&
          weeksOfYear.map((week, index) => {
            const today = moment();
            const todayYear = moment().format("MM-YYYY");
            const rangeYear = moment(week.start).format("MM-YYYY");

            const weekNumber = today.week() + 1;
            const rangeNumber = moment(week.start).week() + 1;

            return (
              <div
                key={index}
                className="grid-cell"
                id={`cell-week-${moment(week.start).format(
                  "YYYY"
                )}-${weekNumber}-${rangeNumber}`}
                style={{
                  background:
                    weekNumber === rangeNumber && todayYear === rangeYear
                      ? "#5CAFFF26"
                      : "",
                  fontSize: "10px",
                }}
              ></div>
            );
          })}
        {viewType === "month" &&
          monthsOfYear.map((month, index) => {
            const todayYear = moment().format("MM-YYYY");
            const rangeYear = moment(month.start).format("MM-YYYY");
            return (
              <div
                key={index}
                className="grid-cell"
                id={`cell-month-${moment(month.start).month() + 1}-${moment(
                  month.start
                ).format("YYYY")}`}
                style={{
                  background: todayYear === rangeYear ? "#5CAFFF26" : "",
                  fontSize: "10px",
                }}
              ></div>
            );
          })}

        {reservationDataLoc
          .filter((res) => res?.general_id?._id === car?._id)
          .map((reservation, idx) => {
            const { startIdx, endIdx } =
              getCellIndexForReservation(reservation);

            const reservationWidthPx = (endIdx - startIdx + 1) * cellWidth;
            const reservationLeftPx = startIdx * cellWidth;

            // Use moment to parse dates and compare
            const now = moment();
            const startDate = moment(reservation?.start_date);
            const returnDate = moment(reservation?.return_date);

            // Determine the type of confirmed reservation using moment
            let confirmedClass = "";
            let notConfirmedClass = "";
            if (reservation.status === "Confirmed") {
              if (startDate.isAfter(now)) {
                confirmedClass = "confirmed-future"; // Type 1: Light green background
              } else if (startDate.isBefore(now) && returnDate.isAfter(now)) {
                confirmedClass = "confirmed-ongoing"; // Type 2: Dark green background
              } else if (returnDate.isBefore(now)) {
                confirmedClass = "confirmed-pending-checkin"; // Type 3: White background with green border
              }
            }

            if (reservation.status === "Not Confirmed") {
              notConfirmedClass = "not-confirmed";
            }

            return (
              <div
                key={`${reservation.car}-${idx}`}
                id={`reservation-${reservation._id}`}
                className={`reservation-bar ${confirmedClass} ${notConfirmedClass}`}
                style={{
                  left: `${reservationLeftPx}px`,
                  width: `${reservationWidthPx}px`,
                  top: "5px",
                  padding:
                    viewType !== "day"
                      ? "3px 20px"
                      : confirmedClass === "confirmed-ongoing"
                      ? "3px 0px"
                      : "3px 5px",
                }}
                onMouseEnter={(e) => {
                  setHoveredReservation({
                    reservation, // Pass all reservation details
                    position: { x: e.pageX, y: e.pageY },
                  });
                }}
                onMouseLeave={() => {
                  setHoveredReservation(null); // Clear hover state
                }}
              >
                {viewType === "day" && (
                  <div
                    className="drag-handle-left"
                    onMouseDown={(event) =>
                      handleDrag(
                        "left",
                        event,
                        reservation,
                        reservationWidthPx,
                        reservationLeftPx,
                        startIdx,
                        endIdx
                      )
                    }
                  >
                    <MdDragIndicator />
                  </div>
                )}
                <div
                  className="d-flex flex-row justify-content-between details-bar"
                  style={{ flex: 1 }}
                >
                  <div
                    className="d-flex flex-column"
                    onClick={() => {
                      setShow(true);
                      setId(reservation._id);
                    }}
                  >
                    <div className="user-res">
                      {reservation?.customer_id
                        ? `${reservation?.customer_id?.firstname} ${reservation?.customer_id?.lastname}`
                        : "User Not Found"}
                    </div>
                    <div className="dated-res">
                      {startDate.format("DD/MM/YYYY")} -{" "}
                      {returnDate.format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <button
                    className="cancel-button"
                    onClick={() => {
                      handleCanceld();
                      setId(reservation._id);
                    }}
                    aria-label="Cancel"
                  >
                    <span className="cancel-icon">✖</span>
                  </button>
                </div>

                {viewType === "day" && (
                  <div
                    className="drag-handle-right"
                    onMouseDown={(event) =>
                      handleDrag(
                        "right",
                        event,
                        reservation,
                        reservationWidthPx,
                        reservationLeftPx,
                        startIdx,
                        endIdx
                      )
                    }
                  >
                    <MdDragIndicator />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    ));
  };

  const toggleFilters = () => setShowFilters(!showFilters);

  const renderFilterChips = () => {
    return (
      <div className="d-flex flex-wrap gap-3">
        {Object.entries(appliedFilters).map((r) => {
          return (
            <>
              {r[1].map((v) => (
                <div
                  className="filter-chip"
                  role="button"
                  onClick={() =>
                    setAppliedFilters({
                      ...appliedFilters,
                      [r[0]]: [...appliedFilters[r[0]].filter((f) => f !== v)],
                    })
                  }
                >
                  {r[0] === "category" ? `Catégorie 0${v}` : v}
                  <IoClose />
                </div>
              ))}
            </>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const scrollPosition = e.target.scrollTop;
      const sidebarDiv = document.getElementById("sidebar-scroll");
      console.log(scrollPosition, "scrollPosition");
      if (sidebarDiv) {
        console.log(sidebarDiv, "sidebarDiv");
        sidebarDiv.scroll({ top: scrollPosition });
      }

      const today = moment(); // Get today's date using moment
      let targetId;

      // Construct target ID based on view type
      if (viewType === "day") {
        targetId = today.format("YYYY-MM-DD"); // Format date as 'YYYY-MM-DD' for day view
      } else if (viewType === "week") {
        // Get the current week number and year
        const currentWeek = moment().week(); // Week number
        const currentYear = moment().year(); // Year
        targetId = `week-${currentWeek}-${currentYear}`;
      } else if (viewType === "month") {
        targetId = `month-${today.month() + 1}-${today.year()}`; // month is 0-based in moment
      }
      if (targetId) {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const xPosition = targetElement
            ? targetElement.getBoundingClientRect().x
            : 0;
          const overlayEle = document.getElementById("curr-d-over");
          if (overlayEle) {
            overlayEle.style.left = `${xPosition + 35}px`; // Set the left position for the blue vertical line
          }
        }
      }
    };

    const element = document.getElementById("calendar");
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [viewType]);

  useEffect(() => {
    const handleScroll = (e) => {
      const scrollPosition = e.target.scrollTop;
      const sidebarDiv = document.getElementById("calendar");
      if (sidebarDiv) {
        sidebarDiv.scroll({ top: scrollPosition });
      }
    };
    const element = document.getElementById("sidebar-scroll");
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     const overlayEle = document.getElementById("curr-d-over");
  //     if (window.scrollY < 5) {
  //       if (overlayEle)
  //         document.getElementById("curr-d-over").style.marginTop = `107px`;
  //     } else {
  //       overlayEle.style.marginTop = `0px`;
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   // Cleanup function to remove the listener
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div id="cal-con">
      {/* Reservation button */}

      <div style={{ position: "relative" }}>
        <div className="calendar" id="calendar">
          {/* View type buttons */}
          {/* {currentDateXPosition > 0 && ( */}
          <>
            {/* Blue Line */}
            <div
              className="current-dateoverlay"
              style={{
                left: `${currentDateXPosition}px`, // Dynamically set horizontal position
                height:
                  viewType === "day"
                    ? `${vehicleDataLoc.length * 70}px` // Height for "Day" view
                    : viewType === "week"
                    ? `${vehicleDataLoc.length * 70}px` // Adjust for week grid (same height for the current example)
                    : `${vehicleDataLoc.length * 70}px`, // Height for "Month" view
              }}
              id="curr-d-over"
            ></div>
          </>
          {/* )} */}
          <div className="reservation-button-container">
            <button
              className="reservation-button"
              onClick={() => setShow(true)}
            >
              <FaCalendar className="calendar-icon" />
              <span>Nouvelle réservation</span>
            </button>
          </div>

          <div className="calendar-header">
            {" "}
            {generateOverlays()}
            {getHeaderLabels()}
          </div>
          <div className="calendar-body">{getGridRows()}</div>

          {hoveredReservation && (
            <div
              className="reservation-tooltip"
              style={{
                position: "fixed",
                top: `${hoveredReservation.position.y + 10}px`, // Offset slightly below the cursor
                left: `${hoveredReservation.position.x}px`,
                zIndex: 1000,
                transform: "translate(-50%, 10%)", // Center the tooltip and adjust height
                background: `url(${
                  theme === "light"
                    ? ReservationDetailBg
                    : ReservationDetailBgDark
                })`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="tooltip-container">
                {/* Header Section */}
                <div className="tooltip-header">
                  <span className="tooltip-info-icon">
                    <IoInformationCircleOutline />
                  </span>
                  <span className="tooltip-header-text">
                    Info de réservation
                  </span>
                </div>

                {/* Address Information */}
                <div className="tooltip-address-section">
                  <div className="tooltip-address-item">
                    {hoveredReservation?.reservation?.client_type ===
                      "Entreprise" && (
                      <>
                        <strong>
                          <FaRegUser />{" "}
                          {hoveredReservation.reservation.client_type}
                        </strong>
                        <p>
                          {
                            hoveredReservation.reservation.customer_id.company
                              .company_address
                          }
                        </p>
                      </>
                    )}
                  </div>

                  <div className="tooltip-address-item">
                    <strong>
                      <FaRegUser />{" "}
                      {hoveredReservation.reservation.customer_id?.firstname}{" "}
                      {hoveredReservation.reservation.customer_id.lastname}
                    </strong>
                    <p>{hoveredReservation.reservation.customer_id.address}</p>
                  </div>
                </div>

                <div className="details-resv">
                  {/* Departure and Return Details */}
                  <div className="tooltip-dates-section">
                    {/* Departure Section */}
                    <div className="tooltip-depart">
                      <div className="tooltip-date-header">
                        <span className="tooltip-date-label">Départ</span>
                      </div>
                      <div className="tooltip-date-time">
                        {hoveredReservation.reservation.dept_loc}
                      </div>
                      <div className="tooltip-date-time">
                        {hoveredReservation?.reservation?.dept_time}
                      </div>
                    </div>

                    {/* Car Icon */}
                    <div className="tooltip-icon-separator">
                      <img
                        src={theme === "light" ? CarFrame : CarFrameDark}
                        height={20}
                        width={90}
                      />
                    </div>

                    {/* Return Section */}
                    <div className="tooltip-retour">
                      <div className="tooltip-date-header">
                        <span className="tooltip-date-label">Retour</span>
                      </div>
                      <div
                        className="tooltip-date-time"
                        style={{
                          fontSize: "12px",
                          lineHeight: "12px",
                          fontWeight: 400,
                          display: "-webkit-box",
                          WebkitLineClamp: 2, // Limit text to 2 lines
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {hoveredReservation.reservation.return_loc}
                      </div>

                      <div className="tooltip-date-time">
                        {hoveredReservation?.reservation?.return_time}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-nowrap align-items-center">
                    <div className="ticket-bubble left" />
                    <hr
                      style={{
                        borderColor: "#000",
                        borderStyle: "dashed",
                        width: "100%",
                      }}
                    />

                    <div className="ticket-bubble right" />
                  </div>
                  <div className="reserved-dates">
                    <div className="reserved-date">
                      <CiCalendarDate />{" "}
                      {moment(
                        hoveredReservation?.reservation?.start_date
                      ).format("DD/MM/YYYY")}
                    </div>
                    <div className="reserved-date">
                      <CiCalendarDate />{" "}
                      {moment(
                        hoveredReservation?.reservation?.return_date
                      ).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="calendar-grid-container"
          style={{ position: "absolute", left: "0", top: 0 }}
        >
          <div
            className="sidebar"
            style={{
              width: showFilters ? "498px" : "300px",
            }}
          >
            {showFilters && (
              <div
                style={{
                  background: "#FFF",
                  height: "100%",
                  width: "300px",
                  padding: "80px 15px",
                }}
                className="right-sub-bar"
              >
                <div className="">
                  <label className="checkbox-div d-flex align-items-start gap-3">
                    <input type="checkbox" className="new-checkbox" />
                    <div>Afficher uniquement les ressources disponibles</div>
                  </label>
                </div>
                <hr style={{ borderColor: "#EFEFEF" }} />
                <div>
                  <a
                    className="filter-header"
                    role="button"
                    onClick={() =>
                      setActiveFilter(activeFilter === "type" ? "" : "type")
                    }
                  >
                    <LiaCarSideSolid className="filter-ico" />
                    <div className="filter-title">
                      Type de voiture{" "}
                      {appliedFilters?.type?.length > 0 && (
                        <span>({appliedFilters?.type?.length})</span>
                      )}
                    </div>
                    <FaChevronRight />
                  </a>
                  {activeFilter === "type" &&
                    filterOpts[activeFilter]?.map((opt) => (
                      <label className="checkbox-div d-flex align-items-start gap-3">
                        <input
                          type="checkbox"
                          className="new-checkbox mt-1"
                          checked={appliedFilters?.type?.includes(opt)}
                          onChange={(e) =>
                            setAppliedFilters({
                              ...appliedFilters,
                              type: e.target.checked
                                ? [...appliedFilters.type, opt]
                                : [
                                    ...appliedFilters.type.filter(
                                      (f) => f !== opt
                                    ),
                                  ],
                            })
                          }
                        />{" "}
                        {opt}
                      </label>
                    ))}
                </div>
                <hr style={{ borderColor: "#EFEFEF" }} />
                <div>
                  <a
                    className="filter-header"
                    role="button"
                    onClick={() =>
                      setActiveFilter(activeFilter === "client" ? "" : "client")
                    }
                  >
                    <FiUsers className="filter-ico" />
                    <div className="filter-title">
                      Type de Client{" "}
                      {appliedFilters?.client?.length > 0 && (
                        <span>({appliedFilters?.client?.length})</span>
                      )}
                    </div>
                    <FaChevronRight />
                  </a>
                  {activeFilter === "client" &&
                    ["Particulier", "Entreprise"]?.map((opt) => (
                      <label className="checkbox-div d-flex align-items-start gap-3">
                        <input
                          type="checkbox"
                          className="new-checkbox mt-1"
                          checked={appliedFilters?.client?.includes(opt)}
                          onChange={(e) =>
                            setAppliedFilters({
                              ...appliedFilters,
                              client: e.target.checked
                                ? [...appliedFilters.client, opt]
                                : [
                                    ...appliedFilters.client.filter(
                                      (f) => f !== opt
                                    ),
                                  ],
                            })
                          }
                        />{" "}
                        {`${opt}`}
                      </label>
                    ))}
                </div>
                <hr style={{ borderColor: "#EFEFEF" }} />
                <div>
                  <a
                    className="filter-header"
                    role="button"
                    onClick={() =>
                      setActiveFilter(
                        activeFilter === "category" ? "" : "category"
                      )
                    }
                  >
                    <PiHashStraightFill className="filter-ico" />
                    <div className="filter-title">
                      Catégories{" "}
                      {appliedFilters?.category?.length > 0 && (
                        <span>({appliedFilters?.category?.length})</span>
                      )}
                    </div>
                    <FaChevronRight />
                  </a>
                  {activeFilter === "category" &&
                    [1, 2, 3, 4, 5, 6]?.map((opt) => (
                      <label className="checkbox-div d-flex align-items-start gap-3">
                        <input
                          type="checkbox"
                          className="new-checkbox mt-1"
                          checked={appliedFilters?.category?.includes(opt)}
                          onChange={(e) =>
                            setAppliedFilters({
                              ...appliedFilters,
                              category: e.target.checked
                                ? [...appliedFilters.category, opt]
                                : [
                                    ...appliedFilters.category.filter(
                                      (f) => f !== opt
                                    ),
                                  ],
                            })
                          }
                        />{" "}
                        {`Catégorie 0${opt}`}
                      </label>
                    ))}
                </div>
                <hr style={{ borderColor: "#EFEFEF" }} />
                <div>
                  <a
                    className="filter-header"
                    role="button"
                    onClick={() =>
                      setActiveFilter(activeFilter === "status" ? "" : "status")
                    }
                  >
                    <IoMdCalendar className="filter-ico" />
                    <div className="filter-title">
                      État de la ressource{" "}
                      {appliedFilters?.status?.length > 0 && (
                        <span>({appliedFilters?.status?.length})</span>
                      )}
                    </div>
                    <FaChevronRight />
                  </a>
                  {activeFilter === "status" &&
                    [
                      "Maintenance",
                      "Confirmed",
                      "Not Confirmed",
                      "En cours",
                      "Terminé",
                      "Bloquée",
                      "Disponible",
                    ]?.map((opt) => (
                      <label className="checkbox-div d-flex align-items-start gap-3">
                        <input
                          type="checkbox"
                          className="new-checkbox mt-1"
                          checked={appliedFilters?.status?.includes(opt)}
                          onChange={(e) =>
                            setAppliedFilters({
                              ...appliedFilters,
                              status: e.target.checked
                                ? [...appliedFilters.status, opt]
                                : [
                                    ...appliedFilters.status.filter(
                                      (f) => f !== opt
                                    ),
                                  ],
                            })
                          }
                        />{" "}
                        {opt}
                      </label>
                    ))}
                </div>
                <hr style={{ borderColor: "#EFEFEF" }} />
                <div className="d-flex flex-wrap justify-content-around filter-header">
                  {["LU", "BE", "FR"].map((opt) => (
                    <div>
                      <label className="checkbox-div d-flex flex-column align-items-center gap-2">
                        <input
                          type="checkbox"
                          className="new-checkbox"
                          checked={appliedFilters?.company?.includes(opt)}
                          onChange={(e) =>
                            setAppliedFilters({
                              ...appliedFilters,
                              company: e.target.checked
                                ? [...appliedFilters.company, opt]
                                : [
                                    ...appliedFilters.company.filter(
                                      (f) => f !== opt
                                    ),
                                  ],
                            })
                          }
                        />
                        <div>{opt}</div>
                      </label>
                    </div>
                  ))}
                </div>
                <hr style={{ borderColor: "#EFEFEF" }} />
                <div
                  className="clear-filter-opt"
                  role="button"
                  onClick={resetFilters}
                >
                  <IoTrashOutline /> réinitialiser les filtres
                </div>
                <hr style={{ borderColor: "#EFEFEF" }} />
                <div>{renderFilterChips()}</div>
              </div>
            )}
            <div
              className="d-flex flex-column"
              style={{
                boxShadow: "0px 4px 8.6px 0px #0000001A",
                padding: "0 10px",
                marginLeft: showFilters ? "300px" : "5px",
                width: showFilters ? "195px" : "300px",
                height: "100vh",
                overflow: "auto",
              }}
              id="sidebar-scroll"
            >
              <div
                className="head-side"
                style={{ width: showFilters ? "181px" : "281px" }}
              >
                <div className="view-buttons">
                  <button
                    className={`view-button ${
                      viewType === "day" ? "selected" : ""
                    }`}
                    onClick={() => setViewType("day")}
                  >
                    {showFilters ? `J` : `Jours`}
                  </button>
                  <button
                    className={`view-button ${
                      viewType === "week" ? "selected" : ""
                    }`}
                    onClick={() => setViewType("week")}
                  >
                    {showFilters ? `S` : `Sem.`}
                  </button>
                  <button
                    className={`view-button ${
                      viewType === "month" ? "selected" : ""
                    }`}
                    onClick={() => setViewType("month")}
                  >
                    {showFilters ? `M` : `Mois`}
                  </button>
                </div>

                <div className="search-container">
                  <button className="filter-button" onClick={toggleFilters}>
                    <FaFilter className="filter-icon" />
                  </button>

                  {/* Search Bar */}
                  <div className="search-bar" style={{ width: "79%" }}>
                    <FaSearch className="search-icon" />
                    <input
                      type="text"
                      placeholder="Chercher"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>

              <div className="car-list">
                {vehicleDataLoc.map((vehicle, index) => (
                  <div
                    key={index}
                    style={{
                      height: "70px",
                      borderBottom: "1px solid #F5F7F9",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="car-label-con"
                  >
                    <div className="car-label">
                      {vehicle?.documents?.[0]?.document?.[0] && (
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}${vehicle?.documents?.[0]?.document?.[0]}`}
                          width={100}
                          height={50}
                          style={{ cursor: "pointer" }} // Indicates that the image is clickable
                          onClick={() =>
                            handleCarImageClick(vehicle.general_id)
                          } // Redirects to the car page
                          alt={`${vehicle.manufacturer} ${vehicle.model}`}
                        />
                      )}
                      <div>
                        {!showFilters && (
                          <div className="car-name">
                            <span className="bold-model">
                              {vehicle.manufacturer}
                            </span>{" "}
                            <span>{vehicle.model}</span>
                          </div>
                        )}
                        {!showFilters && (
                          <div className="car-plate">
                            {vehicle.license_plate}
                          </div>
                        )}
                        {showFilters && (
                          <div className="car-plate-hh">
                            {vehicle.license_plate}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reservation Modal */}

      {isModalOpen && (
        <div className="modal-overlay-cls">
          <div className="modal-center-content-cls">
            <h2>Suppression de réservation</h2>
            <p>
              Vous êtes sur le point de supprimer la réservation de{" "}
              <b>NOM PRÉNOM</b> à la <b>DATE</b> du{" "}
              {/* {moment(isSingleData?.start_date).format("DD/MM/YYYY")} */}
            </p>
            <div className="modal-actions">
              <button className="btn-primary" onClick={handleConfirmDelete}>
                Supprimer
              </button>
              <button className="btn-secondary" onClick={handleCloseModal}>
                Abandonner
              </button>
            </div>
          </div>
        </div>
      )}

      {show && (
        <AddReservation
          show={show}
          onHide={() => setShow(false)}
          res_id={id}
          setId={setId}
          name={name}
        />
      )}
    </div>
  );
};

export default CalendarGrid;
