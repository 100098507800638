import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";

export const getRolePermissions = async () => {
  try {
    const response = await axiosInstanceAuth.get(`/role-permission`);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw new Error(error);
  }
};

export const handleRolePermissions = async (data) => {
  try {
    const response = await axiosInstanceAuth.post(`/role-permission`, data);
    toast.success(response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw new Error(error);
  }
};
