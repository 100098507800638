/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Common/Loader";
import EditChecks from "./EditChecks";
import { fetchCheck } from "../../Redux/checkSlice";
import { useNavigate } from "react-router-dom";
import "../../css/checks.css";
import CheckListItem from "./CheckListItem";

const CheackOut = () => {
  const { permissions } = useSelector((state) => state.userSlice);

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState("");
  const [isId, setId] = useState("");
  const dispatch = useDispatch();

  const { checkListData, loading } = useSelector((state) => state?.checkSlice);

  const newCheckListData = checkListData?.map((item) => {
    const today = new Date();
    const returnDays = new Date(item?.start_date);
    var timeDifference = returnDays?.getTime() - today.getTime();
    var dayDifference = timeDifference / (1000 * 3600 * 24);
    dayDifference = Math.round(dayDifference);
    return { ...item, leftDays: dayDifference };
  });
  newCheckListData?.sort((a, b) => a.leftDays - b.leftDays);
  useEffect(() => {
    dispatch(fetchCheck());
  }, [dispatch]);

  const [expandedItemId, setExpandedItemId] = useState(null);

  const toggleExpand = (id) => {
    // console.log("idd", id)
    setExpandedItemId(expandedItemId === id ? null : id);
  };

  const handleButtonClick = (item) => {
    // console.log("item",item)
    let id = item && item._id;
    let genId = item && item.general_info && item.general_info._id;
    // const url = item.days === '1 Jour(s)' ? '/check-in' : '/check-out';
    navigate("/checks/check-out/" + id + "/" + genId);
  };

  // console.log(loading);
  // When no list permission assigned
  if (!permissions.includes("CHECKS.LIST")) return <></>;
  return (
    <>
      <div>
        {checkListData &&
          checkListData.map((item) => (
            <CheckListItem
              item={item}
              key={item._id}
              expandedItemId={expandedItemId}
              onButtonClick={handleButtonClick}
              ontoggleExpand={toggleExpand}
            />
          ))}
      </div>

      {loading && (
        <div className="mt-4">
          <Loader />
        </div>
      )}
    </>
  );
};

export default CheackOut;
