/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserDocument,
  updateUserDocument,
  userDocumentDelete,
} from "../../../services/client/addclientApi";
import { fetchUserDocument } from "../../../Redux/clientsSlice";
import DeleteModal from "../../Common/DeleteModal";
import { useParams } from "react-router-dom";
import DoumentPdf from "../../Common/DocPdf/DoumentPdf";
import { formatDate } from "../../Common/FormatDate";
import SingleClientDocument from "../Components/ClientDocument/SingleClientDocument";
import ClientMainDocument from "../Components/ClientDocument/ClientMainDocument";
import { ImFolderDownload } from "react-icons/im";

const ClientDocument = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isDocuments, setIsDocuments] = useState([]);
  const [reload, setReload] = useState(false);
  const [isDocumentsId, setIsDocumentsId] = useState("");
  const [isActiveDocument, setActiveDocument] = useState({});
  const [modalShow, setModalShow] = useState("");
  const [isOpenAccordion, setOpenAccordion] = useState();
  const [isOpenClientAccordion, setOpenClientAccordion] = useState();
  const { customerData, userDocumentList } = useSelector(
    (state) => state?.clientsSlice
  );
  const apiData = customerData?.connectedCustomers || [];
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));

  // img upload
  const handleUploadImg = async (files) => {
    const form = new FormData();
    form.append(`cust_id`, files[0].user);
    let count = 0;
    for (const file of files) {
      const fileType1 = file.file?.type?.split("/")[1];
      form.append(`document[${count}][firstname]`, userInfo?.fname);
      form.append(`document[${count}][lastname]`, userInfo?.lname);
      form.append(`document[${count}][name]`, `${file?.key}.${fileType1}`);
      form.append(`document[${count}][file_key]`, file?.key);
      form.append(`document[${count}][file]`, file?.file);

      count += 1;
      // if (data?.status) {

      //   // if (isDocuments?.length > 0) {
      //   //   setIsDocuments((prev) => [...prev, { ...data?.documents?.[0] }]);
      //   // } else {
      //   //   setIsDocuments([data?.documents?.[0]]);
      //   // }
      // }
    }
    await addUserDocument(form);
    setActiveDocument({});
    setIsDocuments([]);
    setReload(!reload);

    return true;
  };

  // handleAccordionToggle
  const handleAccordionToggle = async (id) => {
    dispatch(fetchUserDocument(id));
    setOpenAccordion(isOpenAccordion === id ? null : id);
  };

  const handleClentAccordionToggle = async (id) => {
    dispatch(fetchUserDocument(id));
    setOpenClientAccordion(isOpenClientAccordion === id ? null : id);
  };

  //  delete document
  const handleDelete = async () => {
    const body = { doc_id: isActiveDocument?._id };
    await userDocumentDelete(userDocumentList?._id, body);
    dispatch(
      fetchUserDocument(
        isOpenAccordion ? isOpenAccordion : isOpenClientAccordion
      )
    );
    setModalShow(false);
    setActiveDocument({});
  };

  // download Img
  const handleDownload = async () => {
    try {
      const downloadUrl = `${process.env.REACT_APP_IMG_URL}${
        isActiveDocument?.image || isActiveDocument?.url
      }`;
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = isActiveDocument?.name || "document";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };

  // set documnet
  useEffect(() => {
    setIsDocuments(userDocumentList?.documents);
  }, [userDocumentList, reload]);

  // initial open Accordion
  useEffect(() => {
    setActiveDocument(userDocumentList?.documents?.[0]);
  }, [id, userDocumentList]);

  useEffect(() => {
    setOpenAccordion(apiData?.[0]?.MAIN?._id);
    setOpenClientAccordion(customerData?.customer?._id);
    dispatch(fetchUserDocument(id));
  }, [reload]);

  return (
    <div className="row documents">
      <div className="col-3">
        {apiData.length !== 0 ? (
          apiData?.map((customer, index) => {
            if (customer?.MAIN === null) {
              return null;
            }
            return (
              <ClientMainDocument
                isOpenAccordion={isOpenAccordion}
                customer={customer}
                handleAccordionToggle={handleAccordionToggle}
                isDocuments={isDocuments}
                setActiveDocument={setActiveDocument}
                setIsDocumentsId={setIsDocumentsId}
                updateUserDocument={updateUserDocument}
                isActiveDocument={isActiveDocument}
                isDocumentsId={isDocumentsId}
                formatDate={formatDate}
                setModalShow={setModalShow}
                handleUploadImg={handleUploadImg}
              />
            );
          })
        ) : (
          <SingleClientDocument
            handleClentAccordionToggle={handleClentAccordionToggle}
            isOpenClientAccordion={isOpenClientAccordion}
            customerData={customerData}
            apiData={apiData}
            isDocuments={isDocuments}
            setIsDocumentsId={setIsDocumentsId}
            handleUploadImg={handleUploadImg}
            setActiveDocument={setActiveDocument}
            isActiveDocument={isActiveDocument}
            isDocumentsId={isDocumentsId}
            setModalShow={setModalShow}
          />
        )}
      </div>

      <div className="col-9">
        <div className="card">
          <div className="card-body">
            {isActiveDocument?.url && (
              <ImFolderDownload
                onClick={handleDownload}
                className="fs-4 text-black position-absolute cursor-pointer"
                style={{
                  zIndex: 999,
                  inset: "20px",
                }}
              />
            )}
            {isActiveDocument?.url?.includes("pdf") ? (
              <DoumentPdf
                isOpenAccordion={isOpenAccordion}
                isActiveDocument={isActiveDocument}
                setActiveDocument={setActiveDocument}
              />
            ) : isActiveDocument?.url ? (
              <img
                src={`${process.env.REACT_APP_IMG_URL}${isActiveDocument?.url}`}
                alt=""
                className="img-fluid ps-5"
              />
            ) : isActiveDocument?.image ? (
              <img
                src={isActiveDocument?.image}
                alt=""
                className="img-fluid ps-5"
              />
            ) : (
              <div className="text-center">No Data Found</div>
            )}
          </div>
        </div>
      </div>

      <DeleteModal
        title={"Supprimer ce document"}
        description={"Êtes-vous sûr de supprimer le document ?"}
        show={modalShow}
        deleteData={() => handleDelete(false)}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default ClientDocument;
