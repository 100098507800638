import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  companyDetailsDelete,
  getCompanyDetails,
  getCompanylist,
} from "../services/Company/addCompanyApi";
import { getSubscription } from "../services/Company/addSubscriptionApi";
import DeleteModal from "../components/Common/DeleteModal";
import { ReactComponent as DeleteIcon } from "../assets/icons/delete-comp.svg";
import { ReactComponent as CarIcon } from "../assets/icons/car.svg";

const Settings = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [isCompanylist, setCompanylist] = useState();
  const [isCompanyDetails, setCompanyDetails] = useState();
  const [issubscriptionDetails, setSubscriptionDetails] = useState();
  const [issubscriptionDetailsPlus, setSubscriptionDetailsPlus] = useState();
  const [isFetchCompanylist, setFetchCompanylist] = useState("");
  const [isCompanyDeleteBtn, setCompanyDeleteBtn] = useState(false);
  const [isSubscriptioneBtn, setSubscriptioneBtn] = useState(false);
  const [isActiveCompany, setActiveCompany] = useState("");
  const [isActiveSubscription, setActiveSubscription] = useState("");
  const isRolesTab = location?.pathname === "/settings/roles";

  const AddCompanyDetails = async (id) => {
    setCompanyDeleteBtn(true);
    const companyData = await getCompanyDetails(id);
    setCompanyDetails(companyData?.data);
    navigate("company");
  };

  const addSubscriptionDetails = async (id) => {
    setSubscriptioneBtn(true);
    const subscriptionData = await getSubscription(id);
    setSubscriptionDetails(subscriptionData?.data);
    navigate("subscription");
  };

  const allCompanylist = async () => {
    const list = await getCompanylist();

    if (list?.data) {
      const defaultSel = list?.data?.[0];
      setActiveSubscription(defaultSel?._id);
      setActiveCompany(defaultSel?._id);
      AddCompanyDetails(defaultSel?._id);
      setSubscriptionDetails(defaultSel);
    }
    setCompanylist(list);
  };

  useEffect(() => {
    allCompanylist();
  }, [isFetchCompanylist]);

  const deleteData = async (id) => {
    await companyDetailsDelete(id);
    setFetchCompanylist(id);
    setModalShow(false);
  };

  return (
    <>
      <div className="content-wrapper setting">
        {/* Content */}
        <div className="container-fluid flex-grow-1 container-p-y">
          {/* Modern */}
          <div className="d-flex justify-content-between">
            <div
              className="step d-flex gap-3"
              data-target="#account-details-modern-vertical seeting-tabs"
            >
              <NavLink
                to={"/settings/company"}
                // to={"/settings"}
              >
                <div className={`rounded-2 p-1 px-2 btn-active`}>
                  <span className="bs-stepper-label d-flex gap-2">
                    <span className={`bs-stepper-title}`}>
                      <i className="ti ti-building-community me-1"></i>
                      {location.pathname === "/settings/company" && `Sociétés`}
                    </span>
                    {location.pathname === "/settings/company" && (
                      <>
                        <div className="d-flex gap-1">
                          {isCompanylist?.data?.length &&
                            isCompanylist?.data?.map((c) => (
                              <div
                                className={`company-list ${
                                  isActiveSubscription === c?._id
                                    ? `company-list-active`
                                    : ``
                                }`}
                                onClick={() => {
                                  setActiveSubscription(c?._id);
                                  setActiveCompany(c?._id);
                                  AddCompanyDetails(c?._id);
                                  setSubscriptionDetails(c);
                                }}
                              >
                                {c.name}
                              </div>
                            ))}
                          <div
                            className="company-list"
                            style={{ width: "34px", fontSize: "25px" }}
                            onClick={() => {
                              navigate("company");
                              setCompanyDetails();
                              setCompanyDeleteBtn(false);
                              setActiveCompany();
                              setActiveSubscription();
                            }}
                          >
                            +
                          </div>
                        </div>
                      </>
                    )}
                  </span>
                </div>
              </NavLink>
              {/* <NavLink to={"/settings/subscription"}>
              <div className={`rounded-2 p-1 px-2 btn-active`}>
                <span className="bs-stepper-label ">
                  <span className={`bs-stepper-title`}>
                    <i className="ti ti-currency-euro me-1"></i>
                    Abonnements
                  </span>
                </span>
              </div>
            </NavLink> */}
              <NavLink to={"/settings/roles"}>
                <div className={`rounded-2 p-1 px-2 btn-active`}>
                  <span className="bs-stepper-label ">
                    <span className={`bs-stepper-title`}>
                      <i className="ti ti-users me-1"></i>
                      {location.pathname === "/settings/roles" && `Roles`}
                    </span>
                  </span>
                </div>
              </NavLink>

              <NavLink to={"/settings/vehicles/visibilitee"}>
                <div className={`rounded-2 p-1 px-2 btn-active`}>
                  <span className="bs-stepper-label d-flex gap-2">
                    <span className={`bs-stepper-title`}>
                      <CarIcon />
                      {location.pathname === "/settings/vehicles" && `Vehicles`}
                    </span>

                    {location.pathname.includes("/settings/vehicles") && (
                      <div className="d-flex gap-1">
                        <NavLink
                          to="/settings/vehicles/visibilitee/checks"
                          style={{ maxHeight: "20px", minHeight: "20px" }}
                        >
                          <div
                            className={`company-list ${
                              location.pathname === "/settings/vehicles/checks"
                                ? `company-list-active`
                                : ``
                            }`}
                          >
                            Checks
                          </div>
                        </NavLink>
                        <NavLink
                          to="/settings/vehicles/visibilitee"
                          style={{ maxHeight: "20px", minHeight: "20px" }}
                        >
                          <div
                            className={`company-list ${
                              location.pathname ===
                              "/settings/vehicles/visibilitee"
                                ? `company-list-active`
                                : ``
                            }`}
                          >
                            Visibilitée
                          </div>
                        </NavLink>
                        <NavLink
                          to="/settings/vehicles/visibilitee/colors"
                          style={{ maxHeight: "20px", minHeight: "20px" }}
                        >
                          <div
                            className={`company-list ${
                              location.pathname ===
                              "/settings/vehicles/visibilitee/colors"
                                ? `company-list-active`
                                : ``
                            }`}
                          >
                            Couleur
                          </div>
                        </NavLink>
                        {/* <div
                          className={`company-list ${
                            location.pathname === "/settings/vehicles/colors"
                              ? `company-list-active`
                              : ``
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            window.location.href = "/settings/vehicles/colors";
                          }}
                        >
                          Couleur
                        </div> */}
                      </div>
                    )}
                  </span>
                </div>
              </NavLink>
            </div>
            {isCompanyDeleteBtn &&
              location.pathname === "/settings/company" && (
                <div>
                  <button
                    className="btn p-0"
                    onClick={() => setModalShow(true)}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              )}
          </div>
          <div className="row gy-4">
            {/* Modern Vertical Wizard */}
            {/* {!isRolesTab && (
              <div className="col-lg-3">
                <div className="bs-stepper vertical wizard-modern wizard-modern-vertical mt-2">
                  <div className="bs-stepper-header">
                    {isCompanylist &&
                      isCompanylist?.data?.map((items, index) => (
                        <React.Fragment key={index}>
                          <div
                            className={`btn btn-outline-primary waves-effect justify-content-start mt-3 d-block text-start ${
                              isActiveCompany === items?._id
                                ? "com-sub-active"
                                : ""
                            }`}
                            onClick={() => {
                              setActiveSubscription(items?._id);
                              setActiveCompany(items?._id);
                              AddCompanyDetails(items?._id);
                            }}
                          >
                            {items.name}
                          </div>
                          {items?.subscriptions?.map((subscriptions, index) => (
                            <div
                              key={index}
                              className={`btn btn-outline-primary waves-effect mt-1 ms-4 justify-content-start d-block text-start ${
                                isActiveSubscription === subscriptions?.sub_id
                                  ? "com-sub-active"
                                  : ""
                              }`}
                              onClick={() => {
                                addSubscriptionDetails(subscriptions?.sub_id);
                                setActiveSubscription(subscriptions?.sub_id);
                                setActiveCompany(items?._id);
                              }}
                            >
                              <i className="ti ti-arrow-narrow-right me-2"></i>
                              {subscriptions?.sub_name}
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                    <div className="mt-3 d-flex justify-content-end">
                      <div
                        className="btn btn-outline-primary waves-effect border-end-0 btn-company w-full"
                        onClick={() => {
                          navigate("company");
                          setCompanyDetails();
                          setCompanyDeleteBtn(false);
                          setActiveCompany();
                          setActiveSubscription();
                        }}
                      >
                        <i className="ti ti-plus me-2"></i> Ajouter une Société
                      </div>
                      <div
                        onClick={() => {
                          navigate("subscription");
                          setSubscriptionDetails();
                          setSubscriptioneBtn(false);
                          setActiveSubscription();
                        }}
                        className="btn btn-outline-primary waves-effect btn-subscription w-full"
                      >
                        <i className="ti ti-plus me-2"></i> Ajouter un
                        Abonnement
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
            <div className={isRolesTab ? "col-lg-12" : "col-lg-12"}>
              <div className="mt-3">
                <Outlet
                  context={[
                    isCompanyDetails,
                    setCompanyDetails,
                    isCompanyDeleteBtn,
                    issubscriptionDetails,
                    setFetchCompanylist,
                    isSubscriptioneBtn,
                    setSubscriptionDetails,
                    setSubscriptionDetailsPlus,
                    isActiveCompany,
                  ]}
                />
              </div>
            </div>
            {/* /Modern Vertical Wizard */}
          </div>
        </div>
        {/* / Content */}
        <div className="content-backdrop fade" />

        <DeleteModal
          title={"Supprimer Cette Société"}
          description={"Êtes-vous sûr de supprimer le Société ?"}
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteData={() => deleteData(isCompanyDetails?.banking?.compid)}
        />
      </div>
    </>
  );
};

export default Settings;
