import React, { useEffect, useState } from "react";
import car from "../assets/img/login/car.png";
import singleLogo from "../assets/img/icons/logo/single-logo.svg";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { loginUserAPI } from "../services/loginApi";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [isShow, setShow] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      const data = await loginUserAPI(values);
      if (data?.msg) {
        localStorage.setItem("auth_token", data?.data?.token);
        const userinfo = data.data;
        delete userinfo.token;
        localStorage.setItem("userinfo", JSON.stringify(userinfo));
        navigate(`/${userinfo._id}`);
      }
      formik.resetForm();
    },
    validate: (values) => {
      const errors = {};
      // Add validation rules here
      if (!values?.email?.trim()) {
        errors.email = "This field is required.";
      }
      if (!values.password.toString().trim()) {
        errors.password = "This field is required.";
      }
      // Add validation for other fields

      return errors;
    },
  });

  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      const userInfo = JSON.parse(localStorage.getItem("userinfo"))
      navigate(`/${userInfo._id}`);
    }
  }, []);

  return (
    <div
      style={{ height: "100vh" }}
      className="d-lg-flex align-items-center bg-white log-in p-3 p-lg-0 login-m-auto"
    >
      <div className="login-container">
        <div className="row">
          <div className="col-md-7 d-none d-lg-flex align-items-center car-container rounded-3">
            <img className="img-fluid" src={car} alt="" />
          </div>
          <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center bg-white">
            <form onSubmit={formik.handleSubmit} className="w-px-400">
              <img
                className="mb-4"
                style={{ width: "45px" }}
                src={singleLogo}
                alt=""
              />
              <h3>Welcome to Turismo!</h3>
              <div className="mb-3">
                <label
                  className="form-label"
                  htmlFor="username-modern-vertical"
                >
                  E-mail
                </label>
                <input
                  type="email"
                  className={`form-control ${formik.errors.email ? "is-invalid" : ""
                    }`}
                  placeholder="E-mail"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                />
                {formik.errors.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
              </div>
              <div className="mb-3">
                <label
                  className="form-label"
                  htmlFor="username-modern-vertical"
                >
                  Password
                </label>
                <div
                  className={`form-control d-flex align-items-center ${formik.errors.password ? "is-invalid" : ""
                    }`}
                >
                  <input
                    type={isShow ? "text" : "Password"}
                    placeholder="Password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    name="password"
                    className={`login-input ${!formik.errors.password && "w-full"
                      }`}
                  />
                  {!formik.errors.password && (
                    <div onClick={() => setShow(!isShow)}>
                      {isShow ? (
                        <i className="ti ti-eye"></i>
                      ) : (
                        <i className="ti ti-eye-off"></i>
                      )}
                    </div>
                  )}
                </div>
                {formik.errors.password && (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                )}
              </div>

              <div class="mb-3">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="basic-default-checkbox"
                  />
                  <label class="form-check-label" for="basic-default-checkbox">
                    Remember me?
                  </label>
                </div>
              </div>
              <button type="submit" className="btn btn-primary w-full">
                Log in
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
